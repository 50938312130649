export const errors = {
  someCannotUpload: "Некоторые файлы не могут быть загружены.",
  cannotUpload: "Файлы не могут быть загружены.",
  audioStorageDesc:
    "Необходимо дополнительно $value$ в хранилище чтобы начать анализ выбранных файлов. Улучшите хранилище и попробуйте снова. Вы можете продолжить без расширения хранилища, тогда незагруженные файлы не будут проанализированы.",
  audioTariffDesc:
    "Вы достигли лимита $value$ анализов доступных в этом месяце с вашим тарифом. Улучшите тариф и попробуйте снова. Вы можете продолжить без улучшения, тогда незагруженные файлы не будут проанализированы.",
  audioStorageDescSecond:
    "Необходимо дополнительно $value$ в хранилище чтобы начать анализ выбранных файлов. Улучшите хранилище и попробуйте снова.",
  audioTariffDescSecond:
    "Вы достигли лимита $value$ анализов доступных в этом месяце с вашим тарифом. Улучшите тариф и попробуйте снова.",
  tooLong:
    "Длительность файла слишком большая, поэтому он был ограничен первыми {n} минутами. Но можно выбрать другой интервал.",
  readErr: "При чтении файла возникла ошибка",
  maxKeyPhrases: "Максимум 3 слова в фразе",
  maxKeywords: "Максимум 100 ключевых слов или фраз",
  validArrKeywords: "Разделите запятой и пробелом",
  storageWarning: "Свободное место в хранилище анализов скоро закончится.",
  storageAlert:
    "Хранилище почти заполнено. Когда место кончится, результаты не будут сохранены.",
  storageFull:
    "Хранилище заполнено. Результаты анализов не могут быть сохранены.",
  storageInfoAlert1: "Хранилище почти заполнено",
  storageInfoAlert2: "Когда место кончится, результаты не будут сохранены",
  storageInfoFull1: "Хранилище заполнено",
  storageInfoFull2: "Анализы не могут быть сохранены",
  wrongFormat: "Некорректный формат файла",
  service: "Анализ не завершен из-за ошибки сервиса.",
  maxAudioLength: "Не больше {n} для {n2} плана",
  notZero: "Длительность должна быть больше 0",
  maxValue: "Превышена максимальная длительность",
  invalidTitle: "Недопустимое название анализа",
  fieldIsRequired: "Поле обязательно для заполнения",
  fieldContainsOnlySpaces: "Поле содержит только пробелы",
};
