import { AnalyzeIdItem } from "./../../store/analyzes/analyzes-types";
import Vue from "vue";
import {
  audioErrors,
  audioErrorTypes,
  CollectionNameType,
  convertSeconds,
  functionTypes,
  ICollection,
  keywordsToArray,
  LANGUAGE_COLLECTION,
  sizeFormat,
  TARIFFS,
  timeFormat,
  TONALITY_COLLECTION,
  TONALITY_SPEC_COLLECTION,
} from "@/utils/helper";
import { ErrorsMessages } from "@/utils/errors";
import { SuccessMessages } from "@/utils/success-messages";
import { VLIDATORS } from "@/utils/validators";
import {
  AnalysisStartPayload,
  UploadedFile,
} from "@/store/analyzes/analyzes-types";
import TariffUpgradeModal from "@/components/modals/TariffUpgradeModal/index.vue";
import CutAudioModal from "@/components/modals/CutAudioModal/index.vue";
import ZoomInput from "./ZoomInput/index.vue";
import ZombtyInput from "./ZombtyInput/index.vue";
import { Tariff } from "@/types/shared-types";
import { SHOW_ZOMBTY_LOGIN } from "@/utils/consts";
import languagesMixin from "@/mixins/languagesMixin";

export default Vue.extend({
  name: "NewAnalyze",
  components: { TariffUpgradeModal, ZoomInput, ZombtyInput, CutAudioModal },
  mixins: [languagesMixin],
  data() {
    return {
      tab: null,
      isAllowAnalysis: true,
      wsErrors: ErrorsMessages,
      audioErrors: audioErrors,
      audioErrorTypes: audioErrorTypes,
      typeAudioError: <audioErrorTypes>"",
      file: null as File | null,
      zoomMeetings: [],
      fileError: "",
      fileUploading: false,
      zoomFileUploading: false,
      fileRule: () => true,
      formats: ["FLV", "MP3", "OGG", "WAV", "MP4"],
      tariffs: TARIFFS,

      form: <AnalysisStartPayload>{
        analyzesIDs: [],
        title: this.$tc("newAnalysis.title"),
        fileNames: [],
        language: "ru",
        speakers: null,
        tonality: "",
        tonality_spec: "",
        keywords: "",
        keywords_spec: "",
        topic: false,
        sense: false,
        overlap: false,
      },
      rules: VLIDATORS,
      dragover: false,
      functionType: "",
      upgradeTariffModal: false,
      audioCutDialog: false,
      successUploadFiles: <UploadedFile[]>[],
      unSuccessUploadFiles: <UploadedFile[]>[],
      activeFile: {},
      isNameEditing: false,
      isNameEdited: false,

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      storeSubscription: () => {},
      successMessages: SuccessMessages,
    };
  },
  computed: {
    analisisTitle(): string {
      const fileNamesLength = this.form.fileNames.length - 1;

      return (
        this.form.fileNames[fileNamesLength] ||
        (this.$t("newAnalysis.title") as string)
      );
    },
    isUploadFiles(): boolean {
      return this.successUploadFiles.length < 10;
    },
    analyzesAvailable(): number {
      return this.$store.getters["profile/GET_ANALYZES_AVAILABLE"];
    },
    storageAvailable(): number {
      return this.$store.getters["profile/GET_STORAGE_AVAILABLE"];
    },
    errorCode() {
      return this.$store.state.errorWS;
    },
    successCode(): number | null {
      return this.$store.state.successCode;
    },
    keywordsCounter(): number {
      if (this.form.keywords === "") return 0;
      return keywordsToArray(this.form.keywords).length;
    },
    tariff(): Tariff {
      return this.$store.state.profile.user.tariff;
    },
    analyzesCount() {
      return this.$store.state.profile.user.tariffSettings.analyzes_count;
    },
    isConfirmedEmail() {
      return this.$store.state.profile.user.email_confirmed;
    },
    uploadedZoomFiles(): string[] {
      return [...this.successUploadFiles, ...this.unSuccessUploadFiles]
        .map((f) => f.file.id || "")
        .filter(Boolean);
    },
    availableAudioLength(): string {
      return `${this.$tc(
        "newAnalysis.upToMins",
        this.tariffs[this.tariff].duration_record / 60
      )}`;
    },
    maxFileSize(): number {
      return this.$store.state.profile.user.tariff === "freemium" ? 50 : 500;
    },
    tariffLimit(): { type: string; value: number | string } | null {
      const objectError = {
        [this.audioErrorTypes.TARIFF]: {
          type: this.typeAudioError,
          value: this.$store.state.profile.user.tariffSettings.analyzes_total,
        },

        [this.audioErrorTypes.TARIFF_CONTINUE]: {
          type: this.typeAudioError,
          value: this.$store.state.profile.user.tariffSettings.analyzes_total,
        },
        [this.audioErrorTypes.STORAGE]: {
          type: this.typeAudioError,
          value: sizeFormat(
            this.unSuccessUploadFiles[this.unSuccessUploadFiles.length - 1]
              ?.file.size /
              1024 -
              (this.$store.state.profile.user.tariffSettings.storage_total -
                this.$store.state.profile.user.tariffSettings.storage_fill)
          ),
        },
        [this.audioErrorTypes.STORAGE_CONTINUE]: {
          type: this.typeAudioError,
          value: sizeFormat(
            this.unSuccessUploadFiles
              .filter((file) => !file.error)
              .reduce((acc, file) => {
                return acc + file.file.size / 1024;
              }, 0)
          ),
        },
      };
      return objectError[this.typeAudioError] || null;
    },
    showZombtyTab(): boolean {
      return SHOW_ZOMBTY_LOGIN;
    },
    maxAudioLength(): number {
      return this.tariffs[this.tariff].duration_record;
    },
    translate() {
      return {
        dndFile: this.$t("newAnalysis.dndFile"),
        hintTopic: this.$t("newAnalysis.topicDesc"),
        hintSense: this.$t("newAnalysis.senseDesc"),
        hintInterruptions: this.$t("newAnalysis.interruptionsDesc"),
        keywordsPlaceholder: this.$t("newAnalysis.keywordsPlaceholder"),
        keywordsDesc: this.$t("newAnalysis.keywordInputDesc"),
        emptyValue: this.$t("newAnalysis.emptyValue"),
      };
    },
    selectItems(): { [key: string]: ICollection[] } {
      return {
        TONALITY_COLLECTION: TONALITY_COLLECTION,
        TONALITY_SPEC_COLLECTION: TONALITY_SPEC_COLLECTION,
        LANGUAGE_COLLECTION: LANGUAGE_COLLECTION,
      };
    },
    functionAllTypes() {
      return Object.keys(functionTypes).map(
        (k) => this.$t(`newAnalysis.${k}`) as string
      );
    },
  },
  watch: {
    tab: {
      handler(tab) {
        if (tab === 1 && this.$store.state.profile.user.zoom_authorized)
          this.$store.dispatch("zoom/GET_ZOOM_MEETINGS");
      },
    },
    tariffLimit: {
      handler(newValue) {
        this.isAllowAnalysis = newValue ? false : true;
      },
    },
    unSuccessUploadFiles: {
      handler() {
        this.isAllowAnalysis =
          this.unSuccessUploadFiles.length > 0 && this.tariffLimit
            ? false
            : true;
      },
      deep: true,
    },
  },
  methods: {
    getSelectItems(collectionName: CollectionNameType) {
      return this.selectItems[collectionName].map((item) => ({
        ...item,
        title: this.$t(`analyzes.${item.value}`),
      }));
    },
    changeFileTitle(event: FocusEvent) {
      this.isNameEditing = false;

      const input = event.target as HTMLInputElement;

      if (!input) return;

      if (input.value !== this.$t("newAnalysis.title")) {
        this.isNameEdited = true;
      }

      this.form.fileNames = this.form.fileNames.map(() => input.value);
    },

    validateCharacter(event: KeyboardEvent) {
      const regex = /^[^\\/:*?"<>|]+$/;
      const specialKeys = ["Backspace", "Delete", "Enter", "Space"];
      const isValidKey = specialKeys.includes(event.code);

      if (!regex.test(event.key) && !isValidKey) {
        event.preventDefault();
      }
    },
    getDurationAudio(file: UploadedFile): Promise<number | null> {
      return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const audioContext = new window.AudioContext();
          if (event?.target?.result)
            audioContext.decodeAudioData(
              event.target.result as ArrayBuffer,
              (buffer) => {
                resolve(buffer.duration);
              },
              () => {
                resolve(null);
              }
            );
        };

        reader.onerror = () => {
          resolve(null);
        };

        reader.readAsArrayBuffer(file.file);
      });
    },
    getFLVDuration(file: UploadedFile): Promise<number | null> {
      return new Promise((resolve) => {
        const reader = new FileReader();

        reader.readAsArrayBuffer(file.file);
        reader.onload = () => {
          const arrayBuffer = reader.result;
          return resolve(this.getVideoDuration(arrayBuffer as ArrayBuffer));
        };
        reader.onerror = () => {
          resolve(null);
        };
      });
    },
    getVideoDuration(arrayBuffer: ArrayBuffer) {
      const dv = new DataView(arrayBuffer);
      const offset = 13; // начало заголовка FLV
      const dataSize = dv.getUint32(offset + 1, false) & 0x00ffffff; // размер данных тега
      const timestamp = dv.getUint32(offset + 4, false); // метка времени тега
      const duration = (timestamp + dataSize) / 1000; // длительность в секундах
      return duration;
    },
    async getAudioDuration(file: UploadedFile) {
      const fileFormat = file.file.name.split(".").pop()?.toUpperCase(),
        isValidFormat = this.formats.includes(fileFormat as string);
      if (!isValidFormat) return this.$t("errors.wrongFormat") as string;

      const duration =
        fileFormat === "FLV"
          ? await this.getFLVDuration(file)
          : await this.getDurationAudio(file);

      if (!duration) return this.$t("errors.readErr") as string;

      return duration;
    },
    setAnalyzesIDs(newAnalyzesIDs: AnalyzeIdItem[]) {
      this.form.analyzesIDs = newAnalyzesIDs;
    },
    async createNewItem(file: File) {
      const item: UploadedFile = {
        id: 0,
        file: file,
        status: false,
        error: "",
        warning: "",
        duration: 0,
        croppedDuration: 0,
        crop_from: 0,
        crop_to: 0,
      };

      const fileURL = URL.createObjectURL(file);
      const duration = await this.getAudioDuration(item);

      if (typeof duration === "number") {
        const roundedDuration = Math.round(duration);
        const isValidDuration = roundedDuration <= this.maxAudioLength;
        const croppedMaxDuration = Math.min(
          roundedDuration,
          this.maxAudioLength
        );

        const convertedMaxLength = convertSeconds(this.maxAudioLength);

        const warningMessage = !isValidDuration
          ? this.$t("errors.tooLong", { n: convertedMaxLength })
          : "";

        Object.assign(item, {
          duration: roundedDuration,
          fileURL: fileURL,
          crop_to: croppedMaxDuration,
          croppedDuration: croppedMaxDuration,
          warning: warningMessage,
        });
      } else {
        item.error = duration;
      }

      return item;
    },
    async onDrop(e: any) {
      if (!this.isAllowAnalysis || !this.isUploadFiles) return;

      let counter = 0;
      this.dragover = false;
      this.fileUploading = true;

      const files = e.dataTransfer?.files;
      if (files.length > 0) {
        do {
          const item = await this.createNewItem(files[counter]);

          this.isErrorLoadingFile(item)
            ? this.unSuccessUploadFiles.push(item)
            : this.successUploadFiles.push(item);

          counter++;
        } while (counter < files.length && this.isUploadFiles);
      }

      try {
        const promises = this.successUploadFiles.map(async (file) => {
          try {
            if (!file.id) {
              const id = await this.getId(file);

              if (!id) return;
              this.setAnalyzeTitle(file.file.name);

              this.pushAnalyzeItem(file, id);
            }
          } catch (error) {
            console.error(error);
          }
          return file;
        });

        await Promise.all(promises);
      } catch (error) {
        console.error(error);
      } finally {
        this.fileUploading = false;
      }
    },
    pushAnalyzeItem(item: UploadedFile, id: number) {
      const identifiers = this.form.analyzesIDs.map((item) => item.id);
      item.status = true;
      item.id = id;

      if (!identifiers.includes(id)) {
        const analyzesItem = {
          id,
        };

        if (item.warning) {
          Object.assign(analyzesItem, {
            crop_from: item.crop_from,
            crop_to: item.crop_to,
          });

          this.$store.commit("analyzes/SET_CROPPED_DURATION", {
            id,
            croppedDuration: item.croppedDuration,
          });
        }

        this.form.analyzesIDs.push(analyzesItem);
      }
    },
    getId(file: UploadedFile): Promise<number> {
      return new Promise((resolve) => {
        resolve(
          this.$store
            .dispatch("analyzes/UPLOAD_FILE", file.file)
            .then((id) => id)
            .catch((err) => {
              file.error = err.message;

              const idx = this.successUploadFiles.indexOf(file);
              const errorFile: UploadedFile[] = this.successUploadFiles.splice(
                idx,
                1
              );
              this.unSuccessUploadFiles = [
                ...this.unSuccessUploadFiles,
                ...errorFile,
              ];
            })
        );
      });
    },
    setAnalyzeTitle(title: string) {
      const newTitle = title.trim().slice(0, 39);
      this.form.fileNames.push(newTitle);

      if (this.isNameEdited) {
        this.form.fileNames = this.form.fileNames.map(() => this.form.title);
        return;
      }
      this.form.title = newTitle;
    },
    startAnalyze() {
      if (this.errorCode) this.$store.dispatch("CLEAR_WS_ERROR_ACTION", null);
      const isValid = (
        this.$refs.form as Vue & {
          validate: () => boolean;
        }
      ).validate();
      if (isValid && this.form.analyzesIDs.length > 0) {
        this.$store.dispatch("analyzes/ANALYZE_START", this.form);
      }
    },
    async uploadFile() {
      if (!this.file) return;

      const item = await this.createNewItem(this.file);
      this.setAnalyzeTitle(item.file.name);

      const isError = this.isErrorLoadingFile(item);

      if (isError) {
        this.unSuccessUploadFiles.push(item);
        this.file = null;
      } else {
        this.successUploadFiles.push(item);
        this.fileUploading = true;
        this.$store
          .dispatch("analyzes/UPLOAD_FILE", item.file)
          .then((id) => {
            const lastFile =
              this.successUploadFiles[this.successUploadFiles.length - 1];
            this.pushAnalyzeItem(lastFile, id);
          })
          .catch((err) => {
            item.error = err.message;
            const idx = this.successUploadFiles.indexOf(item);
            if (idx > -1) {
              const errorFile: UploadedFile[] = this.successUploadFiles.splice(
                idx,
                1
              );
              this.unSuccessUploadFiles = [
                ...this.unSuccessUploadFiles,
                ...errorFile,
              ];
            }
          })
          .finally(() => {
            this.file = null;
            this.fileUploading = false;
          });
      }
    },
    isErrorLoadingFile(file: UploadedFile): boolean {
      if (file.error) return true;

      const sizeSuccessUploadFiles = this.successUploadFiles.reduce(
        (acc, upFile) => {
          return acc + upFile.file.size / 1000;
        },
        0
      );

      if (
        this.successUploadFiles.length >= this.analyzesAvailable &&
        this.successUploadFiles.length === 0
      ) {
        this.typeAudioError = this.audioErrorTypes.TARIFF;
        return true;
      }
      if (
        this.successUploadFiles.length >= this.analyzesAvailable &&
        this.successUploadFiles.length > 0
      ) {
        this.typeAudioError = this.audioErrorTypes.TARIFF_CONTINUE;
        return true;
      }
      if (
        sizeSuccessUploadFiles + file.file.size / 1000 >
          this.storageAvailable &&
        this.successUploadFiles.length === 0
      ) {
        this.typeAudioError = this.audioErrorTypes.STORAGE;
        return true;
      }
      if (
        sizeSuccessUploadFiles + file.file.size / 1000 >
          this.storageAvailable &&
        this.successUploadFiles.length > 0
      ) {
        this.typeAudioError = this.audioErrorTypes.STORAGE_CONTINUE;
        return true;
      }
      return false;
    },
    uploadZoomFile(file: UploadedFile) {
      const isError = this.isErrorLoadingFile(file);
      if (isError) {
        this.unSuccessUploadFiles.push(file);
        this.file = null;
      } else {
        this.successUploadFiles.push(file);
        this.fileUploading = true;
        this.$store
          .dispatch("zoom/UPLOAD_ZOOM_RECORD", file.file.id)
          .then((res) => {
            if (res) {
              this.successUploadFiles[
                this.successUploadFiles.length - 1
              ].status = true;
              const identifiers = this.form.analyzesIDs.map((item) => item.id);
              if (!identifiers.includes(res.file_id)) {
                this.form.analyzesIDs.push({
                  id: res.file_id,
                  zoom_record_id: file.file.id || "",
                });
              }
            } else {
              file.error = "Failed to upload file";
              const idx = this.successUploadFiles.indexOf(file);
              if (idx > -1) {
                const errorFile: UploadedFile[] =
                  this.successUploadFiles.splice(idx, 1);
                this.unSuccessUploadFiles = [
                  ...this.unSuccessUploadFiles,
                  ...errorFile,
                ];
              }
            }
          })
          .catch((err) => {
            file.error = err.message;
            const idx = this.successUploadFiles.indexOf(file);
            if (idx > -1) {
              const errorFile: UploadedFile[] = this.successUploadFiles.splice(
                idx,
                1
              );
              this.unSuccessUploadFiles = [
                ...this.unSuccessUploadFiles,
                ...errorFile,
              ];
            }
          })
          .finally(() => {
            this.fileUploading = false;
            this.zoomFileUploading = false;
          });
      }
    },
    uploadZombtyFile(file: UploadedFile) {
      const isError = this.isErrorLoadingFile(file);
      if (isError) {
        this.unSuccessUploadFiles.push(file);
        this.file = null;
      } else {
        this.successUploadFiles.push(file);
        this.fileUploading = true;
        this.$store
          .dispatch("zombty/UPLOAD_RECORD", file.file.id)
          .then((res) => {
            if (res) {
              this.successUploadFiles[
                this.successUploadFiles.length - 1
              ].status = true;
              const identifiers = this.form.analyzesIDs.map((item) => item.id);
              if (!identifiers.includes(res.file_id)) {
                this.form.analyzesIDs.push({
                  id: res.file_id,
                  zombty_record_id: file.file.id || "",
                });
              }
            } else {
              file.error = "Failed to upload file";
              const idx = this.successUploadFiles.indexOf(file);
              if (idx > -1) {
                const errorFile: UploadedFile[] =
                  this.successUploadFiles.splice(idx, 1);
                this.unSuccessUploadFiles = [
                  ...this.unSuccessUploadFiles,
                  ...errorFile,
                ];
              }
            }
          })
          .catch((err) => {
            file.error = err.message;
            const idx = this.successUploadFiles.indexOf(file);
            if (idx > -1) {
              const errorFile: UploadedFile[] = this.successUploadFiles.splice(
                idx,
                1
              );
              this.unSuccessUploadFiles = [
                ...this.unSuccessUploadFiles,
                ...errorFile,
              ];
            }
          })
          .finally(() => {
            this.fileUploading = false;
            this.zoomFileUploading = false;
          });
      }
    },
    deleteFile(isSuccesList: boolean, idx: number) {
      if (isSuccesList) {
        this.successUploadFiles.splice(idx, 1);
        this.form.analyzesIDs.splice(idx, 1);
        this.form.fileNames.splice(idx, 1);
      } else {
        this.unSuccessUploadFiles.splice(idx, 1);
      }
    },
    clearError(code: number) {
      this.$store.dispatch("CLEAR_WS_ERROR_ACTION", code);
    },
    updateSpeakers(count: number) {
      if (this.form.speakers) {
        if (this.form.speakers + count > 10 && count > 0) return;
        if (this.form.speakers + count === 0) this.form.speakers = null;
        else this.form.speakers += count;
      } else this.form.speakers = count === -1 ? null : 1;
    },
    inputSpace(event: InputEvent) {
      if (this.form.keywords.slice(-1) !== ",") event.preventDefault();
    },
    keywordsInputHandler(event: KeyboardEvent) {
      if (this.keywordsCounter === 100 && event.key === ",") {
        event.preventDefault();
        return;
      }
      this.clearError(3396704796);
    },
    checkTariff(type: string) {
      this.functionType = type;
      switch (type) {
        case functionTypes.TOPIC:
          if (!TARIFFS[this.tariff].topic) this.upgradeTariffModal = true;
          break;
        case functionTypes.SUBJECT:
          if (!TARIFFS[this.tariff].sense) this.upgradeTariffModal = true;
          break;
        case functionTypes.INTERRUPTIONS:
          if (!TARIFFS[this.tariff].overlap) this.upgradeTariffModal = true;
          break;
        default:
          break;
      }
    },
    normalizeKeywords() {
      this.form.keywords = keywordsToArray(this.form.keywords).join(", ");
    },
    formatName(name: string): string {
      return name.length > 60 ? `${name.substr(0, 55)}...` : name;
    },
    formatSize(value: number): string {
      return sizeFormat(value);
    },
    formatDuration(duration: number): string {
      return timeFormat(duration, 1);
    },
    openAudioCutDialog(file: { duration: number } & UploadedFile) {
      this.activeFile = file;
      this.audioCutDialog = true;
    },
  },
  created() {
    this.storeSubscription = this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "analyzes/SET_TASK_ID":
          this.$router.push({
            name: "AnalysisMain",
            params: { id: state.analyzes.currentAnalyze.id },
          });
          break;
        case "zoom/SET_ZOOM_MEETINGS":
          this.zoomMeetings = state.zoom.meetings;
          break;
        case "profile/SET_ZOOM_AUTHORIZATION":
          if (mutation.payload && this.tab === 1)
            this.$store.dispatch("zoom/GET_ZOOM_MEETINGS");
          break;
        default:
          break;
      }
    });
  },
  mounted() {
    this.form.title = this.analisisTitle;
  },
  beforeDestroy() {
    if (this.errorCode) this.$store.dispatch("CLEAR_WS_ERROR_ACTION", null);
    this.storeSubscription();
  },
});
