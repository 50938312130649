import Vue from "vue";
import { functionTypes, tariffUpgradeInfo } from "@/utils/helper";

export default Vue.extend({
  name: "RenameModal",
  props: ["dialog", "functionType"],
  data() {
    return {
      tariffUpgradeInfo: tariffUpgradeInfo,
    };
  },
  computed: {
    otherFunctions(): string[] {
      return Object.values(this.functionAllTypes).filter(
        (f) => f !== this.functionType
      );
    },
    functionAllTypes() {
      return Object.keys(functionTypes).map(
        (k) => this.$t(`newAnalysis.${k}`) as string
      );
    },
  },
  methods: {},
});
