export const newAnalysis = {
  title: "Новый анализ",
  titleError: "Недопустимое название анализа",
  file: "Файл",
  local: "Локальный файл",
  zoom: "Zoom файл",
  zombty: "Zombty запись",
  files:
    "{n} файл загружен | {n} файла загружено | {n} файлов загружено | {n} файлов загружено",
  filesReject: "{n} файлов не может быть загружено",
  uploadRepeat: "Загрузить снова",
  upgradeStorage: "Увеличить хранилище",
  upgradePlan: " Обновить тариф",
  dndFile: "Выберите или перенесите несколько файлов",
  loading: "Загрузка",
  localDesc:
    "Запись в FLV, MP3, OGG, WAV, MP4 форматах {n} и {n2} МБ. (не более 10 файлов).",
  availableSpace: "Доступно {n}.",
  zoomDesc:
    "Чтобы загрузить запись встречи Zoom, нужно войти в свою учетную запись Zoom и установить приложение Senseprofile в магазине приложений Zoom",
  zoomConfirm: "Добавить приложение Senseprofile",
  zombtyDesc:
    "Чтобы загрузить запись встречи Zombty, необходимо привязать свою учетную запись Zombty",
  zombtyLink: "Перейти в настройки",
  lng: "Язык общения",
  optionsLabel: "Ожидаемые варианты диалога",
  optionsDesc:
    "Эти поля являются необязательными. Они помогут сделать диаризацию более точной.",
  speakersNum: "Количество спикеров",
  sentiment: "Чувство разговора",
  speechStyle: "Стиль речи в разговоре",
  emptyValue: "Не указано ",
  detailsLabel: "Определение деталей разговора",
  topic: "Тема",
  topicDesc: "В целом, например, бизнес, спорт, искусство, политика",
  sense: "Смысл",
  senseDesc: "О чем разговор, его суть. Например, договор или покупка чего-то",
  interruptions: "Анализ прерываний",
  interruptionsDesc: "Если один спикер прервал другого и когда",
  keywordsLabel: "Ключевые слова и фразы",
  keywordsDesc:
    "Введите список ключевых слов, которые вы хотите найти в разговоре.",
  keywordsPlaceholder: "Введите ключевые слова, разделенные запятой",
  keywordInputDesc:
    "Введите ключевые слова в единственном числе и именительном падеже или добавьте из транскрипции",
  modalTitle: "Обновите для использования Sense распознавания",
  MORE_ANALYSIS: "Больше анализов каждый месяц",
  LONG_AUDIO: "Анализ длинных аудиозаписей",
  EXPENDED_STORAGE: "Расширенное хранение",
  TONALITY_SENTIMENT: "Определение тональности",
  TONALITY_SPEECH: "Тональность распознавания речи",
  TOPIC: "Определение темы",
  INTERRUPTIONS: "Анализ прерывания",
  KEYWORDS: "Поиск ключевых слов и фраз",
  SUBJECT: "Смысловое распознавание",
  upgrade: "Обновить",
  cancel: "Отмена",
  analyze: "Анализировать",
  upToMins: " до 0 минут | до {n} минуты | до {n} минут | до {n} минут",
  newName: "Новое имя",
  step0: "Ожидание анализа",
  step1: "Шаг 1 из 9: Обрезка файла",
  step2: "Шаг 2 из 9: Загрузка файла",
  step3: "Шаг 3 из 9: Извлечение особенностей",
  step4: "Шаг 4 из 9: Сегментация",
  step5: "Шаг 5 из 9: Встраивание оценки",
  step6: "Шаг 6 из 9: Кластеризация",
  step7: "Шаг 7 из 9: Транскрибация",
  step8: "Шаг 8 из 9: Транскрибация",
  step9: "Шаг 9 из 9: Завершение",
  from: "От",
  to: "До",
  duration: "Длительность",
};
