import { Module } from "vuex";
import { client } from "@/store";
import axios from "axios";
import { setErrorMessage } from "@/utils/errors";
import {
  IAnalyzeAbortDataRes,
  IAnalyzeDeleteDataRes,
  IAnalyzeDuplicateDataRes,
  IAnalyzeRenameDataRes,
  IAnalyzeStartDataRes,
  IAnalyzeStatusUpdateDataRes,
  IMultiAnalyzeStartDataRes,
  INowStateListDataRes,
  ITaskList,
  ITaskListDataRes,
} from "@/types/ws-response-types";
import {
  IAnalyzeAbortRequest,
  IAnalyzeDeleteRequest,
  IAnalyzeDuplicateRequest,
  IAnalyzeRecheckKeywordsRequest,
  IAnalyzeRenameRequest,
  IAnalyzeStartAgainRequest,
  IAnalyzeStartRequest,
  IGetTasksListRequest,
  IGetTaskPdfRequest,
  IAnalyzeStartDataReq,
  IMultiAnalyzeStartRequest,
  IMultiAnalyzeStartAgainRequest,
  IAnalyzeStartAgainDataReq,
} from "@/types/ws-request-types";
import {
  Analysis,
  AnalysisStartPayload,
  AnalyzesState,
  ICroppedDuration,
  IFilterReqRes,
  SearchBy,
  ISpeakersNames,
} from "@/store/analyzes/analyzes-types";
import { RootState } from "../store-types";
import {
  combineKeywords,
  resultCombiner,
  addVisibleWords,
} from "@/store/analyzes/analyzes-helper";
import router from "@/router";
import { FilterType, IFilter, IFilterItem, getCookie } from "@/utils/helper";

const api = process.env.VUE_APP_API_XHR_PROTOCOL + process.env.VUE_APP_API_HOST;

const initialState: AnalyzesState = {
  analyzesList: {
    tasks: [],
    total: 0,
    page: 1,
    from: 0,
    loading: false,
    sort_by: "date",
    sort_order: "desc",
    search_by: "all",
    search_value: "",
    filters: {},
  },
  currentAnalyze: {
    id: null,
    file_name: "",
    file_link: "",
    file_id: null,
    status: "",
    title: "",
    language: "",
    speakers_before_filtration: null,
    speakers_expected: null,
    speakers_detected: null,
    speakers_names: {},
    tonality_base_expected: "",
    tonality_base_detected: null,
    tonality_spec_expected: "",
    tonality_spec_detected: null,
    keywords_expected: "",
    keywords_detected: "",
    used_phrases: null,
    topic: "",
    sense: "",
    overlap: null,
    result: null,
    error_text: "",
    update_time: "",
    create_time: "",
    duration: "",
    size: "",
    swear_words: "",
    zoom_record_id: "",
    timelineData: null,
    keywordsCustomSelection: [],
    isCustomSelection: false,
    isShowHighlights: false,
    isAddingKeywords: false,
    isDownloadingPdf: false,
    is_disable: false,
    combinedKeywords: {},
    checkedKeywords: [],
    isStatisticsCalculating: true,
    wordsRefs: {},
  },
  duplicatedTask: null,
  isFileLoading: false,
  initialTaskList: null,
  tasksToDelete: [],
  previousResults: [],
  bulkDeleting: [],
  isMultiplyDeleteLoading: false,
  croppedDurations: [],
};

export const analyzes: Module<AnalyzesState, RootState> = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(initialState)),
  getters: {
    GET_CURRENT_PAGE(store) {
      return store.analyzesList.page;
    },
    GET_COEFFICIENT(store) {
      return (
        (store.currentAnalyze.timelineData?.main || []).reduce(
          (acc, speaker) => acc + speaker.activity,
          0
        ) / 100
      );
    },
    GET_SAVED_FILTERS(store): IFilter[] {
      return Object.keys(store.analyzesList.filters).map((key) => ({
        subject: key as FilterType,
        condition: store.analyzesList.filters[key as FilterType]!.contains,
        value: store.analyzesList.filters[key as FilterType]!.filter_value,
      }));
    },
  },
  mutations: {
    SET_NOW_STATE_TASKS(state: AnalyzesState, data: INowStateListDataRes[]) {
      state.initialTaskList = data.filter(
        (t) => !["aborted", "failed", "completed"].includes(t.status)
      );
    },
    SET_CROPPED_DURATION(state: AnalyzesState, payload: ICroppedDuration) {
      const idx = state.croppedDurations.findIndex(
        (item) => item.id === payload.id
      );
      if (idx !== -1) {
        state.croppedDurations[idx].croppedDuration = payload.croppedDuration;
      } else {
        state.croppedDurations.push(payload);
      }
    },
    SET_SEARCH(
      state: AnalyzesState,
      payload: { search_by: SearchBy; search_value: string }
    ) {
      state.analyzesList.search_by = payload.search_by;
      state.analyzesList.search_value = payload.search_value;
    },
    SET_FILTERS(state: AnalyzesState, filters: IFilter[]) {
      let newFilters: IFilterReqRes = {};
      filters.forEach((f) => {
        newFilters = {
          ...newFilters,
          [f.subject]: {
            contains: f.condition,
            filter_value: f.value,
          },
        };
      });
      state.analyzesList.filters = newFilters;
    },
    REMOVE_TASK_FROM_PROGRESS(state: AnalyzesState, id: number) {
      if (state.initialTaskList?.length) {
        const idx = state.initialTaskList.findIndex((t) => t.task_id === id);
        if (idx !== -1) state.initialTaskList.splice(idx, 1);
      } else state.initialTaskList = null;
    },
    SET_TASK_ID(state: AnalyzesState, id: IAnalyzeStartDataRes) {
      state.currentAnalyze.id = id.analyze.task_id;
    },
    SET_TASK_STATUS(
      state: AnalyzesState,
      payload: IAnalyzeStatusUpdateDataRes
    ) {
      if (state.currentAnalyze.id === payload.task_id) {
        state.currentAnalyze.status = payload.status;
        if (payload.error_text)
          state.currentAnalyze.error_text = payload.error_text;
      }
      if (state.analyzesList.tasks.length) {
        const idx = state.analyzesList.tasks.findIndex(
          (t) => t.id === payload.task_id
        );
        if (idx !== -1) {
          state.analyzesList.tasks[idx].status = payload.status;
          if (payload.error_text)
            state.analyzesList.tasks[idx].error_text = payload.error_text;
        }
      }
      if (state.initialTaskList?.length) {
        const idx = state.initialTaskList.findIndex(
          (t) => t.task_id === payload.task_id
        );
        if (idx !== -1) {
          state.initialTaskList[idx].status = payload.status;
          state.initialTaskList[idx].title = payload.title;
        } else state.initialTaskList = [...state.initialTaskList, payload];
      } else {
        state.initialTaskList = [payload];
      }
    },
    SET_TASK_ANALYZE(state: AnalyzesState, task) {
      if (!state.currentAnalyze.id || state.currentAnalyze.id === task.id) {
        if (state.initialTaskList?.length) {
          const idx = state.initialTaskList.findIndex(
            (t) => t.task_id === task.id
          );
          if (
            idx !== -1 &&
            ["completed", "aborted", "failed"].includes(task.status)
          ) {
            state.initialTaskList.splice(idx, 1);
          }
        }
        state.currentAnalyze = { ...state.currentAnalyze, ...task };
        if (state.currentAnalyze.status === "aborted") {
          state.currentAnalyze.result = null;
        }
        if (state.currentAnalyze.speakers_expected === 0)
          state.currentAnalyze.speakers_expected = null;
        if (state.currentAnalyze.keywords_expected && !task.isPrevious) {
          const kw = state.currentAnalyze.keywords_expected.split(",");
          state.currentAnalyze.checkedKeywords = kw;
          state.currentAnalyze.keywords_expected = kw.join(", ");
        }
      }
    },
    CALCULATE_STATISTICS(state) {
      if (state.currentAnalyze.result) {
        const {
          keywords_expected,
          result,
          speakers_detected,
          overlap,
          used_phrases,
        } = state.currentAnalyze;
        if (speakers_detected && result.length) {
          const timelineData = resultCombiner(
            result,
            speakers_detected,
            keywords_expected,
            used_phrases
          );

          state.currentAnalyze.timelineData = {
            main: timelineData,
            interruption: overlap || [],
          };
          state.currentAnalyze.combinedKeywords = combineKeywords(
            keywords_expected,
            timelineData
          );
          state.currentAnalyze.result = addVisibleWords(result);
        }
      }
      state.currentAnalyze.isStatisticsCalculating = false;
    },
    SET_ANALYSIS_WORDS_REFS(state, ref: Record<string, HTMLElement>) {
      state.currentAnalyze.wordsRefs = {
        ...state.currentAnalyze.wordsRefs,
        ...ref,
      };
    },
    SAVE_PREVIOUS_RESULT(state: AnalyzesState) {
      const idx = state.previousResults.findIndex(
        (t) => t.id === state.currentAnalyze.id
      );
      if (idx !== -1) {
        state.previousResults[idx] = {
          ...state.currentAnalyze,
          isPrevious: true,
        };
      } else {
        state.previousResults = [
          ...state.previousResults,
          { ...state.currentAnalyze, isPrevious: true },
        ];
      }
      state.currentAnalyze.result = null;
      state.currentAnalyze.timelineData = null;
      state.currentAnalyze.combinedKeywords = {};
      state.currentAnalyze.checkedKeywords = [];
      state.currentAnalyze.keywordsCustomSelection = [];
      state.currentAnalyze.isCustomSelection = false;
      state.currentAnalyze.isShowHighlights = false;
      state.currentAnalyze.status = "wait_analyze";
    },
    SHOW_PREVIOUS_RESULT(state: AnalyzesState, index) {
      state.currentAnalyze = state.previousResults[index];
    },
    CLEAR_CURRENT_TASK(state: AnalyzesState) {
      state.currentAnalyze = JSON.parse(
        JSON.stringify(initialState)
      ).currentAnalyze;
    },
    UPDATE_NAME(state: AnalyzesState, payload: IAnalyzeRenameDataRes) {
      if (state.currentAnalyze.id === payload.task_id)
        state.currentAnalyze.title = payload.title;
      const idx = state.analyzesList.tasks.findIndex(
        (t) => t.id === payload.task_id
      );
      if (idx !== -1) state.analyzesList.tasks[idx].title = payload.title;
    },
    SET_SPEAKERS_NAMES(state: AnalyzesState, payload: ISpeakersNames) {
      state.currentAnalyze.speakers_names = payload;
    },
    SET_DUPLICATED_TASK(
      state: AnalyzesState,
      payload: IAnalyzeDuplicateDataRes
    ) {
      state.duplicatedTask = payload.duplicate_task_id;
    },
    ADD_TASK_TO_DELETE(state: AnalyzesState, id) {
      state.tasksToDelete.push(id);
    },
    ADD_MULTIPLY_DELETE(state: AnalyzesState, ids: number[]) {
      state.bulkDeleting = ids;
    },
    SET_MULTIPLY_DELETE_LOADING(state: AnalyzesState, loading: boolean) {
      state.isMultiplyDeleteLoading = loading;
    },
    BULK_TASK_DELETED(state: AnalyzesState, id: number) {
      state.bulkDeleting = state.bulkDeleting.filter((_id) => _id !== id);
      state.analyzesList.tasks = state.analyzesList.tasks.filter(
        (task) => task.id !== id
      );
    },
    CANCEL_DELETE(state: AnalyzesState, id) {
      const idx = state.tasksToDelete.findIndex((taskId) => taskId === id);
      if (idx !== -1) state.tasksToDelete.splice(idx, 1);
    },
    CANCEL_BULK_DELETE(state: AnalyzesState) {
      state.bulkDeleting = [];
      state.isMultiplyDeleteLoading = false;
    },
    DELETE_TASK(state: AnalyzesState, id) {
      state.initialTaskList = (state.initialTaskList || []).filter(
        (task) => task.task_id !== id
      );
    },
    BULK_DELETE_SUCCESS(state: AnalyzesState) {
      // used in subscribe
    },
    SET_ANALYZES_LIST(state: AnalyzesState, tableData: ITaskListDataRes) {
      if (!tableData.tasks) tableData.tasks = [];
      const tasks = [...tableData.tasks];
      state.analyzesList = {
        ...state.analyzesList,
        ...tableData,
        tasks,
      };
    },
    SET_TABLE_LOADING(state: AnalyzesState, loading: boolean) {
      state.analyzesList.loading = loading;
    },
    SET_LOADING_FILE(state: AnalyzesState, loading: boolean) {
      state.isFileLoading = loading;
    },
    TOGGLE_CUSTOM_SELECTION(state: AnalyzesState, payload: boolean) {
      state.currentAnalyze.isCustomSelection = payload;
    },
    EDIT_CUSTOM_SELECTION(state: AnalyzesState, keyword: string) {
      if (state.currentAnalyze.keywordsCustomSelection.includes(keyword))
        state.currentAnalyze.keywordsCustomSelection =
          state.currentAnalyze.keywordsCustomSelection.filter(
            (k) => k !== keyword
          );
      else state.currentAnalyze.keywordsCustomSelection.push(keyword);
    },
    CLEAR_CUSTOM_SELECTION(state: AnalyzesState) {
      state.currentAnalyze.keywordsCustomSelection = [];
      state.currentAnalyze.isCustomSelection = false;
    },
    CLEAR_ANALYSIS_STATE(state: AnalyzesState) {
      state.analyzesList = JSON.parse(
        JSON.stringify(initialState)
      ).analyzesList;
      state.duplicatedTask = null;
    },
    SET_DOWNLOADING_PDF(state: AnalyzesState, loading: boolean) {
      state.currentAnalyze.isDownloadingPdf = loading;
    },
  },
  actions: {
    SET_NOW_STATE_TASKS_ACTION({ commit }, data: INowStateListDataRes[]) {
      //status in_progress
      data = (data || []).map((t) =>
        t.status === "in_progress" ? { ...t, status: "wait_analyze" } : t
      );
      commit("SET_NOW_STATE_TASKS", data);
    },
    REMOVE_TASK_FROM_PROGRESS_ACTION({ commit }, id: number) {
      commit("REMOVE_TASK_FROM_PROGRESS", id);
    },
    GET_ANALYZES_LIST({ commit, state }, page) {
      commit("SET_TABLE_LOADING", true);
      const request: IGetTasksListRequest = {
        path: "analyzer.get_list",
        data: {
          page,
          sort_order: state.analyzesList.sort_order,
          sort_by: state.analyzesList.sort_by,
          search_by: state.analyzesList.search_by,
          search_value: state.analyzesList.search_value,
          filters: state.analyzesList.filters,
        },
      };
      client.sendWSRequest(request);
    },
    SET_TABLE_DATA({ commit }, tableData: ITaskListDataRes) {
      if (tableData) {
        //status in_progress
        if (tableData.tasks) {
          tableData.tasks = tableData.tasks.map((t: ITaskList) =>
            t.status === "in_progress" ? { ...t, status: "wait_analyze" } : t
          );
        }
        commit("SET_ANALYZES_LIST", tableData);
      }
      commit("SET_TABLE_LOADING", false);
    },
    UPDATE_PAGE({ dispatch }, page: number) {
      dispatch("GET_ANALYZES_LIST", page);
    },
    APPLY_FILTERS({ dispatch, commit }, payload: IFilterItem[]) {
      commit("SET_FILTERS", payload);
      dispatch("GET_ANALYZES_LIST", 1);
    },
    ANALYZES_SEARCH(
      { dispatch, commit },
      payload: { search_by: SearchBy; search_value: string }
    ) {
      commit("SET_SEARCH", payload);
      dispatch("GET_ANALYZES_LIST", 1);
    },

    UPLOAD_FILE({ commit }, payload) {
      commit("SET_LOADING_FILE", true);
      const formData = new FormData();
      formData.append("file", payload);
      return axios
        .post(`${api}/api/uploadFileAnalyze`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          commit("SET_LOADING_FILE", false);
          if (res?.data?.response?.file_id) return res.data.response.file_id;
        })
        .catch((err) => {
          commit("SET_LOADING_FILE", false);
          if (err?.response?.data?.error?.code) {
            const errCode: number = err.response.data.error.code;
            throw new Error(setErrorMessage(errCode));
            // TODO: когда будет правильный ответ с бека, убрать условие и правильно обработать код ошибки
          } else if (err?.response?.status == 413) {
            throw new Error(setErrorMessage(1234));
          } else {
            throw new Error(setErrorMessage(-2));
          }
        });
    },

    ANALYZE_START(_, payload: AnalysisStartPayload) {
      if (payload.analyzesIDs.length === 1) {
        const [analizeItem] = payload.analyzesIDs;
        const data = {
          file_id: payload.analyzesIDs[0].id,
          title: payload.fileNames[0] || payload.title,
          lang: payload.language,
          speakers: payload.speakers,
          tonality: payload.tonality,
          tonality_spec: payload.tonality_spec,
          keywords: payload.keywords,
          zoom_record_id: analizeItem.zoom_record_id ?? "",
          zombty_record_id: analizeItem.zombty_record_id ?? "",
          topic: payload.topic,
          sense: payload.sense,
          overlap: payload.overlap,
        };

        if (payload.analyzesIDs[0].crop_to) {
          Object.assign(data, {
            crop_from: payload.analyzesIDs[0].crop_from,
            crop_to: payload.analyzesIDs[0].crop_to,
          });
        }

        const request: IAnalyzeStartRequest = {
          path: "analyzer.analyze",
          data: data,
        };

        client.sendWSRequest(request);
      } else {
        const requestData: IAnalyzeStartDataReq[] = payload.analyzesIDs.map(
          (item, i) => {
            const data = {
              file_id: item.id,
              title: payload.fileNames[i],
              lang: payload.language,
              speakers: payload.speakers,
              tonality: payload.tonality,
              tonality_spec: payload.tonality_spec,
              keywords: payload.keywords,
              zoom_record_id: item.zoom_record_id ?? "",
              zombty_record_id: item.zombty_record_id ?? "",
              topic: payload.topic,
              sense: payload.sense,
              overlap: payload.overlap,
            };

            if (item.crop_to) {
              Object.assign(data, {
                crop_from: item.crop_from,
                crop_to: item.crop_to,
              });
            }

            return data;
          }
        );
        const request: IMultiAnalyzeStartRequest = {
          path: "analyzer.multianalyze",
          data: requestData,
        };
        client.sendWSRequest(request);
      }
    },
    ANALYZE_STARTED({ commit, dispatch }, payload: IAnalyzeStartDataRes) {
      commit("SET_TASK_STATUS", {
        status: "wait_analyze",
        task_id: payload.analyze.task_id,
        title: payload.analyze.title,
      });

      commit("SET_TASK_ID", payload);
      dispatch("profile/GET_USER_TARIFF_SETTINGS", null, {
        root: true,
      });
    },
    MULTI_ANALYZE_STARTED(
      { commit, dispatch },
      payload: IMultiAnalyzeStartDataRes
    ) {
      payload.analyzes.forEach((item) => {
        commit("SET_TASK_STATUS", {
          status: "wait_analyze",
          task_id: item.task_id,
          title: item.title,
        });
      });

      dispatch("profile/GET_USER_TARIFF_SETTINGS", null, {
        root: true,
      });
      router.push({ name: "AnalyzesTable" });
    },

    ANALYZE_AGAIN_START({ commit, state }, payload) {
      if (typeof payload.id === "number") {
        if (state.currentAnalyze.status === "completed")
          commit("SAVE_PREVIOUS_RESULT");

        state.currentAnalyze.status = "";
        const request: IAnalyzeStartAgainRequest = {
          path: "analyzer.analyze_again",
          data: {
            task_id: payload.id as number,
            lang: payload.language,
            speakers: payload.speakers,
            tonality: payload.tonality,
            tonality_spec: payload.tonality_spec,
            keywords: payload.keywords,
            topic: payload.topic,
            sense: payload.sense,
            overlap: payload.overlap,
          },
        };
        client.sendWSRequest(request);
      } else {
        const requestData: IAnalyzeStartAgainDataReq[] = payload.id.map(
          (id: number) => ({
            task_id: id,
            lang: payload.language,
            speakers: payload.speakers,
            tonality: payload.tonality,
            tonality_spec: payload.tonality_spec,
            keywords: payload.keywords,
            topic: payload.topic,
            sense: payload.sense,
            overlap: payload.overlap,
          })
        );
        const request: IMultiAnalyzeStartAgainRequest = {
          path: "analyzer.multianalyze_again",
          data: requestData,
        };
        client.sendWSRequest(request);
      }
    },
    ANALYZE_AGAIN_STARTED({ commit, dispatch }, payload: IAnalyzeStartDataRes) {
      commit("SET_TASK_STATUS", {
        status: "wait_analyze",
        task_id: payload.analyze.task_id,
      });
      dispatch("profile/GET_USER_TARIFF_SETTINGS", null, {
        root: true,
      });
    },
    MULTI_ANALYZE_AGAIN_START(
      { commit, dispatch },
      payload: IMultiAnalyzeStartDataRes
    ) {
      payload.analyzes.forEach((item) => {
        commit("SET_TASK_STATUS", {
          status: "wait_analyze",
          task_id: item.task_id,
        });
      });
      dispatch("profile/GET_USER_TARIFF_SETTINGS", null, {
        root: true,
      });
    },

    SHOW_PREVIOUS_RESULT_ACTION({ commit, state }, id: number) {
      const idx = state.previousResults.findIndex((t) => t.id === id);
      if (state.currentAnalyze.id === id && idx !== -1) {
        commit("SET_TASK_ANALYZE", state.previousResults[idx]);
      }
    },

    ANALYZE_UPDATE_STATUS({ commit }, payload: IAnalyzeStatusUpdateDataRes) {
      //status in_progress
      if (payload.status === "in_progress") {
        payload.status = "wait_analyze";
      }
      commit("SET_TASK_STATUS", payload);
    },

    async GET_TASK_ANALYZE(
      { commit, dispatch },
      { id, withReturn }
    ): Promise<void | Analysis> {
      try {
        const res = await axios.get(`${api}/api/getTaskAnalyze?task_id=${id}`);
        if (!res.data.error) {
          //status in_progress
          if (res.data.response.status === "in_progress") {
            res.data.response.status = "wait_analyze";
          }
          if (!withReturn) {
            await commit("SET_TASK_ANALYZE", res.data.response);
            commit("CALCULATE_STATISTICS");
          } else return res.data.response;
        }
      } catch (err) {
        dispatch("API_ERROR_HANDLER", err, { root: true });
      }
    },

    CALCULATE_STATISTICS_ACTION({ commit }) {
      commit("CALCULATE_STATISTICS");
    },

    RECHECK_KEYWORDS({ state }) {
      const request: IAnalyzeRecheckKeywordsRequest = {
        path: "analyzer.analyze_recheck_keywords",
        data: {
          task_id: state.currentAnalyze.id!,
          keywords: state.currentAnalyze.keywords_expected,
        },
      };
      client.sendWSRequest(request);
    },

    RENAME_TASK(_, { id, title }) {
      const request: IAnalyzeRenameRequest = {
        path: "analyzer.analyze_rename_title",
        data: {
          task_id: id,
          title,
        },
      };
      client.sendWSRequest(request);
    },
    RENAME_TASK_SPEAKERS({ commit }, { id, speakersNames }) {
      if (!id || !speakersNames) {
        throw new Error("id or speakersNames is required");
      }

      const data = {
        speakers_names: speakersNames,
        task_id: id,
      };

      commit("SET_SPEAKERS_NAMES", speakersNames);

      axios.put(`${api}/api/renameTaskSpeakers`, data).catch((error) => {
        if (typeof error?.response?.data?.error?.code === "number") {
          const errorCode: number = error.response.data.error.code;
          throw new Error(setErrorMessage(errorCode));
        }
      });
    },
    ANALYZE_UPDATE_NAME({ commit }, payload: IAnalyzeRenameDataRes) {
      commit("UPDATE_NAME", payload);
    },

    DUPLICATE_TASK(_, id: number) {
      const request: IAnalyzeDuplicateRequest = {
        path: "analyzer.analyze_duplicate",
        data: { task_id: id },
      };
      client.sendWSRequest(request);
    },
    TASK_DUPLICATED(
      { dispatch, state, commit },
      payload: IAnalyzeDuplicateDataRes
    ) {
      dispatch("GET_ANALYZES_LIST", state.analyzesList.page);
      dispatch("profile/GET_USER_TARIFF_SETTINGS", null, {
        root: true,
      });
      commit("SET_DUPLICATED_TASK", payload);
    },

    ADD_TASK_TO_DELETE_ACTION({ state, commit }, id: number) {
      if (!state.tasksToDelete.includes(id)) commit("ADD_TASK_TO_DELETE", id);
    },
    ADD_MULTIPLY_DELETE_ACTION({ commit, dispatch }, ids: number[]) {
      dispatch("MULTIPLY_DELETE_LOADING_ACTION", true);
      commit("ADD_MULTIPLY_DELETE", ids);
    },
    CANCEL_DELETE_ACTION({ commit }, id: number) {
      commit("CANCEL_DELETE", id);
    },
    CANCEL_BULK_DELETE_ACTION({ commit }) {
      commit("CANCEL_BULK_DELETE");
    },
    MULTIPLY_DELETE_TASK_ACTION(_, ids: number[]) {
      ids.forEach((id) => {
        const request: IAnalyzeDeleteRequest = {
          path: "analyzer.analyze_delete",
          data: { task_id: id },
        };
        client.sendWSRequest(request);
      });
    },
    MULTIPLY_DELETE_LOADING_ACTION({ commit }, loading: boolean) {
      commit("SET_MULTIPLY_DELETE_LOADING", loading);
    },
    DELETE_TASK_ACTION(_, id: number) {
      const request: IAnalyzeDeleteRequest = {
        path: "analyzer.analyze_delete",
        data: { task_id: id },
      };
      client.sendWSRequest(request);
    },
    TASK_DELETED({ commit, state, dispatch }, payload: IAnalyzeDeleteDataRes) {
      if (
        state.bulkDeleting.length &&
        state.bulkDeleting.includes(payload.task_id)
      ) {
        commit("BULK_TASK_DELETED", payload.task_id);
        if (!state.bulkDeleting.length) {
          commit("BULK_DELETE_SUCCESS", payload.task_id);
          dispatch("profile/GET_USER_TARIFF_SETTINGS", null, {
            root: true,
          });
          dispatch("MULTIPLY_DELETE_LOADING_ACTION", false);
          const isLastPage =
            state.analyzesList.page *
              +process.env.VUE_APP_TABLE_ITEMS_PER_PAGE >=
            state.analyzesList.total;

          if (!state.analyzesList.tasks.length && isLastPage) {
            dispatch("UPDATE_PAGE", state.analyzesList.page - 1);
          } else {
            dispatch("GET_ANALYZES_LIST", state.analyzesList.page);
          }
        }
      } else {
        commit("DELETE_TASK", payload.task_id);
        dispatch("profile/GET_USER_TARIFF_SETTINGS", null, {
          root: true,
        });
        if (state.analyzesList.tasks.length === 1) {
          dispatch("UPDATE_PAGE", state.analyzesList.page - 1);
        } else {
          dispatch("GET_ANALYZES_LIST", state.analyzesList.page);
        }
      }
    },

    ABORT_TASK(_, id: number) {
      const request: IAnalyzeAbortRequest = {
        path: "analyzer.analyze_abort",
        data: { task_id: id },
      };
      client.sendWSRequest(request);
    },
    TASK_ABORTED(_, data: IAnalyzeAbortDataRes) {
      // used in subscribe
    },

    TOGGLE_CUSTOM_SELECTION_ACTION({ commit }, payload: boolean) {
      commit("TOGGLE_CUSTOM_SELECTION", payload);
    },
    EDIT_CUSTOM_SELECTION_ACTION({ commit }, payload: string) {
      commit("EDIT_CUSTOM_SELECTION", payload);
    },
    CLEAR_CUSTOM_SELECTION_ACTION({ commit }) {
      commit("CLEAR_CUSTOM_SELECTION");
    },

    CLEAR_CURRENT_TASK_ACTION({ commit }) {
      commit("CLEAR_CURRENT_TASK");
    },
    CLEAR_ANALYSIS_STATE_ACTION({ commit }) {
      commit("CLEAR_ANALYSIS_STATE");
    },
    GET_PDF_LINK(_, taskId) {
      const request: IGetTaskPdfRequest = {
        path: "analyzer.generate_report",
        data: {
          task_id: taskId,
        },
      };
      client.sendWSRequest(request);
    },
    DOWNLOAD_PDF({ dispatch }, url) {
      const link = document.createElement("a");
      link.download = "download";
      link.href = url;
      link.click();
      dispatch("UPDATE_DOWNLOAD_PDF", false);
    },
    UPDATE_DOWNLOAD_PDF({ commit }, loading: boolean) {
      commit("SET_DOWNLOADING_PDF", loading);
    },
  },
};
