export const errors = {
  someCannotUpload: "Some files cannot be uploaded.",
  cannotUpload: "Files cannot be uploaded.",
  audioStorageDesc:
    "It needs an additional $value$ to analyze the selected files. Upgrade storage and upload again. You can continue without adding space, then the unloaded files will not be analyzed.",
  audioTariffDesc:
    "You have reached the limit of $value$ analyses per month available at your plan. Upgrade your plan and upload again. You can continue without upgrade, then the unloaded files will not be analyzed.",
  audioStorageDescSecond:
    "It needs an additional $value$ to analyze the selected files. Upgrade storage and upload again.",
  audioTariffDescSecond:
    "You have reached the limit of $value$ analyses per month available at your plan. Upgrade your plan and upload again.",
  tooLong:
    "Audio file is too long, so it has been limited to first {n} minutes. But you can cut a different interval.",
  readErr: "An error ocurred reading the file",
  maxKeyPhrases: "Max 3 words in phrase",
  maxKeywords: "Max 100 keywords or phrases",
  validArrKeywords: "Separate with a comma and space",
  storageWarning: "The storage space for the analysis results is running out.",
  storageAlert:
    "The storage is almost full. When the space runs out, it will not be possible to save test results.",
  storageFull: "The storage is full. The analysis results cannot be saved",
  storageInfoAlert1: "The storage is almost full",
  storageInfoAlert2:
    "When the space runs out, it will not be possible to save test results",
  storageInfoFull1: "The storage is full",
  storageInfoFull2: "The analysis results cannot be saved",
  wrongFormat: "Wrong format",
  service: "Analysis not completed due to service error.",
  maxAudioLength: "No more than {n} for {n2} plan",
  notZero: "Field must be greater than 0",
  maxValue: "Field duration is too long",
  invalidTitle: "Title is invalid",
  fieldIsRequired: "Field is required",
  fieldContainsOnlySpaces: "Field contains only spaces",
};
