export const header = {
  nocon: "No connection",
  pricing: "Pricing",
  analyzes: "Analyzes",
  new: "New analysis",
  settings: "Account settings",
  support: "Support",
  logOut: "Log out",
  storage: "Storage",
  upgrade: "Upgrade",
  upgradeStorage: "Upgrade storage",
  upgradePlan: "Upgrade plan",
  theme: "Appearance",
  available: "{n} available",
  confirmEmail: "Confirm email to get 10 analisys per month for free.",
  confirmEmail2: " is awaiting confirmation.",
  resend: "Send link",
  resend2: "again if you don’t see a confirmation email",
  manage: "Manage",
  warning: "All",
  warning2:
    "analyses available on your plan are done. Upgrade your plan to do more analyses each month.",
  auto: "Auto",
  inprogress: "{n} analysis in progress",
  finished: "{n} analysis is finished",
  withError: "{n} with error",
  analyze: "Analyze {n}",
  done: "Done",
  error: "Error",
  errorAnalysis: "Analysis error",
  successAnalysis: "1 analysis is done",
  left: "left until {n}",
};
