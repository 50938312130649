import Vue from "vue";
import {
  LANGUAGE_COLLECTION,
  TONALITY_COLLECTION,
  TONALITY_SPEC_COLLECTION,
} from "@/utils/helper";
import { Analysis } from "@/store/analyzes/analyzes-types";
import { ITaskStatuses } from "@/types/shared-types";

export default Vue.extend({
  name: "SummaryInfo",
  props: ["showLoader", "showAbortAlert", "keywordsCounter"],
  data() {
    return {
      language: LANGUAGE_COLLECTION,
    };
  },
  computed: {
    $currentStatus(): ITaskStatuses {
      return this.$storeAnalysis.status;
    },
    $storeAnalysis(): Analysis {
      return this.$store.state.analyzes.currentAnalyze;
    },
    swearWordsCount(): number {
      return this.$storeAnalysis.swear_words.split(",").filter(Boolean).length;
    },
    speakersDifference(): boolean {
      return (
        !!this.$storeAnalysis.speakers_expected &&
        this.$storeAnalysis.speakers_expected !==
          this.$storeAnalysis.speakers_detected
      );
    },
    detectedKeywordsCount(): string {
      if (this.$storeAnalysis.result) {
        const exp = this.keywordsCounter;
        const det = this.$storeAnalysis.keywords_detected || "";
        return `${this.$t("analisis.of", {
          i: det.split(",").filter(Boolean).length,
          n: exp,
        })}`;
      } else return "-";
    },
    tonality() {
      return TONALITY_COLLECTION.concat(TONALITY_SPEC_COLLECTION).map(
        (val) => ({
          ...val,
          title: this.$t(`analyzes.${val.value}`),
        })
      );
    },
    speakersRow(): string {
      const speakers = this.$storeAnalysis.speakers_detected || "-";
      const before = this.$storeAnalysis.speakers_before_filtration;

      return `${speakers}, ${this.$t("analisis.before_filtration")}: ${
        before ? before : "-"
      }`;
    },
  },
  methods: {
    getLanguage(key: string) {
      return this.$t(`analyzes.${key}`);
    },
    getTonality(spec: boolean) {
      if (!this.$storeAnalysis.result || this.showAbortAlert) return;
      const tonality = !spec
        ? this.$storeAnalysis.tonality_base_detected
        : this.$storeAnalysis.tonality_spec_detected;
      if (!tonality || !tonality.result) return "-";
      const resTon = this.tonality.find((el) => el.value === tonality.result);
      const result = resTon ? resTon : { title: "", value: "" };
      const val = +tonality[result.value as keyof typeof tonality] * 100;
      return `${result?.title} ${Math.round(val)}%`;
    },
  },
});
