var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{staticClass:"settings-tool",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"mb-2",staticStyle:{"align-self":"flex-start"}},[_vm._v(" "+_vm._s(_vm.$t("pricing.exactNum"))+" ")]),_c('div',{staticClass:"add-input mb-6"},[_c('span',[_vm._v(_vm._s(_vm.$t("pricing.add")))]),_c('div',{staticClass:"wrap-input-field"},[_c('v-text-field',{staticClass:"input-field",attrs:{"single-line":"","disabled":_vm.isFreemiumTariff,"hide-details":"","color":"primary","dark":"","light":!_vm.darkInput},on:{"keypress":function($event){return _vm.checkValue($event)},"drop":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();},"input":_vm.onInput},model:{value:(_vm.additionalSize),callback:function ($$v) {_vm.additionalSize=_vm._n($$v)},expression:"additionalSize"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"help-icon",attrs:{"size":"18","color":"var(--secondary-text)"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}]),model:{value:(_vm.isShowTooltip),callback:function ($$v) {_vm.isShowTooltip=$$v},expression:"isShowTooltip"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("pricing.maxStorage"))+" ")])])],1),_c('div',{staticClass:"d-flex justify-space-between"},_vm._l((['mb', 'gb', 'tb']),function(btn,idx){return _c('div',{key:idx,staticClass:"preset-btn small-btn ml-1",class:{
          'preset-btn-active': _vm.sizeSign === btn,
          disabled: _vm.isFreemiumTariff,
        },on:{"click":function($event){return _vm.setSizeSign(btn)}}},[_c('p',{staticClass:"text-uppercase"},[_vm._v(_vm._s(btn))])])}),0)]),_c('div',{staticClass:"mb-8",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"mb-2",staticStyle:{"align-self":"flex-start"}},[_vm._v(" "+_vm._s(_vm.$t("pricing.readyValues"))+" ")]),_c('div',{staticClass:"d-flex justify-space-between"},_vm._l((_vm.presetsButtons),function(btn,idx){return _c('div',{key:idx,staticClass:"preset-btn",class:{
          'preset-btn-active':
            _vm.additionalSize === btn.value && _vm.sizeSign === btn.sizeSign,
          disabled: _vm.isFreemiumTariff,
        },on:{"click":function($event){return _vm.setSize(btn.value, btn.sizeSign)}}},[_c('p',[_vm._v(_vm._s(btn.value))]),_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(btn.sizeSign))])])}),0)]),_c('div',[_c('v-btn',{staticClass:"submit-btn",staticStyle:{"text-transform":"none","margin-bottom":"8px"},attrs:{"width":"448","height":"52","color":"primary","disabled":_vm.isFreemiumTariff || !_vm.additionalSize},on:{"click":_vm.submit}},[(_vm.isFreemiumTariff)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("pricing.addStorageButton"))+" ")]):(!_vm.additionalSize)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("pricing.activeStorageButton"))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("pricing.subscribe"))+" "),_c('span',{staticStyle:{"text-decoration":"underline","color":"white","font-weight":"bold"}},[_vm._v(_vm._s(_vm.calculatePrice())+"₽")])])]),_c('div',{staticStyle:{"align-self":"flex-start","font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t("pricing.addStorageButtonDesc"))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }