export const settings = {
  title: "Account settings",
  subtitle: "General",
  changeEmail: "Change Email",
  changePassword: "Change Password",
  password: "Password",
  zoom: "Zoom account",
  awaiting: "Email address is awaiting confirmation",
  awaiting2: "Confirm email to access all the features of Senseprofile",
  contact: "contact us",
  perMonth: "per month",
  zoomDesc:
    "All the information about the application, as well as links to documentation and support can be found on the Zoom Senseprofile integration page. If the service does not work as expected - please {n}. To remove an application, click the Remove button at the bottom of the page.",
  zoomLink: "Go to Zoom account",
  zombty: "Zombty account",
  zombtyDesc:
    "You can link Zombty account to Senseprofile for easy analyze existing records",
  zombtyLink: "Link now",
  deleteAcc: "Delete account",
  deleteDesc:
    "Please note, all the analyses you have created will be permanently deleted.",
  paySubtitle: "Payments and subscriptions",
  plan: "Current plan",
  nextPayment: "Next payment {n}",
  change: "Change",
  storage: "Storage",
  available: "available",
  addSpace: "Add Space",
  payManage: "Payment manage",
  payManageDesc:
    "Here you can view your payment history and change your payment method.",
  wrong: "Something went wrong...",
  refreshPage: "Please refresh the page.",
  zombtyLinked:
    " User with this Zombty account is already linked on Senseprofile",
  zombtyUnlink: " Are you sure want to unlink Zombty account?",
  unlinkNow: "Unlink now",
  subscribeCancel:
    "You have canceled your subscription so some files will become inaccessible after your subscription ends.",
  changeName: "Change name",
  deleteTitle: "Delete account",
  deleteWarn:
    "All your data at Senseprofile will be permanently deleted. There will be no way to restore your account.",
  deleteLabel: "To delete an account {n} enter your current password",
  deleteInputLabel: "Current password",
  deleteConfirm: "Delete account permanently",
  passwordTitle: "Change password",
  curPassword: "Current password",
  newPassword: "New password",
  passwordHint: "At least 8 characters",
  editEmail: "Edit email",
  newEmail: "New email",
  emailHint: "We will send a confirmition link to new email",
  close: "Close",
  successChangeEmail:
    "We have sent a confirmation link to {n}. Follow the instructions from the email to complete the mail edit process",
  Jan: "Jan",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Apr",
  May: "May",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Aug",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dec",
  for: "for",
  subscriptionRequiredModalText:
    "You can't add storage because you have a Freemium plan. Change your tariff plan and increase your storage if necessary.",
  changePlan: "Change plan",
  subscriptionRequired: "Subscription required",
};
