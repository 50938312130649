export const analisis = {
  progressTitle: "Текущий анализ",
  progressDesc:
    "Это может занять от нескольких минут до нескольких часов в зависимости от размера файла",
  waiting: "Ожидание анализа",
  audio: "Аудио",
  abort: "Прервать",
  abortedStep: "Прервано",
  reanalyze: "Повторить",
  rename: "Переименовать",
  duplicate: "Дублировать",
  delete: "Удалить",
  withError: "Анализ завершен с ошибкой:",
  notCompleted: "Анализ не завершен из-за ошибки сервиса.",
  prev: "смотреть предыдущий результат",
  retry: "попробовать снова",
  summary: "Сводка",
  detecting: "Обнаружение...",
  sentiment: "Чувство разговора",
  speech: "Стиль речи в разговоре",
  topic: "Тема",
  sense: "Смысл",
  keywords: "Ключевые слова",
  lng: "Нецензурная лексика",
  statistic: "Показать статистику по спикерам",
  title: "Заголовок",
  expected: "{n} ожидается",
  speakers: "Спикеры",
  select: "Выбрать все",
  deselect: "Отменить все",
  dur: "Длительность",
  kw: "Ключевых слов",
  kp: "Ключевая фраза",
  highlight: " Выделение ключевых слов в тексте",
  addFromTranscription: "Добавление ключевых слов из транскрипции",
  custom: "Создать собственную подборку",
  selected: "Выделить выбранные",
  selectDesc:
    'Выберите слова, нажав кнопку "Выделить выбранные". Нажмите еще раз, чтобы удалить слово',
  spotted: "Обнаружено",
  times:
    "Обнаружено {count} раз | Обнаружено {count} раз в | Обнаружено {count} раза в | Обнаружено {count} раз в",
  phrases: "фразах:",
  notChecked: "Не проверено",
  recheckKW: "Проверить ключевые слова",
  aborted: "Анализ был прерван",
  completed: "Завершен",
  person: "Собеседник {n}",
  personFrom: "Собеседник {n} с",
  of: "{i} из {n}",
  words: "{n} слов | {n} слово | {n} слова | {n} слов",
  goBack: "Назад к результатам",
  activityTitle: "Распределение активности спикеров",
  statistics: "Статистика",
  senses: "Смыслы",
  sensesEmpty: "Смысл не обнаружен",
  mostUsed: "Самые частые фразы",
  mostUsedEmpty: "Фразы не обнаружены",
  kwDetected: "Обнаруженые ключевые слова и фразы",
  kwDetectedEmpty: "Ключевые слова не обнаружены",
  playFrom: "Воспроизвести отсюда",
  noDetected: "{n} не обнаружен",
  transcription: "Транскрипция",
  selectedFiles:
    "Выбран {n} файл | Выбрано {n} файла | Выбраны {n} файлов | Выбрано {n} файлов",
  length: "Длина",
  m: "м",
  ms: "мс",
  renameSpeakers: "Переименовать собеседников",
  save: "Сохранить",
  invalidTitle: "Недопустимое название анализа",
  before_filtration: "до фильтрации",
};
