import { IZombtyRecord } from "@/store/zombty/zombty-types";
import Vue from "vue";
import LinkAccount from "./LinkAccount/index.vue";
import ZombtyRecord from "./ZombtyRecord/index.vue";

export default Vue.extend({
  name: "ZombtyInput",
  props: {
    isUploadFiles: Boolean,
  },
  components: {
    LinkAccount,
    ZombtyRecord,
  },
  computed: {
    zombtyAuthorized(): boolean {
      return this.$store.state.profile.user.zombty_authorized;
    },
    zombtyRecords(): IZombtyRecord[] {
      return this.$store.state.zombty.meetings;
    },
    zombtyLoading(): boolean {
      return this.$store.state.zombty.isMeetingsLoading;
    },
  },
  mounted() {
    this.$store.dispatch("zombty/GET_MEETINGS_LIST");
  },
});
