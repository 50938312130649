export * from "./landing";
export * from "./pricing";
export * from "./newAnalisys";
export * from "./header";
export * from "./analyzes";
export * from "./settings";
export * from "./analisis";
export * from "./footer";
export * from "./errors";
export * from "./snackbar";
