export const landing = {
  tryNowBtn: "Try now",
  tryNowBtnDesc: "No registration or credit card needed. 10 analysis free",
  landingHeader:
    "Discover the sense-emphasizing conversation with our AI innovations",
  landingHeaderDesc: "Powered by Arllecta",
  landingHead2: "We combine thousands of words into one meaning",
  landingHead2Desc:
    "Our breakthrough algorithm listens to people with meaning. It differs business from a friendly conversation, determines the emotional mood of each participant in the conversation, professionalism, and much more.",
  featuresHeader: "Main features",
  feature1: "Speakers diarisation",
  feature2: "Tonality analysis",
  feature3: "Interrupts detection",
  feature4: "Frequently used phrases highlighting",
  feature5: "Zoom integration",
  feature6: "Topic and sense detection",
  feature7: "Statistics by speakers",
  feature8: "Obscene language detection",
  feature9: "Words and phrases search",
  businesHeader: "Business cases",
  sales: "Sales",
  salesItem1: "subject transcription of calls;",
  salesItem2: "identification of conversation objects;",
  salesItem3: "creation and control of scenario behavior of a sales manager;",
  salesItem4: "topic identification of the client conversation.",
  support: "Support",
  supportItem1: "subject transcription of calls;",
  supportItem2: "identification of conversation objects;",
  supportItem3:
    "creation and control of scenario behavior of a support manager;",
  supportItem4: " topic identification of the client conversation.",
  retail: "Retail",
  retailItem1: " implementation of the client's mood detection system;",
  retailItem2:
    "implementation of a scenario plan for communicating with a client;",
  retailItem3: "employee control and consulting system;",
  retailItem4: "service quality analytics system.",
  security: "Security",
  securityItem1:
    "assigning a scenario plan for the behavior of a company employee;",
  securityItem2:
    "implementation of a control system for company employees in part of preserving intellectual-property;",
  securityItem3: "personal identification of conversation objects;",
  securityItem4: "scripted 24/7 audio control.",
  whyHeader: "Why SenseProfile",
  whyDesc:
    "With over 14 years of experience in Machine Learning, Artificial Intelligence, and Mathematical Modeling, we provide self-learning and predictive network ArLLecta for business solutions of the global market. The network is fully implemented on the basis of the revolutionary mathematical theory 'Sense Theory' and the highly scalable 'Proof of Participation Protocol (PoPP)' specially designed for AI tasks.",
  investorsHeader: "For investors",
  investorsDesc: "",
  zoomHeader: "Zoom APP",
  zoomDesc:
    "SenseProfile is integrated into Zoom to transcribe the recordings of your online meetings, including conversations of several speakers. All your recordings are now available on one page without additional loading or switching. You don't need to analyze your recording twice - all your results are available on a library page. Manage your business more efficiently with the SenseProfile app.",
  contactsHeader: "Contacts",
  officeLocation1: "SINGAPORE Office (headquarter)",
  officeName1: "Arllecta Pte.Ltd.",
  officeAddress1: "68 Circular Road, #02-01,",
  officeAddress11: "Singapore, 049422",
  officeLocation2: "USA Office",
  officeName2: "Arllecta Inc.",
  officeAddress2: "919 North Market Street,",
  officeAddress22: "Wilmington, Delaware, 19801",
  officeLocation3: "UK Office",
  officeName3: "Arllecta Ltd.",
  officeAddress3: "180 Tottenham Court Road, Suite 12,",
  officeAddress33: "2nd Floor Queens House,",
  officeAddress333: "London, United Kingdom, W1T 7PD",
  officeLocation4: "KZ Office",
  officeName4: "Arllecta LLC",
  officeAddress4: "info@arllecta.com",
  officeAddress44: "Sain street, 16B, Almaty, Kazakhstan,",
  officeAddress444: "050062",
  social: "Follow us on",
  tryHeader: "Try <span>Sense</span>Profile now for free",
  tryButton: "Register",
  tryDesc: "No credit card needed",
};

// export const header = {
//   home: "Home",
//   features: "Features",
//   cases: "Business cases",
//   why: "Why we",
//   investors: "For investors",
//   zoom: "Zoom APP",
//   contact: "Contact",
// };

// export const footer = {
//   policy: "© 2024 by Arllecta. All rights reserved",
//   terms: "Terms of Use",
//   privacy: "Privacy Policy",
// };
