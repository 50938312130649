export const settings = {
  title: "Настройки аккаунта",
  subtitle: "Основное",
  changeEmail: "Изменить Email",
  changePassword: "Изменить Пароль",
  password: "Пароль",
  zoom: "Zoom аккаунт",
  awaiting: "Email адрес ожидает подтверждения",
  awaiting2: "Подтвердите email, чтобы получить доступ к фунциям Senseprofile",
  contact: "свяжитесь с нами",
  perMonth: "в месяц",
  zoomDesc:
    "Вся информация о приложении, а также ссылки на документацию и поддержку можно найти на странице интеграции Zoom Senseprofile. Если сервис не работает, как ожидалось - пожалуйста, {n}. Чтобы удалить приложение, нажмите кнопку Удалить внизу страницы. ",
  zoomLink: "Перейти в Zoom аккаунт",
  zombty: "Zombty аккаунт",
  zombtyDesc:
    "Вы можете связать учетную запись Zombty с Senseprofile для облегчения анализа существующих записей",
  zombtyLink: "Подключить сейчас",
  deleteAcc: "Удалить аккаунт",
  deleteDesc: "Обратите внимание, что все созданные анализы будут удалены.",
  paySubtitle: "Платежи и подписки",
  plan: "Текущий план",
  nextPayment: "Следующая оплата {n}",
  change: "Изменить",
  storage: "Хранилище",
  available: "доступно",
  addSpace: "Добавить Пространство",
  payManage: "Управлять оплатой",
  payManageDesc:
    "Здесь вы можете просмотреть историю платежей и изменить способ оплаты.",
  wrong: "Что то пошло не так...",
  refreshPage: "Пожалуйста перезагрузите страницу.",
  zombtyLinked:
    "Пользователь с таким профилем Zombty уже привязан к Senseprofile",
  zombtyUnlink: "Вы уверены, что хотите отвязать профиль Zombty?",
  unlinkNow: "Отвязать",
  subscribeCancel:
    "Вы отменили вашу подписку, некоторые фаилы станут недоступны после истечения срока подписки.",
  changeName: "Сменить имя",
  deleteTitle: "Удалить аккаунт",
  deleteWarn:
    "Все ваши данные в Senseprofile будут безвозвратно удалены. Возможности восстановить аккаунт не будет.",
  deleteLabel: "Для удаления аккаунта {n} введите текущий пароль",
  deleteInputLabel: "Текущий пароль",
  deleteConfirm: "Удалить аккаунт безвозвратно",
  passwordTitle: "Сменить пароль",
  curPassword: "Текущий пароль",
  newPassword: "Новый пароль",
  passwordHint: "Минимум 8 символов",
  editEmail: "Редактировать email",
  newEmail: "Новый email",
  emailHint: "Мы отправим ссылку с подтверждением на новый email",
  close: "Закрыть",
  successChangeEmail:
    "Мы отправили письмо с подтверждением на {n}. Следуйте инструкциям в письме, чтобы продолжить процесс смены email",
  Jan: "Янв",
  Feb: "Февр",
  Mar: "Март",
  Apr: "Апр",
  May: "Май",
  Jun: "Июнь",
  Jul: "Июль",
  Aug: "Авг",
  Sep: "Сент",
  Oct: "Окт",
  Nov: "Нояб",
  Dec: "Дек",
  for: "за",
  subscriptionRequiredModalText:
    "Вы не можете добавить пространство, так как у вас Freemium план. Измените свой тарифный план и увеличьте ваше хранилище, если это необходимо.",
  changePlan: "Изменить план",
  subscriptionRequired: "Необходима подписка",
};
