export const pricing = {
  title: "Plans",
  min: "{n} minutes ",
  day: "days",
  priceMonth: "{price} per mounth",
  premDuration: "Unlimited",
  current: "Current Plan",
  storageTime: "While the subscription is valid",
  freemium: "Freemium",
  standart: "Standart",
  standartPlus: "Standart Plus",
  premium: "Premium",
  unlimited: "Unlimited",
  free: "Get started for free",
  perMonth: "Requests per month",
  numPerMonth: "Number of requests per month",
  storageCap: "Storage capacity",
  filesLife: "Shelf life of audio files",
  toneRec: "Speech tone recognition",
  expRes: "Export results",
  keySearch: "Search for key phrases",
  expansion: "Storage expansion",
  topicRec: "Topic recognition",
  mood: "Determining the mood of a conversation",
  interRec: "Interruption recognition",
  maxDur: "Maximum duration of each analyzed record",
  space: "Storage space",
  storageTimeLabel: "File storage time",
  tonality: "Tonality",
  export: "Export result as PDF",
  keyphrases: "Keyphrases search",
  extraStorage: "Extra Storage",
  topic: "Topic",
  sense: "Sense",
  interruptions: "Interruptions",
  guestLink: "Sign up",
  guestLink2: "to get 10 requests for free",
  addStorageTitle: "Additional storage space",
  maxStorage: " The maximum limit for one payment is 1000 Mb, 1000 Gb or 10 TB",
  addStorageDesc:
    "When your account reaches its storage limit, you can pay for an extra space",
  exactNum: "Exact number",
  add: "Add",
  readyValues: "Ready-made values",
  addStorageButton: "Extra storage is not available for the Freemium plan",
  activeStorageButton: "Select storage value",
  subscribe: "Subscribe for",
  addStorageButtonDesc:
    "Selected value will be added to your existing storage after payment.",
  faq11: "What payment methods are accepted?",
  faq12:
    "You can use Credit/Debit Cards, Alipay, Google pay, Apple pay, ACH Credit Transfer, ACH Debit for payment.",
  faq21: "How do I renew my subscription?",
  faq22:
    "All subscriptions are renewed automatically until the moment when the user himself does not cancel it. After canceling a paid subscription, the user automatically switches to the Freemium Plan.",
  faq31: "What happens to my files when my subscription ends?",
  faq32:
    "After the expiration of the paid subscription, all user files will be stored for 30 days, then deleted. During this period of 30 days, the user will be able to upload their files at any time.",
  faq41:
    "What if I want to upgrade from a more expensive to a cheaper subscription?",
  faq42:
    "When switching from a more expensive to a cheaper subscription, the user will be prompted to either save all their files that exceed the space limit of the new subscription or delete them.",
  faq51: "How can I cancel my paid or Freemium subscription?",
  faq52: "You can cancel any subscription at page Settings.",
  faq61: "How can I export the results after processing my files?",
  faq62:
    "On the analysis page, after highlighting the desired file and pressing the ellipsis, the user will be offered a menu in which it is possible to download the selected file.",
  faqFooter: "Didn't find an answer to your question?",
  faqFooter2: " Contact us",
  signup: "Sign up",
  signup2: "to get 10 requests for free",
  emailConfirm: "Email address is awaiting confirmation.",
  emailConfirm2:
    "You need to confirm your email before you can choose your subscription plan.",
  progress: "In progress",
  until: "Until {n}",
  renew: "Renew",
  changePlan: "Change subscribtion plan",
  changeConfirm:
    "Are you sure you want to change your subscribtion plan? Subscription price will be { n }$ per month.",
  accept: "Accept",
  cancelTitle: "Canceling subscriptions",
  cancelLabel:
    "After canceling your subscription, you will lose the benefits of the {n}",
  cancelAnalysis: "Analysis each month: {n}",
  cancelAudioLength: "Audio recording length: {n}",
  cancelStorage: " Storage: {n}",
  cancelTopic: "Topic detection",
  cancelSense: "Sense detection",
  cancelInterruption: "Interruption analysis",
  cancelDesc:
    "Data that does not meet the new conditions will be held without access for 30 days without the ability to access it. After that, analyses that do not qualify for the current rate will be permanently deleted. You can save the data locally on your device using the Export feature, or you can extend your subscription.",
  cancelSave: "Save subscription",
  cancelConfirm: "Cancel subscription",
  success: "Success",
  upgradedSuccess: "You have been upgraded to Senseprofile { n } plan.",
  goBack: "Back to Senseprofile",
  manage: "Manage subscribtion",
  fail: "Failure",
  failDesc: "Plan was not changed due to a payment error.",
  tryAgain: "Try again",
  processDesc:
    "In process, as soon as Stripe makes the payment, extra space will be added to your existing storage.",
  faq: "FAQ",
  tarifUpgrage11: "Upgrade to use Tonality detection",
  tarifUpgrage12:
    "The Topic function allows you to understand what the conversation was about in general. Upgrade and get more features:",
  tarifUpgrage21: "Upgrade to use Topic detection",
  tarifUpgrage31: "Upgrade to use Sense detection",
  tarifUpgrage41: "Upgrade to use Interruption analysis",
  changeTariffModalText:
    "Are you sure you want to change your subscription plan? The subscription cost will be {n}₽ per month, payment will need to be made right away.",
  changeTariffModalText2:
    "Are you sure you want to change your subscription plan right now? The subscription cost will be {n}₽ per month.",
  tarifChangeAfterDate:
    "Tariff will change freemium after current tariff expired",
};
