import { IZombtyRecord } from "@/store/zombty/zombty-types";
import { Tariff } from "@/types/shared-types";
import { TARIFFS, dateDiffMs, formatLength, sizeFormat } from "@/utils/helper";
import Vue, { PropType } from "vue";
import type { DataTableHeader } from "vuetify";

interface IExtendedZombtyRecords extends IZombtyRecord {
  display_size: string;
  display_length: string;
  length: number;
}

export default Vue.extend({
  name: "ZombtyRecord",
  props: {
    records: Array as PropType<IZombtyRecord[]>,
    uploadedRecord: {
      type: Array as PropType<string[]>,
      default: Array,
    },
    isUploadFiles: Boolean,
  },
  data: () => ({
    hoveredItem: <null | string>null,
    page: 1,
    pageCount: 1,
  }),
  computed: {
    zombtyRecords(): IExtendedZombtyRecords[] {
      return this.records.map((item) => {
        const length = dateDiffMs(item.recordStartTime, item.recordEndTime);
        return {
          ...item,
          length,
          display_size: sizeFormat(item.size / 1024),
          display_length: formatLength(length),
        };
      });
    },
    isRecordUploading(): boolean {
      return this.$store.state.zoom.isRecordUploading;
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "recordId",
        },
        { text: "Length", value: "display_length", sortable: true },
        { text: "Size", value: "display_size", sortable: true },
        { text: "Date", value: "recordStartTime", sortable: true },
      ];
    },
    tariffs() {
      return TARIFFS;
    },
    userTariff(): Tariff {
      return this.$store.state.profile.user.tariff;
    },
  },
  methods: {
    setHovered(item: IZombtyRecord) {
      this.hoveredItem = item.recordId;
    },
    validateFile(file: IExtendedZombtyRecords) {
      const duration =
        dateDiffMs(file.recordStartTime, file.recordEndTime) / 1000;

      if (!duration) return "An error occurred reading the file";

      const isValidDuration =
        duration <= this.tariffs[this.userTariff].duration_record;

      if (!isValidDuration)
        return `Audio file is too long for ${this.userTariff.split('_').join(' ')} plan`;

      return "";
    },
    uploadFile(item: IExtendedZombtyRecords) {
      if (this.isRecordUploading) return;
      const newItem = {
        file: {
          size: item.size,
          name: item.fullFileName,
          id: item.recordId,
        },
        status: false,
        error: this.validateFile(item),
      };
      this.$emit("uploadFile", newItem);
    },
    openTask(id: number) {
      this.$router.push({ name: "Analysis", params: { id: id.toString() } });
    },
  },
});
