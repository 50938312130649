import Vue from "vue";
import {
  msOrSecFormat,
  PERSON_CONTRAST_COLORS,
  timeFormat,
  calculateScale,
} from "@/utils/helper";
import { ITimeLineData } from "@/store/analyzes/analyzes-types";

export default Vue.extend({
  name: "Timeline",
  props: {
    result: {
      required: true,
      type: Object as () => ITimeLineData,
    },
    audioLength: Number,
    currentTime: Number,
    playSelected: Function,
  },
  data() {
    return {
      scale: calculateScale(this.audioLength),
      contrastColors: PERSON_CONTRAST_COLORS,
      mousedown: false,
    };
  },
  computed: {
    coefficient(): number {
      return this.audioLength / 100;
    },
    timeGrid(): number {
      return Math.floor(this.audioLength / 30);
    },
    minScale() {
      return calculateScale(this.audioLength);
    },
    maxScale(): number {
      return this.minScale + 500;
    },
  },
  methods: {
    calculateSpeechLength(start: number, end: number) {
      return Math.abs((end - start) / this.coefficient);
    },
    calculateSpeechLengthTime(start: number, end: number) {
      const length = Math.abs(end - start);
      return msOrSecFormat(length);
    },
    calculateSpeechStartTime(start: number) {
      return msOrSecFormat(start);
    },
    calculateStartSpeech(start: number) {
      return start / this.coefficient;
    },
    timeFormat(time: number, coef: number) {
      return timeFormat(time, coef);
    },
    trigger(ref: string) {
      (this.$refs[ref] as HTMLInputElement[])[0].click();
    },
    setScale(count: number) {
      if (+this.scale + count < this.minScale) this.scale = this.minScale;
      else if (+this.scale + count > this.maxScale) this.scale = this.maxScale;
      else this.scale = +this.scale + count;
    },
    findSpeakersMaxPosition(speaker1: string, speaker2: string) {
      const idx1 = this.findSpeakerPosition(speaker1);
      const idx2 = this.findSpeakerPosition(speaker2);
      return idx1 > idx2 ? idx1 : idx2;
    },
    findSpeakerPosition(speaker: string) {
      return this.result.main.findIndex((s) => +s.speaker === +speaker) + 1;
    },
    getSpeakerName(speaker: number): string {
      return (
        this.$store.state.analyzes.currentAnalyze.speakers_names[speaker] ||
        this.$tc("analisis.person", speaker)
      );
    },
  },
});
