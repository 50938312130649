























import Vue from "vue";
import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import Snackbar from "@/components/modals/Snackbar/index.vue";
import { getGlobalTheme } from "@/utils/helper";
import { checkIframe } from "./utils/utils";

export default Vue.extend({
  name: "App",
  components: { Footer, Header, Snackbar },
  data: () => ({
    isConnectError: false,
  }),
  computed: {
    isAuth() {
      return this.$route.matched.some((record) => record.meta.authRequired);
    },
    isLanding() {
      return this.$route.name
        ? ["Landing", "Login", "Register", "Error", "AccountDeleted"].includes(
            this.$route.name
          )
        : false;
    },
    showGlobalHeader(): boolean {
      return this.$route.name
        ? this.isAuth &&
            !["PaymentResult", "PaymentCanceled", "PaymentProcessing"].includes(
              this.$route.name
            )
        : this.isAuth;
    },
    showGlobalFooter(): boolean {
      if (this.$route.name) {
        return !["Error"].includes(this.$route.name);
      } else return false;
    },
  },
  mounted() {
    window.addEventListener("online", () => {
      this.isConnectError = false;
      this.$store.dispatch("CONNECT_TO_WS");
    });

    window.addEventListener("offline", () => {
      this.isConnectError = true;
      this.$store.dispatch("DISCONNECT_WS");
    });
    checkIframe();
  },
  methods: {
    setTheme() {
      const theme = getGlobalTheme();
      this.$store.dispatch("SET_THEME", theme);
    },
  },
  created() {
    this.$store.dispatch("CONNECT_TO_WS");
    this.$store.dispatch("profile/GET_USER_SETTINGS");
    this.setTheme();
  },
});
