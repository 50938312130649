import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import AnalyzesTable from "@/components/AnalyzesTable/index.vue";
import NewAnalysis from "@/components/NewAnalysis/index.vue";
import Analysis from "@/components/Analysis/index.vue";
import AccountSettings from "@/components/profile/AccountSettings/index.vue";
import CancelingSubscriptions from "@/components/profile/CancelingSubscriptions/index.vue";
import AnalysisMain from "@/components/Analysis/AnalysisMain/index.vue";
import AnalysisSpeakersStatistics from "@/components/Analysis/AnalysisSpeakersStatistics/index.vue";
import Tariffication from "@/components/Tariffication/index.vue";
import PaymentResult from "@/components/PaymentResult/index.vue";
import PaymentCanceled from "@/components/PaymentResult/PaymentCanceled/index.vue";
import PaymentProcessing from "@/components/PaymentResult/PaymentProcessing/index.vue";
import Error from "@/components/Error/index.vue";
import { tokenValidate } from "@/utils/oauth";
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "AnalyzesTable",
    component: AnalyzesTable,
  },
  {
    path: "/error",
    name: "Error",
    component: Error,
  },
  {
    path: "/tariffication",
    name: "Tariffication",
    component: Tariffication,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/dashboard",
    name: "AnalyzesTable",
    component: AnalyzesTable,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/new-analysis",
    name: "NewAnalysis",
    component: NewAnalysis,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/analysis/:id",
    name: "Analysis",
    component: Analysis,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "",
        name: "AnalysisMain",
        component: AnalysisMain,
      },
      {
        path: "details",
        name: "AnalysisSpeakersStatistics",
        component: AnalysisSpeakersStatistics,
      },
    ],
  },
  {
    path: "/profile",
    name: "AccountSettings",
    component: AccountSettings,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/canceling-subscriptions",
    name: "CancelingSubscriptions",
    component: CancelingSubscriptions,
    meta: {
      authRequired: true,
    },
  },

  {
    path: "/",
    component: NewAnalysis,
    children: [],
  },
  {
    path: "/paying-result",
    name: "PaymentResult",
    component: PaymentResult,
    props: true,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/paying-canceled",
    name: "PaymentCanceled",
    component: PaymentCanceled,
    props: true,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/payment-processing",
    name: "PaymentProcessing",
    component: PaymentProcessing,
    meta: {
      authRequired: true,
    },
  },

  {
    path: "/*",
    redirect: "/dashboard",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    if (savedPosition) {
      return savedPosition;
    }
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.name === "Error" || store.state.profile.user.id) {
    return next();
  }
  const { isLoginChecked } = store.state.profile;

  const loginPromise = new Promise<void>((res, rej) => {
    if (isLoginChecked) {
      return res();
    }
    store.subscribeAction({
      after: (action, state) => {
        if (action.type === "WS_DISCONNECTED") {
          return rej("Error");
        }
        if (action.type === "API_ERROR_HANDLER") {
          tokenValidate()
            .then(next)
            .catch(() => {
              if (isInFrame()) {
                postMessageToParentWindow("Unauthorized");
              }

              next("/error");
            });
          return rej("Api Error");
        }
        if (action.type === "profile/SET_USER_SETTINGS_ACTION") {
          if (!state.profile.user.id) {
            return rej("No user ID");
          }
          return res();
        }
      },
    });
  });

  try {
    await loginPromise.catch(tokenValidate);
    next();
  } catch (_e) {
    if (isInFrame()) {
      postMessageToParentWindow("Unauthorized");
    }

    next("/error");
  }
});

function isInFrame() {
  try {
    return (
      window != window.top ||
      document != top?.document ||
      self.location != top?.location
    );
  } catch (e) {
    return true;
  }
}

function postMessageToParentWindow(message: string) {
  window.parent.postMessage(
    message,
    process.env.VUE_APP_ERLLECTA_URL || "https://www.erllecta.ru/"
  );
}

export default router;
