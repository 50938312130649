export const header = {
  nocon: "Нет соединения",
  pricing: "Цены",
  analyzes: "Анализы",
  new: "Новый анализ",
  settings: "Настройки аккаунта",
  support: "Поддержка",
  logOut: "Выйти",
  storage: "Хранилище",
  upgrade: "Улучшить",
  upgradeStorage: "Улучшить хранилище",
  upgradePlan: "Обновить план",
  theme: "Тема",
  available: "{n} доступно",
  confirmEmail: "Подтвердите email, чтобы получить 10 бесплатных анализов",
  confirmEmail2: " ждет подтверждения",
  resend: "Отправить письмо",
  resend2: "снова, если вы не видите подтверждения",
  manage: "Управлять",
  warning: "Все",
  warning2:
    "анализов доступных в вашем плане закончились. Повысьте план, чтобы делать больше анализов",
  auto: "Авто",
  inprogress: "{n} анализов в процессе",
  finished: "{n} анализов завершено",
  withError: "{n} с ошибкой",
  analyze: "Анализ {n}",
  done: "Завершен",
  error: "Ошибка",
  errorAnalysis: "Ошибка анализа",
  successAnalysis: "1 анализ завершен",
  left: "осталось до {n}",
};
