export const login = {
  title: "Log in",
  email: "Email",
  password: "Password",
  show: "Show",
  recovery: "Forgot password?",
  register: "Don’t have an account? Register",
};

export const recovery = {
  title: "Password recovery",
  desc: "Type the email you used when registering and we will send instructions to reset your password.",
  error: "Field is required",
  button: "Send instruction",
  goBack: "Back to Sign in or Create a new account",
};

export const register = {
  title: "Create an account to use Senseprofile",
  name: "First name",
  lastName: "Last name",
  confirm: "Create account",
  termsOfUse: "I agree with Terms of use and Privacy policy",
};

export const sidebar = {
  title: "Senseprofile",
  label: "Speech analytics",
  desc: "Download or integrate audio recordings of a conversation of one or a group of people and listen to the participants by mood, keywords and phrases, semantic content and much more.",
};
