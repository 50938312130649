export const noop = () => void 0;

export const checkIframe = () => {
  let isFramed = false;
  try {
    isFramed =
      window != window.top ||
      document != top?.document ||
      self.location != top?.location;
  } catch (e) {
    isFramed = true;
  }
  if (!isFramed) {
    window.location.replace(
      process.env.VUE_APP_ERLLECTA_URL || "https://www.erllecta.ru/"
    );
  }
};
