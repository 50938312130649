import Vue from "vue";
import { Analysis, ISpeakersNames } from "@/store/analyzes/analyzes-types";
import { PERSON_COLORS } from "@/utils/helper";

export default Vue.extend({
  name: "RenameSpeakersModal",
  props: ["dialog"],
  data() {
    return {
      formValues: <ISpeakersNames>{},
      personColors: <string[]>PERSON_COLORS,
      areNamesSame: false,
    };
  },
  computed: {
    $storeAnalysis(): Analysis {
      return this.$store.state.analyzes.currentAnalyze;
    },
  },
  methods: {
    renameSpeakers() {
      Object.entries(this.formValues).forEach(([key, value]) => {
        if (!value) {
          this.formValues[Number(key)] = this.getSpeakerName(Number(key));
        }
      });

      this.areNamesSame = Object.keys(this.formValues).every(
        (key) =>
          this.formValues[key] === this.$storeAnalysis.speakers_names[key]
      );

      if (this.areNamesSame) {
        this.$emit("close");
        return;
      }

      const isValid = (
        this.$refs.form as Vue & { validate: () => boolean }
      ).validate();

      if (isValid) {
        this.$store.dispatch("analyzes/RENAME_TASK_SPEAKERS", {
          id: this.$storeAnalysis.id,
          speakersNames: this.formValues,
        });
        this.$emit("close");
      }
    },
    setFormNames() {
      this.$storeAnalysis.timelineData?.main.forEach((speaker) => {
        this.$set(
          this.formValues,
          speaker.speaker,
          this.getSpeakerName(Number(speaker.speaker))
        );
      });
    },
    changeInputValue(value: string, speaker: number) {
      this.formValues[speaker] = value?.trim() || "";
    },
    getSpeakerName(speaker: number): string {
      return (
        this.$storeAnalysis.speakers_names[speaker] ||
        (this.$t("analisis.person", { n: speaker }) as string)
      );
    },
  },

  created() {
    this.setFormNames();
  },
});
