import Vue from "vue";
import {
  audioErrorTypes,
  functionTypes,
  keywordsToArray,
  TONALITY_COLLECTION,
  TONALITY_SPEC_COLLECTION,
  TARIFFS,
  LANGUAGE_COLLECTION,
  ICollection,
  CollectionNameType,
} from "@/utils/helper";
import { Tariff } from "@/types/shared-types";
import { ErrorsMessages } from "@/utils/errors";
import { VLIDATORS } from "@/utils/validators";
import { Analysis } from "@/store/analyzes/analyzes-types";
import { SuccessMessages } from "@/utils/success-messages";
import TariffUpgradeModal from "@/components/modals/TariffUpgradeModal/index.vue";
import languagesMixin from "@/mixins/languagesMixin";

export default Vue.extend({
  name: "ReAnalyzeModal",
  props: ["dialog", "taskId", "taskIdArray"],
  components: { TariffUpgradeModal },
  mixins: [languagesMixin],
  data() {
    return {
      wsErrors: ErrorsMessages,

      tonality: TONALITY_COLLECTION,
      speechStyle: TONALITY_SPEC_COLLECTION,

      form: {
        id: <number | number[] | null>null,
        language: "en",
        title: "New_analysis",
        speakers: <number | null>null,
        tonality: "",
        tonality_spec: "",
        keywords: "",
        topic: false,
        sense: false,
        overlap: false,
      },
      rules: VLIDATORS,
      tariffs: TARIFFS,
      functionType: "",
      upgradeTariffModal: false,

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      storeSubscription: () => {},
      successMessages: SuccessMessages,
    };
  },
  watch: {
    "form.sense": {
      handler(val) {
        if (val) this.form.sense = true;
      },
    },
    "form.topic": {
      handler(val) {
        if (!val) this.form.topic = false;
      },
    },
  },
  methods: {
    startReAnalyze() {
      if (this.errorCode) this.$store.dispatch("CLEAR_WS_ERROR_ACTION", null);
      const isValid = (
        this.$refs.form as Vue & {
          validate: () => boolean;
        }
      ).validate();
      if (isValid) {
        if (typeof this.form.id === "number")
          this.$store.dispatch("analyzes/ANALYZE_AGAIN_START", this.form);
        else
          this.$store.dispatch("analyzes/ANALYZE_AGAIN_START", {
            ...this.form,
            id: this.taskIdArray,
          });
        this.$emit("close");
        this.$route.path.includes("details") &&
          this.$router.replace({ path: `/analysis/${this.form.id}` });
      }
    },
    clearError(code: number) {
      this.$store.dispatch("CLEAR_WS_ERROR_ACTION", code);
    },
    inputSpace(event: InputEvent) {
      if (this.form.keywords.slice(-1) !== ",") event.preventDefault();
    },
    keywordsInputHandler(event: KeyboardEvent) {
      if (this.keywordsCounter === 100 && event.key === ",") {
        event.preventDefault();
        return;
      }
      this.clearError(3396704796);
    },
    //TODO refactor
    checkTariff(type: string) {
      this.functionType = type;

      switch (type) {
        case functionTypes.TOPIC:
          if (!TARIFFS[this.tariff].topic) this.upgradeTariffModal = true;
          break;
        case functionTypes.SUBJECT:
          if (!TARIFFS[this.tariff].sense) this.upgradeTariffModal = true;
          break;
        case functionTypes.INTERRUPTIONS:
          if (!TARIFFS[this.tariff].overlap) this.upgradeTariffModal = true;
          break;
        default:
          break;
      }
    },
    normalizeKeywords() {
      this.form.keywords = keywordsToArray(this.form.keywords).join(", ");
    },
    getSelectItems(collectionName: CollectionNameType) {
      return this.selectItems[collectionName].map((item) => ({
        ...item,
        title: this.$t(`analyzes.${item.value}`),
      }));
    },
  },
  computed: {
    $storeAnalysis(): Analysis {
      return this.$store.state.analyzes.currentAnalyze;
    },
    errorCode() {
      return this.$store.state.errorWS;
    },
    successCode(): number | null {
      return this.$store.state.successCode;
    },
    keywordsCounter(): number {
      if (this.form.keywords === "") return 0;
      return keywordsToArray(this.form.keywords).length;
    },
    tariffLimit() {
      return { type: audioErrorTypes.TARIFF_CONTINUE, value: "10" };
    },
    tariff(): Tariff {
      return this.$store.state.profile.user.tariff;
    },
    translate() {
      return {
        emptyValue: this.$t("newAnalysis.emptyValue"),
        topicDesc: this.$t("newAnalysis.topicDesc"),
        hint: this.$t("newAnalysis.senseDesc"),
        interruptionsDesc: this.$t("newAnalysis.interruptionsDesc"),
        keywordInputDesc: this.$t("newAnalysis.keywordInputDesc"),
        keywordsPlaceholder: this.$t("newAnalysis.keywordsPlaceholder"),
      };
    },
    functionAllTypes() {
      return Object.keys(functionTypes).map(
        (k) => this.$t(`newAnalysis.${k}`) as string
      );
    },
    selectItems(): { [key: string]: ICollection[] } {
      return {
        TONALITY_COLLECTION: TONALITY_COLLECTION,
        TONALITY_SPEC_COLLECTION: TONALITY_SPEC_COLLECTION,
        LANGUAGE_COLLECTION: LANGUAGE_COLLECTION,
      };
    },
  },
  mounted() {
    if (this.taskId) {
      this.form.id = this.taskId;
      this.form.language = this.$storeAnalysis.language;
      this.form.title = this.$storeAnalysis.title;
      this.form.speakers = this.$storeAnalysis.speakers_expected;
      this.form.tonality = this.$storeAnalysis.tonality_base_expected;
      this.form.tonality_spec = this.$storeAnalysis.tonality_spec_expected;
      this.form.keywords = this.$storeAnalysis.keywords_expected;
      this.form.overlap = this.$storeAnalysis.overlap !== null;
      this.form.sense = this.$storeAnalysis.sense !== null;
      this.form.topic = this.$storeAnalysis.topic !== null;
    } else if (this.taskIdArray) {
      this.form.id = this.taskIdArray;
    }
    this.storeSubscription = this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "analyzes/SET_TASK_ID":
          this.$router.push({
            name: "AnalysisMain",
            params: { id: state.analyzes.currentAnalyze.id },
          });
          break;
        default:
          break;
      }
    });
  },
  beforeDestroy() {
    if (this.errorCode) this.$store.dispatch("CLEAR_WS_ERROR_ACTION", null);
    this.storeSubscription();
  },
});
