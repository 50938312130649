import Vue from "vue";
import { Tariff } from "@/types/shared-types";
import TariffChangeModal from "@/components/modals/TariffChangeModal/index.vue";
import { IUser } from "@/store/profile/profile-types";
import { dateFormat } from "@/utils/helper";

export default Vue.extend({
  name: "Plans",
  components: {
    TariffChangeModal,
  },
  data() {
    return {
      newTariff: "",
      isTariffChange: false,
    };
  },
  computed: {
    tariff(): Tariff {
      return this.$store.state.profile.user.tariff;
    },
    isUpdateTariff() {
      return this.$store.state.profile.isUpdateTariff;
    },
    currentUser(): IUser {
      return this.$store.state.profile.user;
    },
    isEmailConfirmed(): boolean {
      return this.currentUser.email_confirmed;
    },
    tariffPeriodEnd() {
      const date = new Date(
        this.currentUser.tariff_period_end * 1000
      ).toString();

      const { month, day } = dateFormat(date);
      return `${day} ${this.$t(`settings.${month}`)}`;
    },
    planPrices() {
      return this.$store.state.profile.prices.plans;
    },
  },
  methods: {
    upgradeTariff(newTariff: string) {
      if (this.tariff === "freemium") {
        this.newTariff = newTariff;
        this.isTariffChange = true;
      } else if (newTariff === "freemium")
        this.$router.push({ name: "CancelingSubscriptions" });
      else {
        this.newTariff = newTariff;
        this.isTariffChange = true;
      }
    },
    renewTariff(tariff: string) {
      if (this.currentUser.cancel_at_period_end) {
        this.newTariff = tariff;
        this.isTariffChange = true;
      }
    },
    upgradeGuest(tariff: string) {
      localStorage.setItem("tariff", tariff);
      this.$router.push({ path: "/login" });
    },
  },
  updated() {
    if (!this.currentUser.is_guest) {
      if (localStorage.getItem("tariff")) {
        this.upgradeTariff(localStorage.getItem("tariff") as string);
      }
      localStorage.removeItem("tariff");
    }
  },
});
