import { RENAME_ANALYSIS } from "@/utils/validators";
import Vue from "vue";

export default Vue.extend({
  name: "RenameModal",
  props: ["dialog", "currentName", "taskId"],
  data() {
    return {
      newName: "",
      renameValidators: RENAME_ANALYSIS,

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      storeSubscription: () => {},
    };
  },
  methods: {
    rename() {
      (this.$refs.nameInput as HTMLInputElement).blur();
      
      const isValid = (
        this.$refs.form as Vue & { validate: () => boolean }
      ).validate();

      if (isValid) {
        this.$store.dispatch("analyzes/RENAME_TASK", {
          title: this.newName,
          id: this.taskId,
        });
      }
    },
  },
  created() {
    this.storeSubscription = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "analyzes/UPDATE_NAME":
          this.$emit("close");
          this.newName = '';
          break;
        default:
          break;
      }
    });
  },
  beforeDestroy() {
    this.newName = "";
    this.storeSubscription();
  },
});
