export const newAnalysis = {
  title: "New analysis",
  titleError: "Analysis name is not valid",
  file: "File",
  local: "Local File",
  zoom: "Zoom File",
  zombty: "Zombty Record",
  files: "{n} files uploaded",
  filesReject: "{n} files cannot be uploaded",
  uploadRepeat: "Upload again",
  upgradeStorage: "Upgrade Storage",
  upgradePlan: " Upgrade Plan",
  dndFile: "Select or drag and drop multiple files",
  loading: "Loading",
  localDesc:
    "Records in FLV, MP3, OGG, WAV, MP4 formats {n} and {n2} MB. (no more than 10 files)",
  availableSpace: "Available {n}",
  zoomDesc:
    "To upload a Zoom meeting records, you need to log in to your Zoom account and install Senseprofile app in Zoom app marketplace",
  zoomConfirm: "Add Senseprofile App",
  zombtyDesc:
    "To upload a Zombty meeting records, you need to link your Zombty account",
  zombtyLink: "Go to settings",
  lng: "Conversation language",
  optionsLabel: "Expected conversation options",
  optionsDesc:
    "These fields are optional. They will help to make the diarization more accurate.",
  speakersNum: "Number of speakers",
  sentiment: "Conversation sentiment",
  speechStyle: "Conversation speech style",
  emptyValue: "Not specified",
  detailsLabel: "Define details of the conversation",
  topic: "Topic",
  topicDesc: "In general, for example business, sports, art, politics",
  sense: "Sense",
  senseDesc:
    "What is the conversation about, its essence. For example contract or purchase of something",
  interruptions: "Analyse interruptions",
  interruptionsDesc: "If one speaker interrupted another and when",
  keywordsLabel: "Keywords and phrases",
  keywordsDesc:
    "Input a list of keywords that you want to find in the conversation.",
  keywordsPlaceholder: "Type keywords separated by comma",
  keywordInputDesc:
    "Type in keywords in singular and nominative case or adding from transcription",
  modalTitle: "Upgrade to use Sense detection",
  MORE_ANALYSIS: "More analysis each month",
  LONG_AUDIO: "Analysis of long audio recordings",
  EXPENDED_STORAGE: "Expanded storage",
  TONALITY_SENTIMENT: "Tonality sentiment detection",
  TONALITY_SPEECH: "Tonality speech detection",
  TOPIC: "Topic detection",
  INTERRUPTIONS: "Interruption analysis",
  KEYWORDS: "Keywords and phrases searches",
  SUBJECT: "Sense detection",
  upgrade: "Upgrade",
  cancel: "Cancel",
  analyze: "Analyze",
  upToMins: " up to {n} minutes",
  newName: "New name",
  step0: "Waiting for analisis",
  step1: "Step 1 of 9: Cropping file",
  step2: "Step 2 of 9: Uploading file",
  step3: "Step 3 of 9: Feature extraction",
  step4: "Step 4 of 9: Segmentation",
  step5: "Step 5 of 9: Embedding evaluation",
  step6: "Step 6 of 9: Clustering",
  step7: "Step 7 of 9: Transcribing",
  step8: "Step 8 of 9: Transcribing",
  step9: "Step 9 of 9: Finishing",
  from: "From",
  to: "To",
  duration: "Duration",
};
