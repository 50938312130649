import i18n from "@/plugins/i18n";
import { keywordsToArray } from "@/utils/helper";

export const VLIDATORS = {
  required: (value: string): boolean | string =>
    !!value || (i18n.t("errors.fieldIsRequired") as string),
  fileRequired: (value: File): boolean | string => {
    return (value !== undefined && !!value?.size) || "Audio is required";
  },

  spaces: (value: string): boolean | string =>
    !!value.trim() || (i18n.t("errors.fieldContainsOnlySpaces") as string),

  minPass: (v: string): boolean | string =>
    v.trim().length >= 8 ||
    "8 characters minimum, including at least one letter, one digit and one \n special character",

  min2: (v: string): boolean | string =>
    v.trim().length >= 2 || "Field must be at least 2 character",

  emailMatch: (v: string): boolean | string =>
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm.test(
      v
    ) || `Email must be like "some@email.com"`,

  passMatch: (v: string): boolean | string => {
    if (!/[0-9]/.test(v))
      return "8 characters minimum, including at least one letter, one digit and one \n special character";
    if (!/[a-z]/i.test(v))
      return "8 characters minimum, including at least one letter, one digit and one \n special character";
    if (!/[!”#$%&'()*+,-./:;<=>?@[\]^_`{|}~ "]/.test(v))
      return "8 characters minimum, including at least one letter, one digit and one \n special character";
    return true;
  },

  keywords: (v: string): boolean | string => {
    if (!v || Array.isArray(v)) return true;
    return (
      /^[a-zа-яё ,']+(?:-[a-zа-яё ,']+)*$/i.test(v) ||
      (i18n.t("newAnalysis.keywordInputDesc") as string)
    );
  },

  checkbox: (v: boolean): boolean | string =>
    !!v || "You must agree to continue!",

  noSpecialSymbols: (v: string): boolean | string =>
    !/[!”#$%&'()*+,-./:;<=>?@[\]^_`{|}~ "]/.test(v) ||
    "Field must not contain special character",

  beginsWithUpperCase: (v: string): boolean | string =>
    v.charAt(0) !== v.charAt(0).toLowerCase() ||
    `Field must begins with upper case`,

  speakers: (v: number): boolean | string =>
    (v > -1 && v <= 10) || `Value is invalid`,

  maxKeywords: (v: string): boolean | string => {
    if (!v || Array.isArray(v)) return true;
    return (
      keywordsToArray(v).length <= 100 ||
      (i18n.t("errors.maxKeywords") as string)
    );
  },

  keyPhrases: (v: string): boolean | string => {
    if (!v || Array.isArray(v)) return true;
    return (
      !keywordsToArray(v).filter(
        (k) => k.trim().split(" ").filter(Boolean).length > 3
      ).length || (i18n.t("errors.maxKeyPhrases") as string)
    );
  },

  validArrayKeywords: (v: string): boolean | string => {
    if (!v || Array.isArray(v)) return true;
    return (
      (v.split(", ").filter(Boolean).length === v.split(",").length &&
        v.split(", ").filter(Boolean).length === v.split(" ").length) ||
      (i18n.t("errors.validArrKeywords") as string)
    );
  },

  renameAnalysis: (v: string): boolean | string =>
    /^[A-zА-я0-9-_\s]{1,40}$/.test(v) ||
    (i18n.t("errors.invalidTitle") as string),

  maxEmailLength: 50,
  maxPassLength: 32,
  maxNameLength: 50,
  maxSpeakers: 20,
  maxTitleLength: 40,
  maxLastMessage: 1000,
};

export const PASSWORD_VALIDATORS = [
  VLIDATORS.required,
  VLIDATORS.spaces,
  VLIDATORS.minPass,
  VLIDATORS.passMatch,
];

export const EMAIL_VALIDATORS = [VLIDATORS.required, VLIDATORS.emailMatch];

export const NAME_VALIDATORS = [
  VLIDATORS.required,
  VLIDATORS.spaces,
  VLIDATORS.min2,
  VLIDATORS.noSpecialSymbols,
  VLIDATORS.beginsWithUpperCase,
];

export const RENAME_ANALYSIS = [
  VLIDATORS.required,
  VLIDATORS.renameAnalysis,
  VLIDATORS.spaces,
];
