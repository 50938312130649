import Vue from "vue";
import { getStoragePricing, SizeSign } from "@/utils/helper";

export default Vue.extend({
  name: "SizingTool",
  data() {
    return {
      presetsButtons: [
        { value: 100, sizeSign: "mb" },
        { value: 1, sizeSign: "gb" },
        { value: 10, sizeSign: "gb" },
        { value: 100, sizeSign: "gb" },
        { value: 1, sizeSign: "tb" },
      ],
      sizeSign: <SizeSign>"mb",
      additionalSize: 0,
      isShowTooltip: false,
      valid: true,
    };
  },
  computed: {
    darkInput() {
      return !this.$vuetify.theme.dark;
    },
    isFreemiumTariff() {
      return this.$store.state.profile.user.tariff === "freemium";
    },
    subPrices() {
      return this.$store.state.profile.prices.sub_plans;
    },
  },
  watch: {
    additionalSize: {
      handler(newValue) {
        if (newValue > 1000 && this.sizeSign !== SizeSign.TB) {
          this.additionalSize = 1000;
          this.showTooltip();
          return;
        }

        if (newValue > 10 && this.sizeSign === SizeSign.TB) {
          this.additionalSize = 10;
          this.showTooltip();
        }
      },
    },
    sizeSign: {
      handler(newValue) {
        if (newValue === SizeSign.TB && this.additionalSize > 10)
          this.additionalSize = 10;
      },
    },
  },
  methods: {
    onInput(value: string) {
      if (!/^\d+$/.test(value)) this.additionalSize = 0;
    },
    setSize(val: number, sizeSign: SizeSign) {
      this.additionalSize = val;
      this.sizeSign = sizeSign;
    },
    setSizeSign(sizeSign: SizeSign) {
      this.sizeSign = sizeSign;
    },
    calculatePrice() {
      return getStoragePricing(this.sizeSign, this.additionalSize || 0);
    },
    checkValue(evt: KeyboardEvent): void {
      const keysAllowed: string[] = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ];
      const isNumber = keysAllowed.includes(evt.key);
      const isTerabyte = this.sizeSign === SizeSign.TB;
      const lengthValue = this.additionalSize.toString().length;
      const isPreventDefault =
        !isNumber ||
        (lengthValue === 4 && !isTerabyte) ||
        (lengthValue === 2 && isTerabyte) ||
        (evt.key === "0" && this.additionalSize.toString() === "0");

      if (isPreventDefault) evt.preventDefault();
    },
    showTooltip() {
      this.isShowTooltip = true;

      setTimeout(() => {
        this.isShowTooltip = false;
      }, 2500);
    },
    submit() {
      const data = {
        sub_plan: "storage_" + this.sizeSign,
        quantity: this.additionalSize,
      };
      this.$store.dispatch("profile/ADD_STORAGE_SPACE", data);
    },
  },
});
