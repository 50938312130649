import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ZoomIcon from "@/components/ZoomIcon/index.vue";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#fd3939",
      },
      dark: {
        primary: "#fd3939",
      },
    },
  },
  icons: {
    values: {
      zoom: {
        component: ZoomIcon,
      },
    },
  },
});
