import store from "@/store";
import { WSResponse } from "@/types/ws-response-types";
import { WSRequest } from "@/types/ws-request-types";
import { addDownloadLink } from "@/store/analyzes/downloading-queue";

export class WS {
  public socket: WebSocket | null = null;
  public eventTarget: EventTarget | null = null;
  public fallbackQueue: WSRequest[] = [];

  connect(): void {
    const apiUrl = `${process.env.VUE_APP_API_WS_PROTOCOL}${process.env.VUE_APP_API_HOST}/ws`;

    const socket = new WebSocket(apiUrl);
    socket.onmessage = (event) => this.onWsMessage(event);
    socket.onopen = () => {
      if (this.fallbackQueue.length)
        this.fallbackQueue.forEach((req) => this.sendWSRequest(req));
      this.fallbackQueue = [];
    };
    socket.onerror = () => store.dispatch("WS_DISCONNECTED");

    this.socket = socket;
  }

  disconnect(): void {
    if (this.socket) this.socket.close(1000);
    store.dispatch("WS_DISCONNECTED");
  }

  public onWsMessage(event: MessageEvent): void {
    if (typeof event.data === "string") {
      const res: WSResponse = JSON.parse(event.data.toString());
      switch (res.path) {
        case "analyzer.now_state_tasks":
          store.dispatch("WS_CONNECTED");
          store.dispatch("analyzes/SET_NOW_STATE_TASKS_ACTION", res.data);
          store.dispatch("profile/GET_USER_SETTINGS");
          store.dispatch("profile/GET_USER_TARIFF_SETTINGS");
          break;
        case "analyzer.get_list":
          store.dispatch("analyzes/SET_TABLE_DATA", res.data);
          break;
        case "analyzer.analyze":
          if (res.data?.analyze) {
            store.dispatch("analyzes/ANALYZE_STARTED", res.data);
            store.dispatch("profile/GET_USER_TARIFF_SETTINGS");
          }
          break;
        case "analyzer.analyze_again":
          if (res.data?.analyze) {
            store.dispatch("analyzes/ANALYZE_AGAIN_STARTED", res.data);
          }
          break;
        case "analyzer.multianalyze":
          if (res.data?.analyzes) {
            store.dispatch("analyzes/MULTI_ANALYZE_STARTED", res.data);
            store.dispatch("profile/GET_USER_TARIFF_SETTINGS");
          }
          break;
        case "analyzer.multianalyze_again":
          if (res.data?.analyzes) {
            store.dispatch("analyzes/MULTI_ANALYZE_AGAIN_START", res.data);
          }
          break;
        case "analyzer.updated_state_task":
          if (res.data?.task_id)
            store.dispatch("analyzes/ANALYZE_UPDATE_STATUS", res.data);
          break;
        case "analyzer.analyze_recheck_keywords":
          if (res.data?.task_id)
            store.dispatch("analyzes/ANALYZE_UPDATE_STATUS", res.data);
          break;
        case "analyzer.analyze_rename_title":
          if (res.data?.task_id)
            store.dispatch("analyzes/ANALYZE_UPDATE_NAME", res.data);
          break;
        case "analyzer.analyze_delete":
          if (res.data?.task_id) {
            store.dispatch("analyzes/TASK_DELETED", res.data);
          }
          break;
        case "analyzer.analyze_duplicate":
          if (res.data?.task_id)
            store.dispatch("analyzes/TASK_DUPLICATED", res.data);
          else store.dispatch("analyzes/GET_ANALYZES_LIST", 1);
          break;
        case "analyzer.analyze_abort":
          if (res.data?.task_id)
            store.dispatch("analyzes/TASK_ABORTED", res.data);
          break;
        case "user.get_settings":
          if (res.data?.id)
            store.dispatch("profile/SET_USER_SETTINGS_ACTION", res.data);
          break;
        case "user.tariff_settings":
          if (res.data)
            store.dispatch("profile/SET_USER_TARIFF_SETTINGS_ACTION", res.data);
          break;
        case "tariff.update":
          if (res) {
            store.dispatch("profile/GET_USER_SETTINGS");
            store.dispatch("profile/GET_USER_TARIFF_SETTINGS");
            store.dispatch("profile/SET_TARIFF_LOADING_ACTION", false);
          }
          break;
        case "user.cancel_change_email":
          if (res)
            store.dispatch("profile/CANCEL_CHANGE_EMAIL_SUCCESS", res.data);
          break;
        case "zoom.authorize":
          if (res)
            store.dispatch("profile/SET_ZOOM_AUTHORIZATION_ACTION", res.data);
          break;
        case "analyzer.generate_report":
          if (res.data?.link) addDownloadLink(res.data.link);
          break;
        default:
          break;
      }
      if (res.error) {
        store.dispatch("profile/LOGOUT_USER");
        store.dispatch("WS_ERROR", res.error);
      }
    }
  }

  public sendWSRequest(req: WSRequest): void {
    if (this.socket) {
      if (this.socket.readyState === this.socket.OPEN) {
        this.socket.send(JSON.stringify(req));
      } else {
        this.fallbackQueue.push(req);
        if (this.socket.readyState !== this.socket.CONNECTING) this.connect();
      }
    }
  }
}
