import {
  FILTER_CONDITIONS,
  FILTER_SUBJECTS,
  FilterType,
  formatDateByTimezone,
  formatLength,
  ICollection,
  IFilter,
  IFilterCondition,
  IFilterItem,
  sizeFormat,
  TONALITY_COLLECTION,
  TONALITY_SPEC_COLLECTION,
} from "@/utils/helper";
import Vue from "vue";
import {
  ITableHeaders,
  LANGUAGE_COLLECTION,
  TABLE_COLUMNS,
  tonalityTypes,
} from "@/utils/helper";
import RenameModal from "@/components/modals/RenameModal/index.vue";
import { Analysis, ICroppedDuration } from "@/store/analyzes/analyzes-types";
import draggable from "vuedraggable";
import { ITaskList } from "@/types/ws-response-types";
import ReAnalyzeModal from "@/components/modals/ReAnalyzeModal/index.vue";
import { ErrorsMessages } from "@/utils/errors";
import languagesMixin from "@/mixins/languagesMixin";

export default Vue.extend({
  name: "AnalyzesTable",
  components: { RenameModal, draggable, ReAnalyzeModal },
  mixins: [languagesMixin],
  data() {
    return {
      headers: TABLE_COLUMNS.map((col) => ({
        ...col,
        text: this.$t(`analyzes.${col.text}`),
      })),
      selectedItems: <ITaskList[]>[],
      language: LANGUAGE_COLLECTION,
      wsErrors: ErrorsMessages,
      itemsPerPage: +process.env.VUE_APP_TABLE_ITEMS_PER_PAGE,
      selectedTask: {},
      allFilterTitles: <FilterType[]>[
        "title",
        "language",
        "tonality_spec_detected",
        "tonality_base_detected",
        "keywords_spotted",
      ],

      speechStyleDetails: TONALITY_SPEC_COLLECTION,
      sentimentDetails: TONALITY_COLLECTION,
      renameDialog: false,
      filters: <IFilter[]>[],
      reAnalyzeDialog: false,
      reanalyzeItemId: <number | null>null,

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      storeSubscription: () => {},

      searchFilter: "all",
      debounceSearch: <number | null>null,
      isFiltersOpened: false,
      loadingTask: false,
      activeSearchValue: "",
    };
  },
  watch: {
    headers: {
      handler() {
        localStorage.setItem("tableHeaders", JSON.stringify(this.headers));
      },
      deep: true,
    },
  },

  created() {
    // const tableHeaders = localStorage.getItem("tableHeaders");

    this.storeSubscription = this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "SET_WS_STATUS":
          if (
            state.isSocketConnected &&
            !state.analyzes.analyzesList.tasks.length
          )
            this.$store.dispatch(
              "analyzes/GET_ANALYZES_LIST",
              this.currentPage
            );
          break;
        case "analyzes/BULK_DELETE_SUCCESS":
          this.selectedItems = [];
          break;
        case "analyzes/BULK_TASK_DELETED":
          this.selectedItems = this.selectedItems.filter(
            (i) => i.id !== mutation.payload
          );
          break;
        default:
          break;
      }
    });
  },

  computed: {
    $storeAnalysis(): Analysis {
      return this.$store.state.analyzes.currentAnalyze;
    },
    rows(): ITaskList[] {
      return this.$store.state.analyzes.analyzesList.tasks;
    },
    activeFilters() {
      return Object.keys(this.$store.state.analyzes.analyzesList.filters)
        .length;
    },
    filteredHeaders(): ITableHeaders[] {
      return this.headers.filter((col) => col.visible);
    },
    currentPage: {
      get: function (): number {
        return this.$store.getters["analyzes/GET_CURRENT_PAGE"];
      },
      set: function (value: number): void {
        this.$store.dispatch("analyzes/UPDATE_PAGE", value);
        this.selectedItems = [];
      },
    },
    totalPage(): number {
      return Math.ceil(this.total / this.itemsPerPage);
    },
    sortOrder(): string {
      return this.$store.state.analyzes.analyzesList.sort_order;
    },
    orderBy(): string {
      return this.$store.state.analyzes.analyzesList.sort_by;
    },
    total(): number {
      return this.$store.state.analyzes.analyzesList.total;
    },
    to(): number {
      return this.currentPage * this.itemsPerPage > this.total
        ? this.total
        : this.currentPage * this.itemsPerPage;
    },
    infoStorageFill() {
      return this.$store.getters["profile/GET_INFO_STORAGE_FILL"];
    },
    analyzesAvailable(): number {
      return this.$store.getters["profile/GET_ANALYZES_AVAILABLE"];
    },
    selectedInProgress(): ITaskList[] {
      return this.selectedItems.filter(
        (i) => !["failed", "completed", "aborted"].includes(i.status)
      );
    },
    selectedWithError(): ITaskList[] {
      return this.selectedItems.filter((i) => ["failed"].includes(i.status));
    },
    idArrayToReanalyze(): number[] {
      return this.selectedItems
        .filter((i) => ["failed", "completed", "aborted"].includes(i.status))
        .map((i) => i.id);
    },
    usedFilterTitles(): FilterType[] {
      return this.filters.map((f) => f.subject);
    },
    filteredSubjects(): IFilterItem[] {
      return this.subjects.map((s) => ({
        ...s,
        disabled: this.usedFilterTitles.includes(s.value),
      }));
    },
    isConfirmedEmail() {
      return this.$store.state.profile.user.email_confirmed;
    },
    errorCode() {
      return this.$store.state.errorWS;
    },
    isMultiplyDeleteLoading() {
      return this.$store.state.analyzes.isMultiplyDeleteLoading;
    },
    isGuestLogin() {
      return this.$store.state.profile.user.is_guest;
    },
    filtersDetails(): { [key: string]: ICollection[] } {
      return {
        language: this.language,
        tonality_spec_detected: this.speechStyleDetails,
        tonality_base_detected: this.sentimentDetails,
      };
    },
    translation() {
      return {
        fields: this.$t("analyzes.fields"),
        title: this.$t("analyzes.titleField"),
        topic: this.$t("analyzes.topic"),
        sense: this.$t("analyzes.sense"),
        sentiment: this.$t("analyzes.sentiment"),
        speech: this.$t("analyzes.speech"),
        search: this.$t("analyzes.search"),
        value: this.$t("analyzes.val"),
        nodata: this.$t("analyzes.emptyTable"),
      };
    },
    subjects() {
      return <IFilterItem[]>FILTER_SUBJECTS.map((filter) => ({
        ...filter,
        text: this.$t(`analyzes.${filter.text}`),
      }));
    },
    conditions() {
      return <IFilterCondition[]>FILTER_CONDITIONS.map((filter) => ({
        ...filter,
        text: this.$t(`analyzes.${filter.text}`),
      }));
    },
    tonalityTypes() {
      return Object.keys(tonalityTypes).reduce(
        (acc, val) => ({ ...acc, [val]: this.$t(`analyzes.${val}`) }),
        {}
      );
    },
  },
  methods: {
    openTask(id: number) {
      this.$router.push({
        name: "AnalysisMain",
        params: { id: id.toString() },
      });
    },
    setLanguage(lang: string) {
      const res = this.language.find((i) => i.value === lang);
      if (res) return res.title;
      else return lang;
    },
    timeFormat(date: string): string {
      return formatDateByTimezone(date)
        .split(" ")
        .map((d, i) => (i === 0 ? this.$t(`settings.${d}`) : d))
        .join(" ");
    },
    sizeFormat(size: number): string {
      return sizeFormat(size);
    },
    async reanalyzeTask(id: number) {
      this.reanalyzeItemId = id;
      await this.getTaskWithReturn(id);
      this.reAnalyzeDialog = true;
    },
    duplicateTask(id: number) {
      this.$store.dispatch("analyzes/DUPLICATE_TASK", id);
    },
    deleteTask(id: number | null = null) {
      if (id) this.$store.dispatch("analyzes/ADD_TASK_TO_DELETE_ACTION", id);
      else {
        const deleteIDs = this.selectedItems
          .filter((i) => ["failed", "completed", "aborted"].includes(i.status))
          .map((i) => i.id);
        if (deleteIDs.length)
          this.$store.dispatch(
            "analyzes/ADD_MULTIPLY_DELETE_ACTION",
            deleteIDs
          );
      }
    },
    renameTask(task: Analysis) {
      this.selectedTask = task;
      this.renameDialog = true;
    },
    calculateKeywords(exp: string, det: string): string {
      if (exp) {
        return ` ${this.$t("analisis.of", {
          i: (det || "").split(",").filter(Boolean).length,
          n: exp.split(",").length,
        })}`;
      } else return "";
    },
    sort(orderBy: string) {
      if (orderBy !== this.orderBy) {
        this.$store.state.analyzes.analyzesList.sort_by = orderBy;
        this.$store.state.analyzes.analyzesList.sort_order = "desc";
      } else if (this.sortOrder === "desc") {
        this.$store.state.analyzes.analyzesList.sort_order = "asc";
      } else {
        this.$store.state.analyzes.analyzesList.sort_order = "desc";
      }
      this.$store.dispatch("analyzes/GET_ANALYZES_LIST", this.currentPage);
    },
    addNewFilter() {
      if (this.filters.length >= this.allFilterTitles.length) return;
      const allowedFilters = this.allFilterTitles.filter(
        (t) => !this.usedFilterTitles.includes(t)
      );
      this.filters.push({
        subject: allowedFilters[0],
        condition: true,
        value: "",
      });
      this.setDefaultFilterValue(allowedFilters[0]);
    },
    removeFilter(idx: number) {
      this.filters.splice(idx, 1);
    },
    clearFilters() {
      this.filters.splice(0);
      this.$store.dispatch("analyzes/APPLY_FILTERS", this.filters);
      this.isFiltersOpened = false;
    },
    closeReAnalyzeDialog() {
      this.$store.dispatch("analyzes/CLEAR_CURRENT_TASK_ACTION");
      this.reanalyzeItemId = null;
      this.reAnalyzeDialog = false;
    },
    downloadPdf(id = null) {
      const toDownload: ITaskList[] = this.selectedItems.filter((i) =>
        ["completed"].includes(i.status)
      );
      if (id) {
        this.$store.dispatch("analyzes/GET_PDF_LINK", id);
      } else if (toDownload.length) {
        let i = 0;
        do {
          this.$store.dispatch("analyzes/GET_PDF_LINK", toDownload[i].id);
          i++;
        } while (i < toDownload.length);
      }
    },
    async downloadAudio(link: string) {
      if (link) {
        this.$store.dispatch("analyzes/DOWNLOAD_PDF", link);
      } else {
        for (const item of this.selectedItems) {
          await new Promise<void>((resolve) => {
            this.$store.dispatch("analyzes/DOWNLOAD_PDF", item.link);
            setTimeout(() => {
              resolve();
            }, 1000);
          });
        }
      }
    },
    search(value: string) {
      this.$store.dispatch("analyzes/ANALYZES_SEARCH", {
        search_by: this.searchFilter,
        search_value: this.activeSearchValue,
      });
    },
    searchWithDebounce(value: string) {
      if (this.debounceSearch) clearInterval(this.debounceSearch);
      this.debounceSearch = setTimeout(() => {
        this.search(value);
      }, 1000);
    },
    applyFilters() {
      this.$store.dispatch("analyzes/APPLY_FILTERS", this.filters);
      this.isFiltersOpened = false;
    },
    getTaskWithReturn(task_id: number) {
      this.loadingTask = true;
      this.$store
        .dispatch("analyzes/GET_TASK_ANALYZE", {
          id: task_id,
          withReturn: true,
        })
        .then((res: Analysis) => {
          this.loadingTask = false;
          this.$store.commit("analyzes/SET_TASK_ANALYZE", res);
          this.$store.commit("analyzes/CALCULATE_STATISTICS");
        })
        .catch((err) => {
          this.loadingTask = false;
          console.log(err);
        });
    },
    setDefaultFilterValue(filterName: string) {
      const filter = this.filters.find((f) => f.subject === filterName);

      if (!filter) return;

      const defaultFilterValue = this.filtersDetails[filterName];

      if (!defaultFilterValue?.length) {
        filter.value = "";
      } else {
        filter.value = defaultFilterValue[0].value;
      }
    },
    getFilterDetails(filterName: string) {
      return (
        this.filtersDetails[filterName].map((detail) => ({
          ...detail,
          title: this.$tc(`analyzes.${detail.value}`),
        })) || []
      );
    },
    isDisplayableSubject(filterName: string) {
      const displayableSubjects = [
        "language",
        "tonality_spec_detected",
        "tonality_base_detected",
      ];
      return displayableSubjects.includes(filterName);
    },
    croppedDuration({ duration, file_id }: Analysis): string {
      const croppedDurations: ICroppedDuration[] =
        this.$store.state.analyzes.croppedDurations;
      const croppedDuration = croppedDurations.find(
        (item) => item.id === file_id
      )?.croppedDuration;

      if (!croppedDuration) return duration;

      const formattedDuration = formatLength(croppedDuration * 1000);

      return formattedDuration;
    },
    speakersCol(
      speakers: number,
      speakersBeforeFiltration: number | undefined
    ): string {
      const displaySpeakers = speakers ? speakers : "-";
      const displaySpeakersBeforeFiltration = speakersBeforeFiltration
        ? speakersBeforeFiltration
        : "-";

      return `${displaySpeakers} / ${displaySpeakersBeforeFiltration}`;
    },
  },

  mounted() {
    this.filters = this.$store.getters["analyzes/GET_SAVED_FILTERS"];
    if (this.$store.state.isSocketConnected)
      this.$store.dispatch("analyzes/GET_ANALYZES_LIST", this.currentPage);
  },

  beforeDestroy() {
    if (this.errorCode) this.$store.dispatch("CLEAR_WS_ERROR_ACTION", null);
    this.storeSubscription();
  },
});
