export const pricing = {
  title: "Планы",
  min: "{n} минут | {n} минута | {n} минуты | {n} минут| {n} минут",
  day: "дней",
  priceMonth: "{price} в месяц",
  premDuration: "Безлимитно",
  current: "Текущий план",
  storageTime: "Пока действует подписка",
  freemium: "Freemium",
  standart: "Standart",
  standartPlus: "Standart Plus",
  premium: "Premium",
  unlimited: "Без лимита",
  free: "Попробуйте бесплатно",
  perMonth: "Запросов в месяц",
  numPerMonth: "Количество запросов в месяц",
  storageCap: "Вместительность хранилища",
  filesLife: "Срок годности аудиофайлов",
  toneRec: "Распознование тона",
  expRes: "Экспорт результатов",
  keySearch: "Поиск ключевых фраз",
  expansion: "Расширение хранилища",
  topicRec: "Распознавание темы разговора",
  mood: "Определение настроения разговора",
  interRec: "Распознавание перебиваний",
  maxDur: "Максимальная продолжительность каждой анализируемой записи",
  space: "Место для хранения",
  storageTimeLabel: "Время хранения файлов",
  tonality: "Тональность",
  export: "Экспорт результата в PDF",
  keyphrases: "Поиск ключевых фраз",
  extraStorage: "Дополнительное хранение",
  topic: "Тема",
  sense: "Чувство",
  interruptions: "Перебивания",
  guestLink: "Зарегистрируйтесь",
  guestLink2: ", чтобы получить 10 запросов бесплатно",
  addStorageTitle: "Дополнительно место для хранения",
  maxStorage: "Лимит на одну покупку 1000 Mb, 1000 Gb или 10 TB",
  addStorageDesc:
    "Когда ваш аккаунт достигнет лимита хранения, вы сможете оплатить дополнительное место",
  exactNum: "Точное число",
  add: "Добавить",
  readyValues: "Готовые значения",
  addStorageButton: "Покупка недоступна для плана Freemium",
  activeStorageButton: "Выберите значение",
  subscribe: "Подписаться за",
  addStorageButtonDesc:
    "Выбранное значение будет добавлено в ваше существующее хранилище после оплаты.",
  faq11: "Какие способы оплаты принимаются?",
  faq12:
    "Вы можете использовать Кредитные/Дебетовые карты, Alipay, Google pay, Apple pay, ACH Credit Transfer, ACH Debit для оплаты.",
  faq21: "Как продлить мою подписку?",
  faq22:
    "Все подписки обновляются автоматически, пока пользователь не отменит их. После отмены платной подписки пользователь автоматически переключается на план Freemium.",
  faq31: "Что произойдет с моими файлами, когда закончится моя подписка?",
  faq32:
    "После истечения срока платной подписки все файлы будут храниться в течение 30 дней, а затем удаляться. В течение 30 дней пользователь может загрузить свои файлы в любое время.",
  faq41:
    "Что, если я захочу перейти от более дорогостоящей к более дешевой подписке?",
  faq42:
    "При переходе с более дорогой на более дешевую подписку, пользователю будет предложено либо сохранить все свои файлы, которые превышают лимит пространства новой подписки, либо удалить их.",
  faq51: "Как я могу отменить платную или Freemium подписку?",
  faq52: "Вы можете отменить любую подписку в настройках.",
  faq61: "Как экспортировать результаты после обработки файлов?",
  faq62:
    "На странице анализа, после выделения нужного файла и нажатия многоточия, пользователю будет предложено меню, в котором можно загрузить выбранный файл.",
  faqFooter: "Не нашли ответа на ваш вопрос?",
  faqFooter2: " Свяжитесь с нами",
  signup: "Зарегистрируйтесь",
  signup2: "чтобы получить 10 запросов бесплатно",
  emailConfirm: "Email ждет подтверждения.",
  emailConfirm2: "Подтвердите Email прежде чем выбирать план.",
  progress: "В процессе",
  until: "До {n}",
  renew: "Продлить",
  changePlan: "Изменить план подписки",
  changeConfirm:
    "Вы уверены, что хотите поменять план подписки? Цена подписки будет {n}$ в месяц.",
  accept: "Принять",
  cancelTitle: "Отменить подписку",
  cancelLabel: "После отмены подписки, вы потреяте преимущества {n}",
  cancelAnalysis: "Анализов в месяц: {n}",
  cancelAudioLength: "Размер аудиозаписи: {n}",
  cancelStorage: "Хранилище: {n}",
  cancelTopic: "Распознавание тем",
  cancelSense: "Распознавание смысла",
  cancelInterruption: "Анализ прерываний",
  cancelDesc:
    "Данные, не соответствующие новым условиям, будут храниться в течение 30 дней без возможности доступа к ним. После этого анализы, не соответствующие действующей ставке, будут удалены безвозвратно. Вы можете сохранить данные локально на своем устройстве с помощью функции экспорта или продлить подписку.",
  cancelSave: "Сохранить подписку",
  cancelConfirm: "Отменить подписку",
  success: "Успешно",
  upgradedSuccess: "You have been upgraded to Senseprofile { n } plan.",
  goBack: "Вернуться к Senseprofile",
  manage: "Управление подпиской",
  fail: "Отказ",
  failDesc: "План не был изменен из-за ошибки платежа.",
  tryAgain: "Попробовать еще раз",
  processDesc:
    "В процессе, как только Stripe произведет оплату, к вашему существующему хранилищу будет добавлено дополнительное место.",
  faq: "Вопросы и ответы",
  tarifUpgrage11: "Улучшите чтобы использовать распознавание Тональности",
  tarifUpgrage12:
    "Функция Тема розволяет вам понять о чем идет разговор. Улучшите тариф, чтобы использовать больше функций:",
  tarifUpgrage21: "Улучшите чтобы использовать распознавание Темы",
  tarifUpgrage31: "Улучшите чтобы использовать распознавание Чувства разговора",
  tarifUpgrage41: "Улучшите чтобы использовать распознавание перебиваний",
  changeTariffModalText:
    "Вы уверены, что хотите изменить свой план подписки? Стоимость подписки составит {n}₽ в месяц, оплату необходимо будет произвести сразу.",
  changeTariffModalText2:
    "Вы уверены, что хотите изменить свой план подписки прямо сейчас? Стоимость подписки составит {n}₽ в месяц.",
  tarifChangeAfterDate:
    "Тариф поменяется на бесплатный после истечения текущей подписки",
};
