import { WS } from "@/ws/ws";
import axios, { AxiosError } from "axios";
import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { analyzes } from "./analyzes/index";
import { profile } from "./profile/index";
import { RootState } from "./store-types";
import { zoom } from "@/store/zoom";
import { zombty } from "@/store/zombty";
import vuetify from "@/plugins/vuetify";
import { themeInterval } from "@/utils/themeInterval";
import { getGlobalTheme, LANGUAGE_COLLECTION } from "@/utils/helper";
import { JWTErrors } from "@/utils/errors";

Vue.use(Vuex);
export const client = new WS();
let errorTimeout: number | null = null;
let successTimeout: number | null = null;

const api = process.env.VUE_APP_API_XHR_PROTOCOL + process.env.VUE_APP_API_HOST;

const store: StoreOptions<any> = {
  state: {
    isSocketConnected: false,
    errorWS: null,
    errorAPI: null,
    successCode: null,
    changeThemeInterval: undefined,
    availableLanguages: [],
  },
  modules: {
    profile,
    analyzes,
    zoom,
    zombty,
  },
  mutations: {
    SET_WS_STATUS(state: RootState, payload: boolean) {
      state.isSocketConnected = payload;
    },
    SET_WS_ERROR(state: RootState, error) {
      if (error.code) state.errorWS = error.code;
      else state.errorWS = -2; // unknown error
    },
    SET_API_ERROR(state: RootState, error) {
      if (error.code) state.errorAPI = error.code;
      else state.errorAPI = -2; // unknown error
      if (error.code === 0) {
        if (errorTimeout) clearInterval(errorTimeout);
        errorTimeout = setTimeout(() => {
          if (state.errorAPI) state.errorAPI = null;
        }, 5000);
      }
    },
    CLEAR_WS_ERROR(state: RootState) {
      state.errorWS = null;
    },
    CLEAR_API_ERROR(state: RootState) {
      state.errorAPI = null;
    },
    SET_SUCCESS(state: RootState, code: number) {
      state.successCode = code;
      if (successTimeout) clearInterval(successTimeout);
      successTimeout = setTimeout(() => {
        state.successCode = null;
      }, 5000);
    },
    CLEAR_THEME_INTERVAL(state: RootState) {
      clearInterval(state.changeThemeInterval);
      state.changeThemeInterval = undefined;
    },
    CHANGE_THEME_INTERVAL(state: RootState) {
      themeInterval();
      state.changeThemeInterval = setInterval(themeInterval, 60000 * 60 * 10);
    },
    SET_AVAILABLE_LANGUAGES(state: RootState, languages: string[]) {
      state.availableLanguages = languages;
    },
    SET_IS_LOGIN_CHECK(state: RootState, val: boolean) {
      state.profile.isLoginChecked = val;
    },
  },
  actions: {
    CONNECT_TO_WS({ dispatch }) {
      dispatch("SET_LOGIN_LOADING_ACTION", true);

      client.connect();
      dispatch("SET_THEME", getGlobalTheme());
    },
    DISCONNECT_WS() {
      client.disconnect();
    },
    WS_DISCONNECTED({ commit }) {
      commit("SET_WS_STATUS", false);
    },
    WS_CONNECTED({ commit }) {
      commit("SET_WS_STATUS", true);
    },
    WS_ERROR({ commit }, error) {
      commit("SET_WS_ERROR", error);
    },
    API_ERROR({ commit }, error) {
      commit("SET_API_ERROR", error);
    },
    CLEAR_WS_ERROR_ACTION({ commit, state }, code: number | number[] | null) {
      if (Array.isArray(code)) {
        code.forEach((c) => {
          if (state.errorWS === c) commit("CLEAR_WS_ERROR");
        });
      } else if (!code || state.errorWS === code) commit("CLEAR_WS_ERROR");
    },
    CLEAR_API_ERROR_ACTION({ commit, state }, code: number | number[] | null) {
      if (Array.isArray(code)) {
        code.forEach((c) => {
          if (state.errorAPI === c) commit("CLEAR_API_ERROR");
        });
      } else if (!code || state.errorAPI === code) commit("CLEAR_API_ERROR");
    },
    API_ERROR_HANDLER({ commit }, err: Error | AxiosError) {
      commit("SET_IS_LOGIN_CHECK", false);
      if (axios.isAxiosError(err)) {
        if (err?.response?.data?.error) {
          if (JWTErrors.includes(err.response.data.error.code)) {
            this.state.profile.user.id = null;
          }

          commit("SET_API_ERROR", err.response.data.error);
        } else if (err.response) {
          commit("SET_API_ERROR", {
            code: err.response.status,
            message: err.message,
          });
        } else commit("SET_API_ERROR", { message: err.message });
      } else {
        commit("SET_API_ERROR", { message: err.message });
      }
    },
    SET_SUCCESS_ALERT({ commit }, code: number) {
      commit("SET_SUCCESS", code);
    },
    SET_THEME({ commit }, theme: string) {
      if (theme === "auto") {
        commit("CHANGE_THEME_INTERVAL");
        const currentTime = new Date().getHours();
        if (currentTime > 5 && currentTime < 19) theme = "light-theme";
        else theme = "dark-theme";
      } else {
        commit("CLEAR_THEME_INTERVAL");
      }
      vuetify.framework.theme.dark = theme === "dark-theme";
      document.documentElement.className = theme;
    },
    async GET_AVAILABLE_LANGUAGES({ commit, dispatch }) {
      try {
        const res = await axios.get(`${api}/api/getConversationLanguages`);

        if (!res.data.error) {
          commit("SET_AVAILABLE_LANGUAGES", res.data.response?.Languages ?? []);
        }
      } catch (err) {
        dispatch("API_ERROR_HANDLER", err, { root: true });
      }
    },
  },
  getters: {
    GET_LANGUAGES_DETAILS(state: RootState) {
      return LANGUAGE_COLLECTION.map((language) => ({
        ...language,
        disabled: state.availableLanguages.includes(language.value)
          ? false
          : language.disabled,
      }));
    },
  },
};

export default new Vuex.Store<RootState>(store);
