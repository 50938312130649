import { InitialTaskList } from "@/store/analyzes/analyzes-types";
import { IGlobalState } from "@/store/store-types";
import { ANALYSIS_PROGRESS_STATUS } from "@/utils/helper";
import Vue from "vue";

export default Vue.extend({
  name: "HeaderProgress",
  data() {
    return {
      statuses: ANALYSIS_PROGRESS_STATUS,

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      storeSubscription: () => {},
    };
  },
  computed: {
    isAuth(): boolean {
      return !["/login", "/register"].includes(this.$route.path);
    },
    tasksInProgress(): InitialTaskList[] | [] {
      if (this.$store.state.analyzes.initialTaskList?.length)
        return this.$store.state.analyzes.initialTaskList;
      else return [];
    },
    totalProgress(): number {
      if (this.tasksInProgress.length === 0) return 0;
      let counter = 0;
      this.tasksInProgress.forEach((t) => {
        counter += this.statuses[t.status].value;
      });
      counter = Math.round(counter / this.tasksInProgress.length);
      if (counter >= 100) return 100;
      else return counter;
    },
    tasksWithError(): InitialTaskList[] | [] {
      return this.tasksInProgress.filter((t) => t.status === "failed");
    },
    finishedTasks(): InitialTaskList[] | [] {
      return this.tasksInProgress.filter((t) =>
        ["aborted", "failed", "completed"].includes(t.status)
      );
    },
    translation() {
      return {
        errorAnalysis: this.$t("header.errorAnalysis"),
        successAnalysis: this.$t("header.successAnalysis"),
      };
    },
  },
  created() {
    this.storeSubscription = this.$store.subscribe(
      (mutation, state: IGlobalState) => {
        switch (mutation.type) {
          case "analyzes/SET_NOW_STATE_TASKS":
            break;
          case "analyzes/SET_TASK_STATUS":
            {
              const { status, task_id } = mutation.payload;
              if (["aborted", "failed", "completed"].includes(status)) {
                if (
                  state.analyzes.currentAnalyze.id === task_id &&
                  this.$route.name &&
                  ["AnalysisMain", "AnalysisSpeakersStatistics"].includes(
                    this.$route.name
                  )
                ) {
                  this.$store.dispatch(
                    "analyzes/REMOVE_TASK_FROM_PROGRESS_ACTION",
                    task_id
                  );
                }
              }
            }
            break;
          default:
            break;
        }
      }
    );
  },
  methods: {
    goToTask(id: number, status: string) {
      if (["aborted", "failed", "completed"].includes(status)) {
        this.$store.dispatch("analyzes/REMOVE_TASK_FROM_PROGRESS_ACTION", id);
      }
      if (this.$route.params?.id !== id.toString())
        this.$router.push({
          name: "AnalysisMain",
          params: { id: id.toString() },
        });
    },
    abortTask(id: number) {
      this.$store.dispatch("analyzes/ABORT_TASK", id);
    },
  },
  beforeDestroy() {
    this.storeSubscription();
  },
});
