import Vue from "vue";
import { IOverlap } from "@/store/analyzes/analyzes-types";
import { PERSON_CONTRAST_COLORS } from "@/utils/helper";
import TonalityMetrics from "./TonalityMetrics/index.vue";
import store from "@/store";

export default Vue.extend({
  name: "PersonCard",

  props: ["person", "personIndex", "coefficient", "tonalities"],

  components: { TonalityMetrics },

  data() {
    return {
      contrastColors: PERSON_CONTRAST_COLORS,
    };
  },

  computed: {
    interruptions(): IOverlap[] | null {
      return store.state.analyzes.currentAnalyze.overlap;
    },
    titles() {
      return {
        sentiment: this.$t("analyzes.sentiment"),
        style: this.$t("analyzes.style"),
      };
    },
  },
  methods: {
    getSpeakerName(speaker: number): string {
      return (
        this.$store.state.analyzes.currentAnalyze.speakers_names[speaker] ||
        this.$tc("analisis.person", speaker)
      );
    },
  },
});
