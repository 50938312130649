import Vue from "vue";
import { ErrorsMessages } from "@/utils/errors";
import { SuccessMessages } from "@/utils/success-messages";
import { ISubscribeInfo, IUser } from "@/store/profile/profile-types";
import ChangeEmailModal from "@/components/modals/ChangeEmailModal/index.vue";
import ChangePasswordModal from "@/components/modals/ChangePasswordModal/index.vue";
import DeleteAccountModal from "@/components/modals/DeleteAccountModal/index.vue";
import SubscriptionRequiredModal from "@/components/modals/SubscriptionRequiredModal/index.vue";
import AccountHeader from "../AccountHeader/index.vue";
import {
  dateFormat,
  storageColors,
  storageInfoInSettings,
  storageProgressStatus,
  storageVariants,
  TARIFFS,
  TariffName,
  getStoragePricing,
} from "@/utils/helper";
import { Tariff } from "@/types/shared-types";
import { openZombtyWindow } from "@/utils/oauth";
import { GET_ZOMBTY_AUTH_URL, SHOW_ZOMBTY_LOGIN } from "@/utils/consts";

export default Vue.extend({
  name: "AccountSettings",
  components: {
    ChangeEmailModal,
    ChangePasswordModal,
    DeleteAccountModal,
    AccountHeader,
    SubscriptionRequiredModal,
  },
  data() {
    return {
      zoomLink: process.env.VUE_APP_ZOOM_MARKETPLACE_LINK,
      errorsMessages: ErrorsMessages,
      successMessages: SuccessMessages,
      storageColors: storageColors,
      storageInfo: storageInfoInSettings,

      email: "",
      password: "",

      changeEmailForm: {
        email: "",
        password: "",
      },
      changePasswordForm: {
        password: "",
        new_password: "",
      },
      sendSuccess: false,

      replySignUpDisabled: false,
      replyChangeEmailDisabled: false,
      changeEmailDialog: false,
      changePasswordDialog: false,
      deleteAccountDialog: false,
      subRequiredDialog: false,
      unlinkZombtyDialog: false,
      tariffNames: TariffName,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      storeSubscription: () => {},

      zombtyUnsub: <null | (() => void)>null,
    };
  },
  methods: {
    closeChangeEmailDialog() {
      if (!this.sendSuccess) {
        this.changeEmailForm = { email: "", password: "" };
      }
      this.changeEmailDialog = false;
    },
    closeChangePasswordDialog() {
      this.changePasswordForm = { password: "", new_password: "" };
      this.changePasswordDialog = false;
    },
    handleAddSpace() {
      if (this.tariff === "freemium") {
        this.subRequiredDialog = true;
      } else {
        this.$router.push({
          name: "Tariffication",
          hash: "#storageScrollAnchor",
        });
      }
    },
    async replyChangeEmail() {
      if (this.replyChangeEmailDisabled) return;
      this.replyChangeEmailDisabled = true;
      const res = await this.$store.dispatch("profile/REPLY_CHANGE_EMAIL");
      if (res) {
        this.$store.dispatch("SET_SUCCESS_ALERT", 6);
        setTimeout(() => {
          this.replyChangeEmailDisabled = false;
        }, 120000);
      } else {
        this.replyChangeEmailDisabled = false;
      }
    },
    async replyConfirmSignUp() {
      if (this.APIError === 2829294403) return;

      const res = await this.$store.dispatch("profile/REPLY_CONFIRM_EMAIL");

      if (res) {
        this.$store.dispatch("SET_SUCCESS_ALERT", 4);
      } else {
        setTimeout(() => {
          this.$store.dispatch("CLEAR_API_ERROR_ACTION", null);
        }, 5000);
      }
    },
    cancelChangeEmail() {
      this.changeEmailForm = { email: "", password: "" };
      this.sendSuccess = false;
      this.$store.dispatch("profile/CANCEL_CHANGE_EMAIL");
    },
    infoSubscription({ quantity, type }: ISubscribeInfo) {
      const price = getStoragePricing(type, quantity);

      return `+ ${quantity} ${type.toUpperCase()} ${this.$t(
        "settings.for"
      )} $${price}`;
    },
    getNextPaymentDate() {
      const date = new Date(
        this.currentUser.tariff_period_end * 1000
      ).toString();
      const { month, day, year } = dateFormat(date);

      return `${this.$t(`settings.${month}`)} ${day}, ${year}`;
    },
    cancelSubscriptions() {
      this.$router.push({ name: "CancelingSubscriptions" });
    },
    linkZombtyAccount() {
      this.zombtyUnsub = openZombtyWindow({
        url: this.zombtyLoginUrl,
        onSuccess: (state) =>
          this.$store.dispatch("profile/LINK_ZOMBTY_ACCOUNT", state),
        onClosed: () => (this.zombtyUnsub = null),
        onOtherTabSuccess: () =>
          this.$store.dispatch("profile/GET_USER_SETTINGS"),
      });
    },
    unlinkZombtyAccount() {
      this.$store
        .dispatch("profile/UNLINK_ZOMBTY_ACCOUNT")
        .then(() => (this.unlinkZombtyDialog = false));
    },
  },
  computed: {
    currentUser(): IUser {
      return this.$store.state.profile.user;
    },
    infoStorageAvailable() {
      return this.$store.getters["profile/GET_INFO_STORAGE_AVAILABLE"];
    },
    APIError() {
      return this.$store.state.errorAPI;
    },
    successCode(): number | null {
      return this.$store.state.successCode;
    },
    tariff(): Tariff {
      const underscoreStart = this.currentUser.tariff.indexOf("_");

      return this.currentUser.tariff.slice(underscoreStart + 1) as Tariff;
    },
    price(): number {
      return this.$store.state.profile.prices.plans[this.tariff];
    },
    subscribeList(): ISubscribeInfo[] {
      return this.currentUser.subscribeList;
    },
    errorSubscribeList(): boolean {
      return this.$store.state.profile.errorSubscribeList;
    },
    lastChangePassword(): string {
      const date = new Date(this.currentUser.change_password_time);
      const today = new Date();
      if (this.currentUser.change_password_time) {
        switch (date.getDate()) {
          case today.getDate(): {
            return "Changed today";
          }
          case today.getDate() - 1: {
            return "Changed yesterday";
          }
          default: {
            return "Changed: " + date.toLocaleDateString();
          }
        }
      } else return "●●●●●●●●●";
    },
    storageVolumePercent(): number {
      return this.$store.getters["profile/GET_STORAGE_VOLUME_PERCENT"];
    },
    storageStatus(): storageVariants {
      return storageProgressStatus(this.storageVolumePercent);
    },
    showStorageInfo(): boolean {
      return [storageVariants.ALERT, storageVariants.FULL].includes(
        this.storageStatus
      );
    },
    isZombtyConnected(): boolean {
      return this.$store.state.profile.user.zombty_authorized;
    },
    isZombtyLinkingLoading(): boolean {
      return this.$store.state.profile.isZombtyLinkLoading;
    },
    zombtyLoginUrl(): string {
      return GET_ZOMBTY_AUTH_URL(this.$router);
    },
    showZombtyButton(): boolean {
      return SHOW_ZOMBTY_LOGIN;
    },
  },
  async created() {
    this.$store.dispatch("profile/GET_PRICES");

    this.storeSubscription = this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "analyzes/SET_TASK_ID":
          this.$router.push(`/analysis/${state.analyzes.currentAnalyze.id}`);
          break;
        default:
          break;
      }
    });
  },
  beforeDestroy() {
    if (this.APIError) this.$store.dispatch("CLEAR_API_ERROR_ACTION", null);
    this.storeSubscription();
  },
});
