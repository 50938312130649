




























import Vue from "vue";
export default Vue.extend({
  name: "LinkAccount",
  props: {
    loading: Boolean,
  },
});
