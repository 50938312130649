import Vue from "vue";
import { ANALYSIS_PROGRESS_STATUS } from "@/utils/helper";
import RenameModal from "@/components/modals/RenameModal/index.vue";
import Footer from "@/components/Footer/index.vue";
import Player from "@/components/Analysis/Player/index.vue";
import { Analysis } from "@/store/analyzes/analyzes-types";
import LinearProgress from "../shared/LinearProgress/index.vue";
import AnalysisHeader from "@/components/Analysis/AnalysisHeader/index.vue";

export default Vue.extend({
  name: "Analysis",
  components: {
    RenameModal,
    Footer,
    Player,
    LinearProgress,
    AnalysisHeader,
  },
  watch: {
    "$route.params": {
      handler(val, oldVal) {
        if (oldVal && oldVal.id !== val?.id) {
          this.globalLoading = true;
          this.clearWSError(null);
          this.clearAPIError(null);
          this.$store.dispatch("analyzes/CLEAR_CURRENT_TASK_ACTION");
          this.$store.dispatch("analyzes/GET_TASK_ANALYZE", {
            id: val.id,
          });
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      progressStatus: <{ text: string; value: number } | null>null,
      loadingTask: false,
      globalLoading: false,

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      storeSubscription: () => {},
    };
  },
  created() {
    this.globalLoading = true;
    this.$store.dispatch("analyzes/GET_TASK_ANALYZE", {
      id: this.$route.params.id,
    });
    this.storeSubscription = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "analyzes/SET_TASK_STATUS":
          if (
            mutation.payload.task_id.toString() ===
            this.$route.params.id.toString()
          ) {
            this.progressStatus =
              ANALYSIS_PROGRESS_STATUS[this.$storeAnalysis.status];
            if (this.$storeAnalysis.status === "completed") {
              this.getTaskWithReturn(mutation.payload.task_id);
            }
          }
          break;
        case "analyzes/SET_TASK_ANALYZE":
          this.globalLoading = false;
          if (this.$storeAnalysis.status === "completed") {
            this.progressStatus = null;
          } else {
            this.progressStatus =
              ANALYSIS_PROGRESS_STATUS[this.$storeAnalysis.status];
          }
          break;
        case "analyzes/DELETE_TASK":
          if (+this.$route.params.id === mutation.payload)
            this.$router.push({ name: "AnalyzesTable" });
          break;
        case "analyzes/BULK_TASK_DELETED":
          if (+this.$route.params.id === mutation.payload)
            this.$router.push({ name: "AnalyzesTable" });
          break;
        default:
          break;
      }
    });
  },
  computed: {
    $storeAnalysis(): Analysis {
      return this.$store.state.analyzes.currentAnalyze;
    },
    $APIError(): number | null {
      return this.$store.state.errorAPI;
    },
    $WSError(): number | null {
      return this.$store.state.errorWS;
    },
  },
  methods: {
    analyzeAgain() {
      this.clearWSError(null);
      this.clearAPIError(null);
      const form = {
        id: this.$storeAnalysis.id,
        title: this.$storeAnalysis.title,
        language: this.$storeAnalysis.language,
        speakers: this.$storeAnalysis.speakers_expected,
        tonality: this.$storeAnalysis.tonality_base_expected,
        tonality_spec: this.$storeAnalysis.tonality_spec_expected,
        keywords: this.$storeAnalysis.keywords_expected,
      };
      this.$store.dispatch("analyzes/ANALYZE_AGAIN_START", form);
    },
    getTaskWithReturn(task_id: number) {
      this.loadingTask = true;
      this.$store
        .dispatch("analyzes/GET_TASK_ANALYZE", {
          id: task_id,
          withReturn: true,
        })
        .then((res: Analysis) => {
          // таймаут для отображения статуса 'completed' - 2sec
          setTimeout(() => {
            this.loadingTask = false;
            this.$store.commit("analyzes/SET_TASK_ANALYZE", res);
            this.$store.commit("analyzes/CALCULATE_STATISTICS");
          }, 2000);
        })
        .catch((err) => {
          this.loadingTask = false;
          console.log(err);
        });
    },
    clearWSError(code: number | number[] | null) {
      if (this.$WSError)
        this.$store.dispatch("CLEAR_WS_ERROR_ACTION", code);
    },
    clearAPIError(code: number | number[] | null) {
      if (this.$APIError)
        this.$store.dispatch("CLEAR_API_ERROR_ACTION", code);
    },
  },
  beforeDestroy() {
    this.clearWSError(null);
    this.clearAPIError(null);
    this.$store.dispatch("analyzes/CLEAR_CURRENT_TASK_ACTION");
    this.storeSubscription();
  },
});
