import Vue from "vue";
import { TariffName, TARIFFS } from "@/utils/helper";
import { Tariff } from "@/types/shared-types";

export default Vue.extend({
  name: "CancelingSubscriptions",
  data() {
    return {
      tariffs: TARIFFS,
      tariffNames: TariffName,
    };
  },
  computed: {
    tariff(): Tariff {
      // TODO: Убрать костыль, когда решим с беком вопрос о единном формате подписок (сейчас где-то подписок есть префикс, а где-то нет)
      const tariff = this.$store.state.profile.user.tariff;
      return tariff.replace(/subscription_/, "");
    },
  },
  methods: {
    cancelSubscription() {
      this.$store.dispatch("profile/UNSUBSCRIBE_TARIFF");
    },
    goBack() {
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push("/tariffication");
      }
    },
  },
});
