import {
  Analysis,
  AnalysisResultData,
  AnalysisResultWords,
  ITimeLineDataSpeakers,
  IVisibleWords,
} from "@/store/analyzes/analyzes-types";

export function resultCombiner(
  data: AnalysisResultData[],
  speakers: number,
  keywordsString: string,
  usedPhrases: Analysis["used_phrases"]
): ITimeLineDataSpeakers[] {
  const keywordsArray: string[] = (keywordsString || "")
    .split(",")
    .map((w) => w.trim());
  return new Array(speakers).fill("foo").map((foo, idx) => {
    const speech = data.filter((speech) => +speech.speaker - 1 === idx);
    const tonality_base: { [key: string]: number } = {
      negative: 0,
      neutral: 0,
      positive: 0,
    };
    const tonality_spec: { [key: string]: number } = {
      aggressive: 0,
      business: 0,
      intimate: 0,
    };
    let allKeywords: AnalysisResultWords[] = [];

    speech.forEach((s) => {
      Object.keys(tonality_base).forEach(
        (key) =>
          (tonality_base[key] +=
            (<{ [key: string]: number }>(<unknown>s.tonality_base))[
              key
            ] /
            (speech.length + 1))
      );
      Object.keys(tonality_spec).forEach(
        (key) =>
          (tonality_spec[key] +=
            (<{ [key: string]: number }>(<unknown>s.tonality_spec))[
              key
            ] /
            (speech.length + 1))
      );

      allKeywords = [
        ...allKeywords,
        ...s.words.filter(
          (w) =>
            keywordsArray.includes(w.keyword as string) ||
            !!w.keyphrase?.find((e) => keywordsArray.includes(e))
        ),
      ];
    });

    const concatTonal = { ...tonality_base, ...tonality_spec };
    const sorted_tonality = Object.keys(concatTonal)
      .map((key) => [key, concatTonal[key]])
      .sort((a, b) => <number>b[1] - <number>a[1]);

    const sorted_tonality_base = Object.keys(tonality_base)
      .map((key) => [key, tonality_base[key]])
      .sort((a, b) => <number>b[1] - <number>a[1]);

    const sorted_tonality_spec = Object.keys(tonality_spec)
      .map((key) => [key, tonality_spec[key]])
      .sort((a, b) => <number>b[1] - <number>a[1]);

    const keywords: AnalysisResultWords[] = allKeywords.filter(
      (word, index, self) =>
        index === self.findIndex((w) => w.keyword === word.keyword)
    );
    const keyphrases: string[] = keywords
      .map((k) =>
        k.keyword ? [...k.keyphrase, k.keyword] : k.keyphrase
      )
      .flat()
      .filter(
        (word, index, self) =>
          index === self.findIndex((w) => w === word)
      );
    let used_phrases: [string[], number][] = [];
    if (usedPhrases) {
      used_phrases = usedPhrases[idx + 1];
    }
    //sense
    const sense: [string, number][] = [];
    let allSense: [string, number][] = speech
      .map((s) => s.sense)
      .filter(Boolean);

    if (allSense.length) {
      allSense = allSense.filter((s) => s[1] > 0);
      allSense.forEach((sen) => {
        const idx = sense.findIndex((s) => s[0] === sen[0]);
        if (idx !== -1) sense[idx][1] = sense[idx][1] + sen[1];
        else sense.push(sen);
      });
      sense.sort((a, b) => b[1] - a[1]);
    }

    return {
      speaker:
        data.find((speech) => +speech.speaker - 1 === idx)?.speaker ||
        `${idx + 1}`,
      speech,
      activity: speech.reduce(
        (prev, current) =>
          prev +
          current.words.reduce(
            (pword, cword) => pword + (cword.end - cword.begin),
            0
          ),
        0
      ),
      tonality_base,
      tonality_spec,
      sorted_tonality,
      sorted_tonality_base,
      sorted_tonality_spec,
      keywords,
      keyphrases,
      used_phrases,
      allKeywords,
      sense,
      visible: true,
    };
  });
}

export function combineKeywords(
  keywords: string,
  timelineData: ITimeLineDataSpeakers[]
): Record<string, AnalysisResultWords[]> {
  const res: Record<string, AnalysisResultWords[]> = keywords
    .split(",")
    .reduce((acc, keyword) => {
      const trimmedKeyword = keyword.trim();
      return { ...acc, [trimmedKeyword]: [] };
    }, {});

  const keys = timelineData.reduce<AnalysisResultWords[]>(
    (acc, speaker) => [...acc, ...speaker.allKeywords],
    []
  );

  keys.sort((a, b) => a.begin - b.begin);

  for (const w of keys) {
    if (w.keyword) {
      res[w.keyword].push(w);
    }

    if (w.keyphrase.length) {
      for (const phrase of w.keyphrase) {
        if (phrase.includes(w.word)) {
          res[phrase].push({ ...w, keyphrase: [...w.keyphrase, phrase] });
        }
      }
    }
  }
  return res;
}

export function addVisibleWords(
  result: AnalysisResultData[]
): AnalysisResultData[] {
  return result.map((item) => {
    const visibleWords: IVisibleWords[] = [];
    let hyphenCount = 1;

    item.text.split(" ").forEach((word, i) => {
      if (/—/.test(word)) {
        visibleWords[i - hyphenCount] = {
          ...visibleWords[i - hyphenCount],
          hyphen: word,
        };

        hyphenCount++;
      } else {
        visibleWords.push({ visibleWordItem: word });
      }
    });

    const words = item.words.map((word, index) => ({
      ...word,
      visibleWord: visibleWords[index] || word.visibleWord,
    }));

    return { ...item, words };
  });
}
