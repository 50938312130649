export const analyzes = {
  title: "Анализы",
  search: "Поиск",
  fields: "Все поля",
  filters: "Фильтры",
  cols: "Колонки",
  apply: "Применить",
  addFilter: "Добавить фильтр",
  noFilters: "Фильтры не добавлены",
  clearFilters: "Очистить фильтры",
  filtersApplied:
    "{n} Фильтров применено | {n} Фильтр применен | {n} Фильтра применено | {n} Фильтров применено |",
  bAction: "Действия",
  titleField: "Название",
  speakers: "Спикеры",
  topic: "Тема",
  sense: "Смысл",
  sentiment: "Чувство разговора",
  speech: "Стиль речи",
  phrases: "Ключевые фразы",
  dur: "Длительность",
  size: "Размер",
  mod: "Изменения",
  lng: "Язык",
  action: "Действие",
  created: "Создано",
  tonality: "Тональность",
  kwSpotted: "Ключевые слова",
  val: "Значение",
  contain: "Содержит",
  notContain: "Не содержит",
  emptyTable: "Данные отсутствуют",
  guestLink: "Зарегистрируйтесь, чтобы получить 10 запросов бесплатно",
  guestLink1: "Зарегистрируйтесь",
  guestLink2: ", чтобы получить 10 запросов бесплатно",
  storage: "Хранилище",
  manage: "Управлять хранилищем",
  toTariff: "Управлять планом",
  delete: "Удалить",
  duplicate: "Дублировать",
  reanalyze: "Повторить",
  rename: "Переименовать",
  download: "Загрузить",
  aborted: "Прервано",
  progress: "В процессе",
  error: "Ошибка",
  noAvailable: "Недоступно для текущего плана",
  warn: "{count} выбранных файлов не будут удалены. Вы не можете удалить файлы, которые находятся в процессе анализа.",
  warn2:
    "{count} выбранных файлов не будут проанализированы повторно. Вы не можете повторно проанализировать файлы, которые находятся в процессе анализа.",
  warn3:
    "{count}  выбранных файлов не будут загружены. Вы не можете загрузить, которые находятся в процессе анализа или файлы с ошибкой.",
  business: "Деловой",
  negative: "Негативный",
  intimate: "Интимный",
  neutral: "Нейтральный",
  aggressive: "Агрессивный",
  positive: "Позитивный",
  selected:
    "{n} анализов выбрано | {n} анализ выбран | {n} анализа выбрано | {n} анализов выбрано",
  exp: "{n} ожид.",
  style: "Стиль речи",
  other: "Другой",
  active: "активности",
  downloadAudio: "Скачать Аудио",
  downloadPdf: "Скачать PDF",
  en: "Английский (US)",
  ru: "Русский (RU)",
  de: "Немецкий (DE)",
  it: "Итальянский (IT)",
};
