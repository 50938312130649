import i18n from "@/plugins/i18n";
import VueI18n from "vue-i18n";

export interface IAnalysisProgressStatus {
  [key: string]: { text: string; value: number };
}

export interface ITableHeaders {
  visible: boolean;
  sortable: boolean;
  value: string;
  text?: string | VueI18n.TranslateResult;
  class?: string;
  width?: string;
  align?: string;
}

export type FilterType =
  | "title"
  | "created"
  | "language"
  | "tonality_spec_detected"
  | "tonality_base_detected"
  | "keywords_spotted";

export interface IFilterItem {
  value: FilterType;
  text: string | VueI18n.TranslateResult;
  disabled?: boolean;
}
export interface IFilterCondition {
  value: boolean;
  text: string | VueI18n.TranslateResult;
}
export interface IFilter {
  subject: FilterType;
  condition: boolean;
  value: string;
}

export interface ITimeString {
  hours: string;
  minutes: string;
  seconds: string;
}

export type CollectionNameType =
  | "TONALITY_COLLECTION"
  | "TONALITY_SPEC_COLLECTION"
  | "LANGUAGE_COLLECTION";

export interface ICollection {
  title: string;
  value: string;
  disabled?: boolean;
}

export const ANALYSIS_PROGRESS_STATUS: IAnalysisProgressStatus = {
  wait_analyze: {
    text: "newAnalysis.step0",
    value: 0,
  },
  cropping_file: {
    text: "newAnalysis.step1",
    value: 11,
  },
  uploading_file: {
    text: "newAnalysis.step2",
    value: 22,
  },
  feature_extraction: {
    text: "newAnalysis.step3",
    value: 33,
  },
  segmentation: {
    text: "newAnalysis.step4",
    value: 44,
  },
  embedding_evaluation: {
    text: "newAnalysis.step5",
    value: 55,
  },
  clustering: {
    text: "newAnalysis.step6",
    value: 66,
  },
  transcription: {
    text: "newAnalysis.step7",
    value: 77,
  },
  tonality: {
    text: "newAnalysis.step8",
    value: 88,
  },
  completed: {
    text: "newAnalysis.step9",
    value: 100,
  },
  failed: {
    text: "analyzes.error",
    value: 100,
  },
  aborted: {
    text: "analisis.abortedStep",
    value: 100,
  },
};

export const TABLE_COLUMNS: ITableHeaders[] = [
  {
    text: "titleField",
    sortable: false,
    value: "title",
    visible: true,
    width: "226px",
  },
  {
    text: "speakers",
    sortable: false,
    value: "speakers_result",
    visible: true,
    width: "78px",
  },
  {
    text: "topic",
    sortable: false,
    value: "topic",
    visible: true,
    width: "100px",
  },
  {
    text: "sense",
    sortable: false,
    value: "sense",
    visible: true,
    width: "180px",
  },
  {
    text: "sentiment",
    sortable: false,
    value: "tonality_base",
    visible: true,
    width: "110px",
  },
  {
    text: "speech",
    sortable: false,
    value: "tonality_spec",
    visible: true,
    width: "110px",
  },
  {
    text: "phrases",
    sortable: false,
    value: "keywords",
    visible: true,
    width: "94px",
  },
  {
    text: "dur",
    sortable: false,
    value: "duration",
    visible: true,
    width: "80px",
  },
  {
    text: "size",
    sortable: false,
    value: "size",
    visible: true,
    width: "100px",
  },
  {
    text: "mod",
    sortable: false,
    value: "update_time",
    visible: true,
    width: "164px",
  },
  {
    text: "created",
    sortable: false,
    value: "create_time",
    visible: false,
    width: "164px",
  },
  {
    text: "lng",
    sortable: false,
    value: "language",
    visible: false,
    width: "120px",
  },
  {
    text: "action",
    value: "actions",
    sortable: false,
    align: "center",
    visible: true,
    width: "70px",
  },
];

export const FILTER_SUBJECTS = [
  { value: "title", text: "titleField" },
  // { value: "created", text: "Created" },
  { value: "language", text: "lng" },
  { value: "tonality_spec_detected", text: "speech" },
  { value: "tonality_base_detected", text: "sentiment" },
  { value: "keywords_spotted", text: "kwSpotted" },
];

export const FILTER_CONDITIONS: IFilterCondition[] = [
  { value: true, text: "contain" },
  { value: false, text: "notContain" },
];

export const LANGUAGE_COLLECTION = [
  { title: "Английский (US)", value: "en" },
  { title: "Русский (RU)", value: "ru" },
  { title: "Немецкий (DE)", value: "de", disabled: true },
  { title: "Итальянский (IT)", value: "it", disabled: true },
];

export const TONALITY_COLLECTION = [
  { title: "positive", value: "positive" },
  { title: "negative", value: "negative" },
  { title: "neutral", value: "neutral" },
];

export const TONALITY_SPEC_COLLECTION = [
  { title: "business", value: "business" },
  { title: "intimate", value: "intimate" },
  { title: "aggressive", value: "aggressive" },
];

export const tonalityTypes: { [key: string]: string } = {
  positive: "Positive",
  negative: "Negative",
  neutral: "Neutral",
  business: "Business",
  intimate: "Intimate",
  aggressive: "Aggressive",
};

export const PERSON_COLORS = [
  "var(--person-1)",
  "var(--person-2)",
  "var(--person-3)",
  "var(--person-4)",
  "var(--person-5)",
  "var(--person-6)",
  "var(--person-7)",
  "var(--person-8)",
  "var(--person-9)",
  "var(--person-10)",
  "var(--person-11)",
  "var(--person-12)",
];
export const PERSON_CONTRAST_COLORS = [
  "var(--person-1-contrast)",
  "var(--person-2-contrast)",
  "var(--person-3-contrast)",
  "var(--person-4-contrast)",
  "var(--person-5-contrast)",
  "var(--person-6-contrast)",
  "var(--person-7-contrast)",
  "var(--person-8-contrast)",
  "var(--person-9-contrast)",
  "var(--person-10-contrast)",
  "var(--person-11-contrast)",
  "var(--person-12-contrast)",
];

export function storageProgressStatus(val: number) {
  if (val <= 70) return storageVariants.SUCCESS;
  if (val > 70 && val <= 95) return storageVariants.WARNING;
  if (val > 96 && val < 100) return storageVariants.ALERT;
  else return storageVariants.FULL;
}

export enum storageVariants {
  SUCCESS = "success",
  WARNING = "warning",
  ALERT = "alert",
  FULL = "full",
}

export const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const storageColors = {
  [storageVariants.SUCCESS]: "#FD3939",
  [storageVariants.WARNING]: "#FD3939",
  [storageVariants.ALERT]: "#FF5252",
  [storageVariants.FULL]: "#FF5252",
};

export const storageInfo = {
  [storageVariants.WARNING]: "errors.storageWarning",
  [storageVariants.ALERT]: "errors.storageAlert",
  [storageVariants.FULL]: "errors.storageFull",
};

export const storageInfoInSettings = {
  [storageVariants.ALERT]: {
    title: "errors.storageInfoAlert1",
    description: "errors.storageInfoAlert2",
  },
  [storageVariants.FULL]: {
    title: "errors.storageInfoFull1",
    description: "errors.storageInfoFull2",
  },
};

export enum audioErrorTypes {
  STORAGE_CONTINUE = "upgrade_storage_continue",
  TARIFF_CONTINUE = "upgrade_tariff_continue",
  STORAGE = "upgrade_storage",
  TARIFF = "upgrade_tariff",
}

export const audioErrors = {
  [audioErrorTypes.STORAGE_CONTINUE]: {
    title: "errors.someCannotUpload",
    description: "errors.audioStorageDesc",
  },
  [audioErrorTypes.TARIFF_CONTINUE]: {
    title: "errors.someCannotUpload",
    description: "errors.audioTariffDesc",
  },
  [audioErrorTypes.STORAGE]: {
    title: "errors.cannotUpload",
    description: "errors.audioStorageDescSecond",
  },
  [audioErrorTypes.TARIFF]: {
    title: "errors.cannotUpload",
    description: "errors.audioTariffDescSecond",
  },
};

export enum functionTypes {
  MORE_ANALYSIS = "More analysis each month",
  LONG_AUDIO = "Analysis of long audio recordings",
  EXPENDED_STORAGE = "Expanded storage",
  TONALITY_SENTIMENT = "Tonality sentiment detection",
  TONALITY_SPEECH = "Tonality speech detection",
  TOPIC = "Topic detection",
  SUBJECT = "Sense detection",
  INTERRUPTIONS = "Interruption analysis",
  KEYWORDS = "Keywords and phrases searches",
}

export const TARIFFS = {
  freemium: {
    price: 0,
    req_per_month: 10,
    duration_record: 120,
    storage: 52428800,
    display_storage: "50 MB",
    sense: false,
    topic: false,
    overlap: false,
  },
  standart: {
    price: 10,
    req_per_month: 100,
    duration_record: 300,
    storage: 1073741824,
    display_storage: "1 GB",
    topic: true,
    sense: false,
    overlap: false,
  },
  standart_plus: {
    price: 19,
    req_per_month: 200,
    duration_record: 600,
    storage: 10737418240,
    display_storage: "10 GB",
    topic: true,
    sense: true,
    overlap: false,
  },
  premium: {
    price: 99,
    req_per_month: 500,
    duration_record: 3600,
    storage: 107374182400,
    display_storage: "100 GB",
    topic: true,
    sense: true,
    overlap: true,
  },
};

export const tariffUpgradeInfo = {
  [functionTypes.TONALITY_SENTIMENT]: {
    title: "pricing.tarifUpgrage11",
    description: "pricing.tarifUpgrage41",
  },
  [functionTypes.TONALITY_SPEECH]: {
    title: "pricing.tarifUpgrage11",
    description: "",
  },
  [functionTypes.TOPIC]: {
    title: "pricing.tarifUpgrage21",
    description: "",
  },
  [functionTypes.SUBJECT]: {
    title: "pricing.tarifUpgrage31",
    description: "",
  },
  [functionTypes.INTERRUPTIONS]: {
    title: "pricing.tarifUpgrage41",
    description: "",
  },
};

export const TariffName = {
  freemium: "Freemium",
  standart: "Standart",
  standart_plus: "Standart Plus",
  premium: "Premium",
};

export enum SizeSign {
  MB = "mb",
  GB = "gb",
  TB = "tb",
}

export const STORAGE_PRICING = {
  [SizeSign.MB]: 0.01,
  [SizeSign.GB]: 1.5,
  [SizeSign.TB]: 100,
};

export const NEW_STORAGE_PRICING = {
  [SizeSign.MB]: {
    100: 10,
  },
  [SizeSign.GB]: {
    1: 50,
    10: 300,
    100: 1000,
  },
  [SizeSign.TB]: {
    1: 2500,
  },
};

export const getStoragePricing = (type: SizeSign, quantity: number): number => {
  const storagePricingMap = NEW_STORAGE_PRICING[type];

  if (storagePricingMap[quantity as keyof typeof storagePricingMap])
    return storagePricingMap[quantity as keyof typeof storagePricingMap];

  const keys = Object.keys(storagePricingMap);

  const minKeys = keys.filter((item: string) => +item <= quantity);
  const key = +minKeys[minKeys.length - 1] || +keys[0];
  const diff = quantity / key;

  return (storagePricingMap[key as keyof typeof storagePricingMap] || 1) * diff;
};

export function calculateTime(
  time: number,
  coef: number
): { min: number; sec: number } {
  const min = Math.floor((+time * coef) / 60);
  const sec = Math.floor(+time * coef - min * 60);
  return { min, sec };
}

export function calculateScale(audioLength: number): number {
  const baseAudioLenght = 900;
  const coefficient = 9;
  return audioLength < baseAudioLenght ? 100 : audioLength / coefficient;
}

export function formatAMPM(date: string) {
  const d = new Date(date);
  let h = d.getHours();
  const m = d.getMinutes();
  const ampm = h >= 12 ? "PM" : "AM";
  h = h % 12;
  h = h ? h : 12;
  const minutes = m < 10 ? `0${m}` : m;
  const hours = h < 10 ? `0${h}` : h;
  return { hours, minutes, ampm };
}

export function formatEU(date: string) {
  const d = new Date(date);
  const hours = d.getHours();
  const m = d.getMinutes();
  const minutes = m < 10 ? `0${m}` : m;
  return { hours, minutes };
}

export function dateFormat(date: string): {
  month: string;
  day: string | number;
  year: number;
} {
  const d = new Date(date);
  const month = Months[d.getMonth()];
  const dayNonFormat = d.getDate();
  const day = dayNonFormat < 10 ? `0${dayNonFormat}` : dayNonFormat;
  const year = d.getFullYear();
  return { month, day, year };
}

export function formatDateByTimezone(date: string): string {
  const { month, day, year } = dateFormat(date);

  const { hours, minutes, ampm } = formatAMPM(date);

  return `${month} ${day} ${year} ${
    ampm === "AM" ? hours : Number(hours) + 12
  }:${minutes}`;
}

export function timeFormat(time: number, coef: number): string {
  const { min, sec } = calculateTime(time, coef);
  return `${min}:${sec < 10 ? `0${sec}` : sec}`;
}

/**
 *
 * @param length length time in ms
 * @returns formatted string with omited unneeded registers, like `1d 7h 5m 24s` or `34m 3s`
 */
export function formatLength(length: number): string {
  const d = Math.floor(length / 86400000);
  const h = Math.floor((length - d * 86400000) / 3600000);
  const m = Math.floor((length - d * 86400000 - h * 3600000) / 60000);
  const s = Math.ceil((length - d * 86400000 - h * 3600000 - m * 60000) / 1000);
  return Object.entries({ d, h, m, s })
    .filter(
      ([, value], idx, arr) =>
        arr.filter((_, i) => i < idx).some(([, v]) => v > 0) || value > 0
    )
    .map(([key, value]) => `${value}${key}`)
    .join(" ");
}

export function dateDiffMs(start: string, end: string): number {
  const [startTimestamp, endTimestamp] = [start, end].map((v) =>
    new Date(v).getTime()
  );
  return [startTimestamp, endTimestamp].some(isNaN)
    ? 0
    : endTimestamp - startTimestamp;
}

export function sizeFormat(size: number): string {
  if (size < 0) return `${"00.00 MB"}`;

  const type = ["KB", "MB", "GB", "TB"];
  let i = 0;

  while (Math.floor(size / 1024) && i < type.length - 1) {
    size /= 1024;
    i++;
  }

  const dType = type[i];
  const dSize = size.toFixed(dType === type[0] ? 0 : 2);

  return `${dSize} ${dType}`;
}

export function msOrSecFormat(length: number): string {
  if (length < 1) return `${(length * 1000).toFixed()}${i18n.t("analisis.ms")}`;
  return `${timeFormat(length, 1)}${i18n.t("analisis.m")}`;
}

export function getZoomCode(): string | null {
  return localStorage.getItem("code");
}

export function removeZoomCode(): void {
  localStorage.removeItem("code");
}

export function getGlobalTheme(): string {
  return localStorage.getItem("theme") || "light-theme";
}
export function setGlobalTheme(theme: string): void {
  localStorage.setItem("theme", theme);
}

export function getCookie(name: string): string | undefined {
  const matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1") + "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function deleteCookie(name: string): void {
  setCookie(name, "", {
    "max-age": -1,
  });
}

export function keywordsToArray(keywords: string): string[] {
  return keywords
    .replace(/\s+/g, " ")
    .split(",")
    .map((key) => key.trim())
    .filter(Boolean);
}

export function checkPunctuation(word: string): [string, string] {
  const hasPunctuation = /[\\wа-яА-Яa-zA-Z]+[.,!?;:]$/i;
  if (hasPunctuation.test(word)) {
    const punctuationMark = word.split("").pop();
    const newWord = word.slice(0, -1);
    return [newWord, punctuationMark + " "];
  } else {
    return [word, " "];
  }
}

export function setCookie(
  name: string,
  value: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any = {}
): void {
  options = {
    path: "/",
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (const optionKey in options) {
    updatedCookie += "; " + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function removePunctuationMark(word: string): string {
  return word.replace(/[.,!?;:]$/i, "");
}

export function convertSeconds(seconds: number): number {
  return Math.floor(seconds / 60);
}

export function timeStringToSeconds(time: ITimeString): number {
  const hoursInSeconds = Number(time.hours) * 3600;
  const minutesInSeconds = Number(time.minutes) * 60;
  const seconds = Number(time.seconds);

  return hoursInSeconds + minutesInSeconds + seconds;
}

export const formatNumber = (num: number): string => `${num}`.padStart(2, "0");

export function secondsToTimeString(totalSeconds: number): ITimeString {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return {
    hours: formatNumber(hours),
    minutes: formatNumber(minutes),
    seconds: formatNumber(seconds),
  };
}

export function getTimeDifference(startTime: number, endTime: number): number {
  return Math.abs(endTime - startTime);
}
