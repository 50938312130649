import Vue from "vue";
import { TARIFFS } from "@/utils/helper";
import { Tariff } from "@/types/shared-types";

export default Vue.extend({
  name: "TariffChangeModal",
  props: {
    dialog: {
      type: Boolean,
    },
    newTariff: {
      type: String as () => Tariff,
    },
  },
  data() {
    return {
      tariffs: TARIFFS,
    };
  },
  computed: {
    price(): number | "" {
      return this.newTariff
        ? this.$store.state.profile.prices.plans[this.newTariff as Tariff]
        : "";
    },
    currentTariff(): Tariff {
      return this.$store.state.profile.user.tariff;
    },
    priceDifference(): number {
      const { plans } = this.$store.state.profile.prices;
      const newPrice = this.price;
      const oldPrice = Number(plans[this.currentTariff]) || 0;

      if (typeof newPrice === "number") {
        return newPrice - oldPrice;
      }
      return 0;
    },
    modalText(): string {
      const textIndex = this.priceDifference > 0 ? "" : 2;

      return this.$t(`pricing.changeTariffModalText${textIndex}`, {
        n: this.price,
      }) as string;
    },
  },
  methods: {
    changeTariff() {
      this.$store.dispatch("profile/SET_TARIFF_LOADING_ACTION", true);

      const actionName =
        this.currentTariff === "freemium"
          ? "SUBSCRIBE_TARIFF"
          : "CHANGE_TARIFF";

      this.$store.dispatch(
        `profile/${actionName}`,
        `subscription_${this.newTariff}`
      );

      this.$emit("close");
    },
  },
});
