import { UploadedFile } from "@/store/analyzes/analyzes-types";
import {
  ITimeString,
  formatNumber,
  getTimeDifference,
  secondsToTimeString,
  timeFormat,
  timeStringToSeconds,
} from "@/utils/helper";
import TimeInput from "./TimeInput/index.vue";
import Vue from "vue";

type TimeTypes = "hours" | "minutes" | "seconds";
type InputTypes = "from" | "to" | "duration";

export default Vue.extend({
  name: "CutAudioModal",
  components: {
    TimeInput,
  },
  props: [
    "dialog",
    "file",
    "durationRecord",
    "tariffName",
    "analyzesIDs",
    "setAnalyzesIDs",
  ],
  data() {
    return {
      formValues: {
        from: { hours: "00", minutes: "00", seconds: "00" },
        to: { hours: "00", minutes: "00", seconds: "00" },
        duration: { hours: "00", minutes: "00", seconds: "00" },
      },
      formErrors: { from: "", to: "", duration: "" },
      mousedown: false,
      currentTime: 0,
      isPlaying: false,

      ticks: [] as number[],
    };
  },

  computed: {
    rangeSliderValues(): number[] {
      const convertedFrom = timeStringToSeconds(this.formValues.from);
      const convertedTo = timeStringToSeconds(this.formValues.to);
      const from = Math.min(convertedFrom, this.file.duration);
      const to = Math.min(convertedTo, this.file.duration);
      return [from, to];
    },
    areInputsValid(): boolean {
      return !Object.values(this.formErrors).some((error) => !!error);
    },
  },

  methods: {
    maxAudioLength(value: ITimeString) {
      const seconds = timeStringToSeconds(value);
      const formattedDuration = timeFormat(this.durationRecord, 1);
      const tariffName = this.tariffName.split("_").join(" ");
      const errorMessage = this.$t("errors.maxAudioLength", {
        n: formattedDuration,
        n2: tariffName,
      }) as string;
      this.formErrors.duration =
        seconds > this.durationRecord ? errorMessage : "";

      return seconds <= this.durationRecord;
    },
    notZero(value: ITimeString, type: InputTypes) {
      const seconds = timeStringToSeconds(value);
      const errorMessage = this.$t("errors.notZero") as string;
      this.formErrors[type] = seconds === 0 ? errorMessage : "";

      return seconds !== 0;
    },
    maxValue(value: ITimeString, type: InputTypes) {
      const seconds = timeStringToSeconds(value);
      const maxDuration = this.file.duration;
      const errorMessage = this.$t("errors.maxValue") as string;
      this.formErrors[type] = seconds > maxDuration ? errorMessage : "";

      return seconds <= maxDuration;
    },
    checkValueDuration(value: string) {
      let number = parseInt(value, 10);
      if (number > 59) {
        number = 59;
      } else if (number < 0 || isNaN(number)) {
        number = 0;
      }
      return number;
    },
    setFrom(e: InputEvent, type: TimeTypes) {
      const target = e.target as HTMLInputElement;
      const value = this.checkValueDuration(target.value);

      const formattedValue = timeStringToSeconds(this.formValues.from);

      this.changeAudioDuration(formattedValue);

      this.formValues.from[type] = formatNumber(value);
    },
    setTo(e: InputEvent, type: TimeTypes) {
      const target = e.target as HTMLInputElement;
      const value = this.checkValueDuration(target.value);

      this.formValues.to[type] = formatNumber(value);
    },
    setDuration(e: InputEvent, type: TimeTypes) {
      const target = e.target as HTMLInputElement;
      const value = this.checkValueDuration(target.value);

      const formattedFrom = timeStringToSeconds(this.formValues.from);

      this.formValues.duration[type] = formatNumber(value);

      const duration = timeStringToSeconds(this.formValues.duration);

      this.formValues.to = secondsToTimeString(formattedFrom + duration);
    },
    onInputsBlur() {
      const from = this.formValues.from;
      const to = this.formValues.to;
      const formattedFrom = timeStringToSeconds(this.formValues.from);
      const formattedTo = timeStringToSeconds(this.formValues.to);

      if (formattedTo < formattedFrom) {
        this.formValues.to = from;
        this.formValues.from = to;
      }
    },
    generateTicks() {
      const audioDuration = this.file.duration;
      const numberOfTicks = Math.floor(audioDuration / 10);

      this.ticks = Array.from({ length: numberOfTicks + 1 }, (_, i) => i * 10);
    },
    onRangeChange([from, to]: number[]) {
      const formValueFrom = timeStringToSeconds(this.formValues.from);

      if (from !== formValueFrom) {
        this.changeAudioDuration(from);
      }

      if (to < this.currentTime) {
        this.changeAudioDuration(to);
      }

      this.formValues.from = secondsToTimeString(from);
      this.formValues.to = secondsToTimeString(to);
    },
    onDurationChange() {
      const formattedFrom = timeStringToSeconds(this.formValues.from);
      const formattedTo = timeStringToSeconds(this.formValues.to);
      const difference = getTimeDifference(formattedFrom, formattedTo);

      this.formValues.duration = secondsToTimeString(difference);
    },
    changeProgress([from, to]: number[]) {
      const roundedFrom = Math.round(from);
      const roundedTo = Math.round(to);
      const formattedFrom = timeStringToSeconds(this.formValues.from);
      const formattedTo = timeStringToSeconds(this.formValues.to);

      if (roundedTo > formattedTo) {
        const audio = this.$refs.audio as HTMLAudioElement;
        this.currentTime = audio.currentTime;
        this.formValues.to = secondsToTimeString(roundedTo);
        return;
      }

      const isFromChange = formattedFrom !== roundedFrom;
      const audioTime = isFromChange ? roundedFrom : roundedTo;
      this.changeAudioDuration(audioTime);

      if (audioTime < formattedTo) {
        this.formValues.from = secondsToTimeString(roundedFrom);
      }
    },
    togglePlay() {
      const audio = <HTMLAudioElement>this.$refs.audio;

      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
      }

      this.isPlaying = !this.isPlaying;
    },
    onTimeUpdate() {
      if (!this.mousedown) {
        const audio = <HTMLAudioElement>this.$refs.audio;
        this.currentTime = audio.currentTime;

        const formattedTo = timeStringToSeconds(this.formValues.to);
        const formattedFrom = timeStringToSeconds(this.formValues.from);

        if (Math.round(audio.currentTime) >= formattedTo) {
          this.togglePlay();
          this.changeAudioDuration(formattedFrom);
        }
      }
    },
    formatTime(time: number): string {
      return timeFormat(time, 1);
    },
    timeToSeconds(time: ITimeString): number {
      return timeStringToSeconds(time);
    },
    changeAudioDuration(value: number) {
      const audio = <HTMLAudioElement>this.$refs.audio;

      if (audio) {
        audio.currentTime = value;
        this.currentTime = value;
      }
    },
    cutAudio() {
      const isValid = this.areInputsValid;

      if (!isValid) return;

      const formattedFrom = timeStringToSeconds(this.formValues.from);
      const formattedTo = timeStringToSeconds(this.formValues.to);
      const formattedDuration = timeStringToSeconds(this.formValues.duration);

      this.file.crop_from = formattedFrom;
      this.file.crop_to = formattedTo;
      this.file.croppedDuration = formattedDuration;
      this.file.warning = "";

      const newAnalyzesIDs = this.analyzesIDs.map((item: UploadedFile) => {
        if (item.id === this.file.id) {
          this.$store.commit("analyzes/SET_CROPPED_DURATION", {
            id: this.file.id,
            croppedDuration: formattedDuration,
          });
          return {
            ...item,
            crop_from: formattedFrom,
            crop_to: formattedTo,
          };
        }
        return item;
      });

      this.setAnalyzesIDs(newAnalyzesIDs);
      this.$emit("close");
    },
  },

  watch: {
    "formValues.from": {
      handler() {
        this.onDurationChange();
      },
      deep: true,
    },
    "formValues.to": {
      handler() {
        this.onDurationChange();
        if (this.maxValue(this.formValues.to, "to")) {
          this.notZero(this.formValues.to, "to");
        }
      },
      deep: true,
    },
    "formValues.duration": {
      handler() {
        const duration = this.formValues.duration;

        if (
          this.maxValue(duration, "duration") &&
          this.maxAudioLength(duration)
        ) {
          this.notZero(duration, "duration");
        }
      },
      deep: true,
    },
  },

  created() {
    this.generateTicks();
    this.formValues.to = secondsToTimeString(this.file.crop_to);
    this.formValues.from = secondsToTimeString(this.file.crop_from);
  },

  mounted() {
    const audio = <HTMLAudioElement>this.$refs.audio;
    audio.currentTime = this.file.crop_from;
  },
});
