export const analyzes = {
  title: "Analyzes",
  search: "Search",
  fields: "All fields",
  filters: "Filters",
  cols: "Collumns",
  apply: "Apply",
  addFilter: "Add filter",
  noFilters: "No filters yet",
  clearFilters: "Clear filters",
  filtersApplied: "{n} FILTERS APPLIED",
  bAction: "Bulk action",
  titleField: "Title",
  speakers: "Speakers",
  topic: "Topic",
  sense: "Sense",
  sentiment: "Sentiment",
  speech: "Speech style",
  phrases: "Key phrases",
  dur: "Duration",
  size: "Size",
  mod: "Modified",
  lng: "Language",
  action: "Action",
  created: "Created",
  tonality: "Tonality",
  kwSpotted: "Keywords spotted",
  val: "Value",
  contain: "Contains",
  notContain: "Doesn't contain",
  emptyTable: "No data available",
  guestLink: "Sign up to get 10 requests for free",
  guestLink1: "Sign up",
  guestLink2: " to get 10 requests for free",
  storage: "Storage",
  manage: "Manage storage",
  toTariff: "Manage plan",
  delete: "Delete",
  duplicate: "Duplicate",
  reanalyze: "Reanalyze",
  rename: "Rename",
  download: "Download",
  aborted: "Aborted",
  progress: "In progress",
  error: "Error",
  noAvailable: "Not available on your current plan",
  warn: '{count} of your selected files will not be delete. You cannot delete files with status "in progress".',
  warn2:
    '{count} of your selected files will not be re-analyzed. You cannot re-analyze files with status "in progress".',
  warn3:
    '{count}  of your selected files will not be downloaded. You cannot download files with status "in progress" or "error"',
  business: "Business",
  negative: "Negative",
  intimate: "Intimate",
  neutral: "Neutral",
  aggressive: "Aggressive",
  positive: "Positive",
  selected: "{n} analyze selected",
  exp: "{n} exp.",
  style: "Style of speech",
  other: "Other",
  active: "active",
  downloadAudio: "Download Audio",
  downloadPdf: "Download PDF",
  en: "English (US)",
  ru: "Russian (RU)",
  de: "Deutsch (DE)",
  it: "Italian (IT)",
};
