import store from "@/store";
import axios from "axios";

interface ZombtyWindowProps {
  url: string;
  onSuccess(state: string): void;
  onOtherTabSuccess?(): void;
  onClosed?(): void;
}

export function openZombtyWindow({
  url,
  onSuccess,
  onOtherTabSuccess,
  onClosed,
}: ZombtyWindowProps): () => void {
  localStorage.removeItem("zombty-state");
  const win = window.open(url, "_blank", "popup");
  let closed = false;
  if (win) {
    const interval = setInterval(() => {
      if (!win || win.closed) {
        clearInterval(interval);
        onClosed?.();
        closed = true;
      }
    }, 1000);

    window.addEventListener("beforeunload", () => win?.close());
  }

  let unsub = () => {
    //
  };

  const handleZombtyState = ({ key, newValue }: StorageEvent) => {
    if (key !== "zombty-state") {
      return;
    }
    // We are logins in another tab, not in popup
    if (closed) {
      unsub();
      return onOtherTabSuccess?.();
    }
    if (newValue) {
      onSuccess(newValue);
      unsub();
    }
  };

  window.addEventListener("storage", handleZombtyState);

  unsub = () => {
    win?.close();
    window.removeEventListener("storage", handleZombtyState);
  };

  return unsub;
}
export const tokenValidate = async (watch?: number) => {
  try {
    await axios.post(
      `${process.env.VUE_APP_ERLLECTA_URL}/api/refresh-token`,
      {},
      { withCredentials: true, timeout: 3000 }
    );
  } catch (err) {
    if (!watch) {
      await tokenValidate(1).catch(() => {
        throw err;
      });
    } else {
      store.dispatch("profile/LOGOUT_USER");
      throw err;
    }
  }
};
