var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"header-background",attrs:{"app":"","dark":"","height":"48","flat":""}},[_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/logo.svg"),"alt":"logo Senseprofile"}})]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[(_vm.isConnectError)?_c('div',{staticClass:"mr-4",staticStyle:{"color":"var(--danger-color)"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"var(--danger-color)"}},[_vm._v("mdi-lan-disconnect")]),_vm._v(" "+_vm._s(_vm.$t("header.nocon"))+" ")],1):_vm._e(),_c('div',{staticClass:"mr-10"},[_c('HeaderProgress')],1),_c('v-row',{staticClass:"flex-grow-0 ma-0 mr-6 ml-4"},[_c('router-link',{staticClass:"header-link",class:{ active: _vm.$route.name === 'Tariffication' },attrs:{"to":"/tariffication"}},[_vm._v(_vm._s(_vm.$t("header.pricing")))]),_c('router-link',{staticClass:"header-link",class:{ active: _vm.$route.name === 'AnalyzesTable' },attrs:{"to":"/dashboard"}},[_vm._v(_vm._s(_vm.$t("header.analyzes")))])],1),_c('v-btn',{staticClass:"btn-accent-color",attrs:{"to":"/new-analysis"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t("header.new"))+" ")],1),(!_vm.currentUser.is_guest)?_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"320px","disabled":!_vm.currentUser.id,"close-on-content-click":false},scopedSlots:_vm._u([(!_vm.currentUser.is_guest)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"ml-6 avatar",attrs:{"color":"#FFFFFF","size":"36"}},'v-avatar',attrs,false),on),[(_vm.currentUser.id)?_c('span',[_vm._v(" "+_vm._s(_vm.currentUser.email[0].toUpperCase())+" ")]):_vm._e()])]}}:null],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('span',{staticClass:"accent-text email"},[_vm._v(_vm._s(_vm.currentUser.email))])])],1),_c('v-list-item',{attrs:{"to":{ name: 'AccountSettings' }}},[_c('v-list-item-title',{staticClass:"menu-button"},[_c('v-icon',{attrs:{"left":"","size":"22"}},[_vm._v("mdi-pencil")]),_c('span',{staticClass:"menu-title"},[_vm._v(_vm._s(_vm.$t("header.settings")))])],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-divider',{staticClass:"mt-3"}),_c('div',{staticClass:"themes-title"},[_vm._v(" "+_vm._s(_vm.$t("header.analyzes"))+" ")]),_c('div',{staticClass:"info-container d-flex justify-space-between mb-2"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.infoAnalyzesAvailable)+" "),(_vm.tariff !== 'freemium')?_c('span',[_vm._v(" "+_vm._s(_vm.$tc("header.left", _vm.getNextPaymentDate)))]):_vm._e()]),(_vm.tariff !== 'premium')?_c('router-link',{staticClass:"link mb-0",attrs:{"to":{
                  name: 'Tariffication',
                  hash: '#plansScrollAnchor',
                }}},[_vm._v(" "+_vm._s(_vm.$t("header.upgrade"))+" ")]):_vm._e()],1),(_vm.analyzesCountStatus)?_c('div',{staticClass:"info-message",class:("info-" + _vm.analyzesCountStatus)},[(_vm.analyzesCountStatus === 'not-confirmed')?_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v(_vm._s(_vm.$t("header.confirmEmail"))+" ")]),_vm._v(" "+_vm._s(_vm.currentUser.email)+" "+_vm._s(_vm.$t("header.confirmEmail2"))),_c('br'),_c('span',{staticClass:"info-alert__btn",on:{"click":function($event){return _vm.replyConfirmSignUp()}}},[_vm._v(" "+_vm._s(_vm.$t("header.resend"))+" ")]),_vm._v(" "+_vm._s(_vm.$t("header.resend2"))+" ")]):_vm._e(),(_vm.analyzesCountStatus === 'empty')?_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t("header.warning"))+" "+_vm._s(_vm.analysisAllowedCount)+" "+_vm._s(_vm.$t("header.warning2"))+" ")]),_c('v-btn',{staticClass:"btn-accent-color",attrs:{"block":"","to":{
                    name: 'Tariffication',
                    hash: '#plansScrollAnchor',
                  }}},[_vm._v(" "+_vm._s(_vm.$t("header.upgradePlan"))+" ")])],1):_vm._e(),(_vm.successCode)?_c('div',{staticClass:"success-message success-message-header mt-4"},[_c('p',{staticClass:"success-message-description mb-2"},[_vm._v(" "+_vm._s(_vm.successMessages[_vm.successCode])+" ")])]):_vm._e(),([2829294403].includes(_vm.APIError))?_c('div',{staticClass:"info-alert rounded mt-4 py-2 px-4"},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.errorsMessages[_vm.APIError]))])]):_vm._e()]):_vm._e(),_c('v-divider',{staticClass:"mt-3"}),_c('div',{staticClass:"themes-title"},[_vm._v(_vm._s(_vm.$t("header.storage")))]),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"#"}},[_c('div',{staticClass:"info-container"},[_c('router-link',{staticClass:"text-decoration-none d-flex justify-space-between",attrs:{"to":{
                    name: 'Tariffication',
                    hash: '#storageScrollAnchor',
                  }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$tc("header.available", _vm.infoStorageAvailable)))]),_c('span',{staticClass:"link"},[_vm._v(_vm._s(_vm.$t("header.manage")))])])],1),_c('v-progress-linear',{staticClass:"mt-1 mb-3",staticStyle:{"border-radius":"4px"},attrs:{"background-color":"#E0E0E0","value":_vm.storageVolumePercent,"color":_vm.storageColors[_vm.storageStatus],"height":"8"}})],1),(_vm.showStorageInfo)?_c('div',{staticClass:"info-message",class:("info-" + _vm.storageStatus)},[_c('p',[_vm._v(_vm._s(_vm.$t(_vm.storageInfo[_vm.storageStatus])))]),_c('v-btn',{staticClass:"btn-accent-color",attrs:{"block":"","to":{
                  name: 'Tariffication',
                  hash: '#storageScrollAnchor',
                }}},[_vm._v(" "+_vm._s(_vm.$t("header.upgradeStorage"))+" ")])],1):_vm._e(),_c('v-divider',{staticClass:"mt-3"}),_c('div',{staticClass:"themes-title"},[_vm._v(_vm._s(_vm.$t("header.theme")))]),_c('div',{staticClass:"themes-container"},[_c('div',{staticClass:"themes-btn",class:{
                  'active-theme': _vm.savedTheme === 'auto',
                },on:{"click":function($event){return _vm.changeTheme('auto')}}},[_c('v-icon',{staticClass:"themes-btn-icon",attrs:{"size":"16"}},[_vm._v(" mdi-circle-half-full ")]),_vm._v(" "+_vm._s(_vm.$t("header.auto"))+" ")],1),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"themes-btn",class:{
                  'active-theme': _vm.savedTheme === 'light-theme',
                },on:{"click":function($event){return _vm.changeTheme('light-theme')}}},[_c('v-icon',{staticClass:"themes-btn-icon",attrs:{"size":"16"}},[_vm._v(" mdi-white-balance-sunny ")]),_vm._v(" Day ")],1),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"themes-btn",class:{
                  'active-theme': _vm.savedTheme === 'dark-theme',
                },on:{"click":function($event){return _vm.changeTheme('dark-theme')}}},[_c('v-icon',{staticClass:"themes-btn-icon",attrs:{"size":"16"}},[_vm._v(" mdi-moon-waning-crescent ")]),_vm._v(" Night ")],1)])],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }