export const analisis = {
  progressTitle: "Analysis in progress",
  progressDesc:
    "It can take from several minutes to several hours depending on the file size",
  waiting: "Waiting for analysis",
  audio: "Audio",
  abort: "Abort",
  abortedStep: "Aborted",
  reanalyze: "Re-analyze",
  rename: "Rename",
  duplicate: "Duplicate",
  delete: "Delete",
  withError: "Analysis completed with errors:",
  notCompleted: "Analysis not completed due to service error.",
  prev: "see previous result",
  retry: "try again",
  summary: "Summary",
  detecting: "Detecting...",
  sentiment: "Sentiment",
  speech: "Speech style",
  topic: "Topic",
  sense: "Sense",
  keywords: "Keywords detected",
  lng: "Obscene language",
  statistic: "Show speakers statistics",
  title: "Title",
  expected: "{n} expected",
  speakers: "Speakers",
  select: "Select all",
  deselect: "Deselect all",
  dur: "Duration",
  kw: "Keywords",
  kp: "Key phrase",
  highlight: " Highlighting keywords in text",
  addFromTranscription: "Adding keywords from transcription",
  custom: "Create custom selection",
  selected: "Highlight selected",
  selectDesc:
    "Select the words by clicking then press “Highlight selected”. Сlick again to deselect the word",
  spotted: "Spotted",
  times: "{count} times at",
  phrases: "phrases:",
  notChecked: "Not checked",
  recheckKW: "Re-check keywords",
  aborted: "Analysis was aborted",
  completed: "Completed",
  person: "Person {n}",
  personFrom: "Person {n} at",
  of: "{i} of {n}",
  words: "0 words | {n} word | {n} words ",
  goBack: "Back to results",
  activityTitle: "Speaker activity distribution",
  statistics: "Statistics",
  senses: "Senses",
  sensesEmpty: "No sense detected",
  mostUsed: "The most used phrases",
  mostUsedEmpty: "No phrases detected",
  kwDetected: "Keywords and phrases detected",
  kwDetectedEmpty: "No keywords detected",
  playFrom: "Play from selected",
  noDetected: "No {n} detected",
  transcription: "Transcription",
  selectedFiles: "Selected {n} files",
  length: "Length",
  m: "m",
  ms: "ms",
  renameSpeakers: "Speaker renaming",
  save: "Save",
  invalidTitle: "Title is invalid",
  before_filtration: "before filtration",
};
